import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import './Consentimiento.css';
import imgFormulario from './../../resources/images/formulario.png';
import ExamenServices from './../../services/examen';
import Loading from './../../components/Loading/Loading';

const PrintableConsentimiento = () => {
   const { idexamen } = useParams();
   const[isLoading, setIsLoading] = useState(true);
   const[hasError, setHasError] = useState(false);
   const[data, setData] = useState({
      nombre: '',
      rut:    '',
      edad:   '',
      sexo:{
         masculino:false,
         femenino:false,
      },
      fechaNacimiento:{
         dia:'',
         mes:'',
         annio:''
      },
      fechaHoy:{
         dia:'',
         mes:'',
         annio:''
      },
      nacionalidad:'',
      telefono:'',
      correo:'',
      direccion:'',
      ciudad:'',
      comuna:'',
      region:'',
      motivo:{
         laboral: false,
         preventivo: false,
         sintomas: false,
         contactoEstrecho: false
      },
      sintoma:{
         ninguno:false,
         fiebre:false,
         tos:false,
         cefalea:false,
         dolorMuscular:false,
         perdidaOlfatoGusto:false,
         dolorPecho:false,
         dificultadRespiratoria:false,
         dolorAlTragar:false,
         cansancioExcesivo:false,
         diarrea:false,
         escalofrios:false
      },
      fechaSintomas:''
   });
  
   useEffect(() => {
      buscarPrintable();
   },[]);

   const buscarPrintable = async () => {
      setIsLoading(true);
      let response = await ExamenServices.printableConsentimiento(idexamen);
      if(response.data.success){
         setData(response.data.data)
         setIsLoading(false);
      }else{
         setHasError(true);
         setIsLoading(false);
      }
   };

   if(isLoading && hasError){
      return(
         <Loading />
      )
   }else {
      return (
         <>
         
         {isLoading && !hasError ?
            <Loading />
         :null };

         {hasError ?
            <p>No se ha podido cargar el exámen</p>
         :null }

         {!isLoading && !hasError ?
            <><div id="sidebar">
               <div id="outline"></div>
            </div>
            <div id="page-container">
               <div id="pf1" className="pf w0 h0" data-page-no="1"><div className="pc pc1 w0 h0"><img className="bi x0 y0 w1 h1" alt="" src={imgFormulario}/><div className="t m0 x1 h2 y1 ff1 fs0 fc0 sc0 ls0 ws0"></div><div className="t m0 x2 h3 y2 ff2 fs1 fc0 sc0 ls1 ws0"><span className="ls0"> </span></div><div className="t m0 x3 h3 y3 ff2 fs1 fc0 sc0 ls0 ws0"></div><div className="t m0 x3 h4 y4 ff3 fs2 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h5 y5 ff2 fs0 fc0 sc0 ls0 ws0">Datos Personales:</div>
               <div className="t m0 x3 h6 y6 ff2 fs0 fc0 sc0 ls2 ws0">
                  <span className="ls0">Nombre Completo:
                     <span className="ff3">
                        <span className="ls3 relative">
                           <span className="absolute">{data.nombre}</span>
                           _____________________________________________________________________________________________________
                        </span>
                     </span>
                  </span>
               </div>
               <div className="t m0 x3 h6 y7 ff2 fs0 fc0 sc0 ls2 ws0">
                  <span className="ls0">
                     RUT:
                     <span className="ls4">
                        <span className="absolute">{data.rut}</span>
                        ____________________________
                     </span>
                     Edad: 
                     <span className="ff3">
                        <span className="ls4"> 
                           <span className="absolute"> {data.edad}</span>
                           ___________
                        </span>
                     </span>
                     <span className="ls5">  F. Nacimiento: </span>
                     <span className="ff3">
                        <span className="absolute">{data.fechaNacimiento.dia}</span>
                        ___</span>
                     <span className="ff3">_/_</span>
                     <span className="ff3">
                        <span className="absolute">{data.fechaNacimiento.mes}</span>
                        ___</span>
                     <span className="ff3">_/_</span>
                     <span className="ff3">
                        <span className="absolute">{data.fechaNacimiento.annio}</span>
                        ______  </span>
                     Nacionalidad:  <span className="ff3 ls4"> 
                     <span className="absolute">{data.nacionalidad}</span>
                     ______________________</span>
                  </span>
               </div>
               <div className="t m0 x3 h5 y8 ff2 fs0 fc0 sc0 ls2 ws0">
                  <span className="ls0"></span>
               </div>
               <div className="t m0 x3 h5 y9 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h5 ya ff2 fs0 fc0 sc0 ls0 ws0">Datos de Contacto: </div>
               <div className="t m0 x3 h6 yb ff2 fs0 fc0 sc0 ls0 ws0"> 
                  Teléfono: <span className="ff3 ls4">
                        <span className="absolute">{data.telefono}</span>
                        _________________________________</span>
                  <span className="ls0"> Correo: </span>
                  <span className="ff3 ls4">
                     <span className="absolute">{data.correo}</span>
                     ____________________________________________________________________
                  </span>
               </div>
               <div className="t m0 x3 h6 yc ff2 fs0 fc0 sc0 ls0 ws0">
                  Dirección: <span className="ls4">
                  <span className="absolute">{data.direccion}</span>
                     ____________________________________________________________________________________________________________
                  </span>
               </div>
               <div className="t m0 x3 h6 yd ff2 fs0 fc0 sc0 ls0 ws0"> 
                  Ciudad: <span className="ff3 ls4">
                     <span className="absolute">{data.ciudad}</span>
                     _______________________________
                     </span>
                  Comuna: <span className="ff3">
                     <span className="absolute">{data.comuna}</span>
                     _________________________________ 
                     </span>
                  Región: <span className="ff3 ls4">
                     <span className="absolute">{data.region}</span>
                     ______________________________
                     </span>
               </div>
               
               <div className="t m0 x3 h5 ye ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 yf ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 y10 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 y11 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               
               <div className="t m0 x3 h5 y12 ff2 fs0 fc0 sc0 ls0 ws0">Factores de Riesgo:</div>
               <div className="t m0 x4 h5 y13 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 y14 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 y15 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 y16 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h6 y17 ff2 fs0 fc0 sc0 ls0 ws0">¿Ingresó a esta ciudad desde otra región o país?:
                  <span className="ff3">No        <span className="ls2">  <span className="ls7">Si</span>          </span>(completar siguien<span className="_ _0"></span>te cuadro si corr<span className="_ _0"></span>esponde)</span> </div><div className="t m0 x4 h5 y18 ff2 fs0 fc0 sc0 ls0 ws0"> </div><div className="t m0 x4 h5 y19 ff2 fs0 fc0 sc0 ls0 ws0"> </div>
               
               <div className="t m0 x4 h5 y1a ff2 fs0 fc0 sc0 ls0 ws0"> </div>
               <div className="t m0 x4 h5 y1b ff2 fs0 fc0 sc0 ls0 ws0"> </div>
               <div className="t m0 x4 h5 y1c ff2 fs0 fc0 sc0 ls0 ws0"> </div>
               
               <div className="t m0 x4 h6 y1d ff2 fs0 fc0 sc0 ls0 ws0">
                  Síntomas COVID-19: 
                  <span className="ff3">(Marque con una X el o los síntomas que usted presenta)</span>
               </div>
               <div className="t m0 x4 h5 y1e ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 y1f ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 y20 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 y21 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x4 h5 y22 ff2 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h6 y23 ff3 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h6 y24 ff3 fs0 fc0 sc0 ls2 ws0">
                  <span className="ls0">¿Tuvo Covid-19? No    <span className="_ _0"></span>     Si           Fecha del Diagnóstico</span>: _____/_____/_____
               </div>
               <div className="t m0 x3 h6 y25 ff3 fs0 fc0 sc0 ls2 ws0">
                  <span className="ls0">¿Se realizó un PCR en los últimos 30 días?    No         Si             Fecha de PCR anterior<span className="_ _0"></span></span>: <span className="ls4">_____/_____/_____</span>
               </div>
               <div className="t m0 x3 h6 y26 ff3 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h6 y27 ff3 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h6 y27 ff3 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h6 y23 ff3 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 y2a ff3 fs0 fc0 sc0 ls8 ws0" style={{lineHeight:1.2}}>He sido informado(a) acerca de la naturaleza y propósito del examen que la empresa solicita y me será realizado.  Además, autorizo expresamente<br/>a Inversiones Tecnomédica que informe a la Empresa: _______________________________________________________ Sobre las condiciones<br/>de mi estado de salud, y remita a esta última una copia del resultado del examen realizado a mi persona.
               </div>
               <div className="t m0 x3 h7 y2c ff4 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h7 y2d ff4 fs0 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h8 y2e ff4 fs3 fc0 sc0 ls0 ws0"></div>
               <div className="t m0 x3 h9 y2f ff4 fs4 fc0 sc0 ls0 ws0"></div>
               
               <div className="t m0 x5 ha y30 ff4 fs1 fc0 sc0 ls0 ws0">FIRMA PACIENTE<span className="_ _1"></span></div>
               <div className="c x6 y31 w2 hb">
                  <div className="t m0 x7 hc y32 ff5 fs1 fc0 sc0 ls0 ws0">Consentimiento informado de entrega </div>
                  <div className="t m0 x4 hc y33 ff5 fs1 fc0 sc0 ls0 ws0">de resultados Examen PCR</div>
               </div>
               
               <div className="c x3 y34 w3 hd">
                  <div className="t m0 x8 h6 y35 ff3 fs0 fc0 sc0 ls0 ws0">
                     Sexo: Masc.<span className="ls2">
                        {data.sexo.masculino && <span className="absolute">  x</span> }       </span> 
                     Fem. <span className="ls2">{data.sexo.femenino && <span className="absolute">  x </span> }     </span>  Embarazo: No<span className="ls2">        <span className="ls7">Si</span>        <span className="_ _0"></span></span>Semanas Ge<span className="_ _0"></span>stación <span className="ls4">________</span> Etnia <span className="ls2">Indígena</span>:<span className="_ _0"></span> No<span className="ls2">        <span className="ls7">Si</span></span> <span className="ls2">       </span> Cual: <span className="ls4">_______</span>__<span className="_ _0"></span>____<span className="ls4">__</span>__ 
                  </div>
               </div>
               
               <div className="c x9 y36 w4 he">
                  <div className="t m0 x8 ha y37 ff4 fs1 fc0 sc0 ls0 ws0">  </div>
               </div>
               <div className="c x3 y38 w5 hf">
                  <div className="t m0 x8 h6 y39 ff2 fs0 fc0 sc0 ls0 ws0">Motivo del Examen: 
                     <span className="ff3 checkMotivo">
                        Laboral     <span className="ls2">    {data.motivo.laboral && <span className="chk checkLaboral">x</span> }  </span>  
                          Preventivo      <span className="ls2">    {data.motivo.preventivo && <span className="chk checkPreventivo">x</span> }</span>   Síntomas   <span className="ls2">    {data.motivo.sintomas && <span className="chk checkSintomas">x</span> }</span>  Contacto Estrecho <span className="ls2">    {data.motivo.contactoEstrecho && <span className="checkContactoEstrecho">x</span> }</span></span>
                  </div>
                  <div className="t m0 x8 h6 y3a ff3 fs0 fc0 sc0 ls0 ws0"> </div>
                  <div className="t m0 x8 h6 y3b ff2 fs0 fc0 sc0 ls0 ws0">Lugar donde realizará la Cuarentena: <span className="ff3">Domicilio <span className="ls2">       </span>   Residenci<span className="_ _0"></span>a Sanitaria         <span className="ls2">  </span>Hospita<span className="_ _0"></span>l o Clínica </span></div>
               </div>
               
               <div className="c x3 y3c w6 h10"><div className="t m0 x8 h6 y3d ff2 fs0 fc0 sc0 ls0 ws0">¿Es usted p<span className="_ _0"></span>ersonal de Salud<span className="_ _0"></span>?<span className="ls6">: <span className="ff3 ls8">No<span className="ls2">       <span className="ls0">    <span className="ls7">Si</span></span><span className="ff2">       </span></span></span></span> <span className="ls2">  </span> Cent<span className="_ _0"></span>ro de Salud<span className="_ _0"></span> donde trabaja<span className="ls6">: </span>_<span className="ls4">_______</span>______<span className="ls4">________________________________</span>  </div><div className="t m0 x8 h6 y3e ff2 fs0 fc0 sc0 ls0 ws0">Enf. Crónicas<span className="ls6">: </span><span className="ff3">Hipertensión<span className="_ _0"></span><span className="ls2">         </span> Diabetes<span className="ls2">         </span> A<span className="_ _0"></span>sma     <span className="ls2">  </span>   Hipotiro<span className="_ _0"></span>idismo<span className="ls2">       </span> <span className="ls2">  </span> Otros: ______<span className="_ _0"></span>___<span className="ls4">__________________________________</span> </span></div></div>
               
               <div className="c x3 y3f w7 h11">
                  <div className="t m0 x8 h5 y40 ff2 fs0 fc0 sc0 ls0 ws0 checkMotivo">
                     <span className="nob">Ninguno   <span className="ls2">  {data.sintoma.ninguno && <span className="chk checkNinguno">x</span> }    </span>
                     </span>
                  </div>
                  <div className="t m0 x8 h6 y41 ff3 fs0 fc0 sc0 ls0 ws0 checkMotivo">
                     <span className="nob">
                        Fiebre <span className="ls2">  {data.sintoma.fiebre && <span className="chk checkFiebre">x</span> }    </span>
                     </span>
                     <span className="nob">
                         Tos   <span className="ls2"> {data.sintoma.tos && <span className="chk checkTos">x</span> }    </span>
                     </span>
                     <span className="nob"> Cefalea   <span className="ls2">  {data.sintoma.cefalea && <span className="chk checkCefalea">x</span> }    </span>
                     </span>
                     <span className="nob">
                        Dolor Muscular   <span className="ls2">  {data.sintoma.dolorMuscular && <span className="chk checkDolorMuscular">x</span> }    </span>
                     </span>
                     <span className="nob">
                        Diarrea   <span className="ls2">  {data.sintoma.diarrea && <span className="chk checkDiarrea">x</span> }    </span>
                     </span>
                     <span className="nob">
                        Perdida de Olfato y/o Gusto    <span className="ls2">  {data.sintoma.perdidaOlfatoGusto && <span className="chk checkPerdidaOlfato">x</span> }     </span>
                     </span>
                     <span className="nob">
                        Dolor de Pecho  <span className="ls2">  {data.sintoma.dolorPecho && <span className="chk checkDolorPecho">x</span> }    </span>
                     </span>
                     <span className="nob"> Escalofríos  <span className="ls2">  {data.sintoma.escalofrios && <span className="chk checkEscalofrios">x</span> }    </span>
                     </span>
                  </div>
                  <div className="t m0 x8 h6 y42 ff3 fs0 fc0 sc0 ls0 ws0 checkMotivo">
                     <span className="nob">Dificultad Respiratoria  <span className="ls2">  {data.sintoma.dificultadRespiratoria && <span className="chk checkDificultad">x</span> }    </span>
                     </span>
                     <span className="nob">   Dolor al Tragar    <span className="ls2">  {data.sintoma.dolorAlTragar && <span className="chk checkDolorAlTragar">x</span> }    </span>
                     </span>
                     <span className="nob">   Cansancio Excesivo  <span className="ls2">  {data.sintoma.cansancioExcesivo && <span className="chk checkCansancio">x</span> }    </span>
                     </span>    Fecha de Inicio de Síntomas: {data.fechaSintomas}
                  </div>
               </div>
               
               <div className="c xa y43 w8 h12">
                  <div className="t m0 x8 hc y44 ff5 fs1 fc0 sc0 ls0 ws0">
                     FECHA:  <span className="ff3">
                        <span className="absolute">{data.fechaHoy.dia}</span>
                        ___</span>
                     <span className="ff3">_/_</span>
                     <span className="ff3">
                        <span className="absolute">{data.fechaHoy.mes}</span>
                        ___</span>
                     <span className="ff3">_/_</span>
                     <span className="ff3">
                        <span className="absolute">{data.fechaHoy.annio}</span>
                        ______  </span>
      
                  </div>
               </div>
               
               <div className="c x3 y45 w7 h13"><div className="t m0 x8 h6 y46 ff3 fs0 fc0 sc0 ls0 ws0">Fecha <span className="ls4">de</span> Ingreso: _<span className="_ _0"></span>_<span className="ls4">___/__</span>_<span className="ls4">__<span className="ls2">/_</span></span>_<span className="ls4">___  </span>Medio de T<span className="_ _0"></span>ransporte: Vehículo Particular<span className="_ _0"></span>  <span className="ls2">     </span>  </div><div className="t m0 x8 h6 y47 ff3 fs0 fc0 sc0 ls2 ws0">                                                                                                                       <span className="ls0"> Avión </span>       <span className="ls0">  Aerolínea<span className="_ _0"></span> _____<span className="ls4">___</span>__<span className="_ _0"></span>___ N° de Vuelo<span className="ls4">____</span>_<span className="_ _0"></span>_____<span className="ls4">___</span> </span></div><div className="t m0 x8 h6 y48 ff3 fs0 fc0 sc0 ls2 ws0">                                                                                                          <span className="ls0"> </span>              <span className="ls0">  Bus</span>         <span className="ls0"> <span className="ls4">Línea</span> <span className="ls4">bus</span> ____<span className="_ _0"></span><span className="ls4">____________</span>  </span></div></div><div className="c xb y49 w9 he"><div className="t m0 x8 ha y37 ff4 fs1 fc0 sc0 ls0 ws0">&lt;</div><div className="t m0 x8 ha y4a ff4 fs1 fc0 sc0 ls0 ws0"> </div><div className="t m0 x8 ha y4b ff4 fs1 fc0 sc0 ls0 ws0">&lt;</div><div className="t m0 x8 ha y4c ff4 fs1 fc0 sc0 ls0 ws0">&lt;</div><div className="t m0 x8 ha y4d ff4 fs1 fc0 sc0 ls0 ws0">&lt; </div></div></div><div className="pi" data-data='{"ctm":[1.000000,0.000000,0.000000,1.000000,0.000000,0.000000]}'></div></div>
            </div></>
         :null}
         </>
      );
   }

}

export default PrintableConsentimiento;