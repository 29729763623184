import React, { useState, useEffect, forwardRef } from 'react';
import { useHistory } from 'react-router';
import Loading from './../../../components/Loading/Loading';
import MuiAlert from '@material-ui/lab/Alert';
import {
   Grid,
   CircularProgress,
   Chip,
   Checkbox,
   Button,
   Divider,
   IconButton,
   FormControl,
   FormControlLabel,
   Paper,
   Typography,
   TextField,
   RadioGroup,
   Snackbar
} from '@material-ui/core';
import {
   AddBox,
   ArrowDownward,
   Check,
   ChevronLeft,
   ChevronRight,
   Clear,
   DeleteOutline,
   FirstPage,
   FilterList,
   Edit,
   LastPage,
   Remove,
   SaveAlt,
   Search,
   ViewColumn
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TipoOperativoServices from './../../../services/tipoOperativo';
import OperativoServices from './../../../services/operativo';

const tableIcons = {
   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
   Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
   Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
   DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
   Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
   Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
   PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
   ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
   ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const estados = {
   A: 'Activo',
   C: 'Cerrado'
}
const Operaciones = () => {
   const [tipoOperativoSeleccionado, setTipoOperativoSeleccionado] = useState();
   const [tipoOperativos, setTipoOperativos] = useState([]);
   const [operaciones, setOperaciones] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [nuevaOperacion, setNuevaOperacion] = useState({
      nombre: '',
      tipooperativo: '',
      estado: 'A',
      empresa: '',
      lugartesteo: ''
   })
   const [dataError, setDataError] = useState({
      nombre: false,
      tipoOperativo: false,
      empresa: false,
      lugartesteo: false
   });
   const history = useHistory();

   const handleChangeOperativo = (e) => {
      let state = nuevaOperacion;
      setNuevaOperacion({
         ...state,
         [e.target.name]: e.target.value
      });
   };

   /* const updateRow = async (nuevoOperativo, operativoAnterior) => {
      OperativoServices.updateOperacion(nuevoOperativo)
         .then(res => {
            if (res.data.success) {
               const aux = [...operaciones];
               const index = operativoAnterior.tableData.id;
               aux[index] = nuevoOperativo;
               setOperaciones([...aux]);
            }
         })
         .catch(error => {
            setErrorOpen(true);
         })
   } */

   const deleteRow = async (operativo) => {
      OperativoServices.deleteOperacion(operativo.idoperacion)
         .then(res => {
            if (res.data.success) {
               const aux = [...operaciones];
               const index = operativo.tableData.id;
               aux.splice(index, 1);
               setOperaciones([...aux]);
            }
         })
         .catch(error => {
            setErrorOpen(true);
         })
   }

   const submitNuevoOperativo = async () => {
      let errores = {
         nombre: false,
         tipooperativo: false,
         empresa: false,
         lugartesteo: false
      };

      if (!nuevaOperacion.nombre && nuevaOperacion.nombre.trim().length === 0) {
         errores['nombre'] = true;
      }
      if (!nuevaOperacion.tipooperativo) {
         errores['tipooperativo'] = true;
      }
      if (!nuevaOperacion.empresa && nuevaOperacion.empresa.trim().length === 0) {
         errores['empresa'] = true;
      }
      if (!nuevaOperacion.lugartesteo && nuevaOperacion.lugartesteo.trim().length === 0) {
         errores['lugartesteo'] = true;
      }

      if (errores.nombre || errores.tipooperativo || errores.empresa || errores.lugartesteo) {
         setDataError(errores);
         return;
      }

      const response = await OperativoServices.saveOperacion(nuevaOperacion);
      if (response.data && response.data.success) {
         verOperacion(response.data.data.idoperacion);
      } else {
         setErrorOpen(true);
      }
   }

   useEffect(() => {

      fetchData();
   }, []);


   const fetchData = async () => {
      const operativoResponse = await TipoOperativoServices.getTipoOperativosActivos();
      if (operativoResponse && operativoResponse.data) {
         setTipoOperativos(operativoResponse.data);
         setIsLoading(false);
      }

      const operacionesResponse = await OperativoServices.getOperativos();
      if (operacionesResponse && operacionesResponse.data) {
         setOperaciones(operacionesResponse.data);
      }
   }

   const verOperacion = (idoperacion) => {
      history.push('/admin/in-situ/' + idoperacion + '/');
   };

   /* Alert */
   const [errorOpen, setErrorOpen] = React.useState(false);
   const handleError = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setErrorOpen(false);
   };

   return (
      <>
         <Grid container spacing={4}>
            <Grid item xs={12} style={{ padding: '25px 15px' }}>
               <Typography component="h1" variant="h6" color="inherit" >
                  Operación in Situ
               </Typography>
            </Grid>
         </Grid>

         <Paper>
            {isLoading ?
               <Loading />
               :
               <Grid container spacing={4}>
                  <Grid item xs={12} md={12} style={{ padding: '10px 40px' }}>
                     <Typography component="h1" variant="h6" color="inherit">
                        Crear una nueva
                     </Typography>
                     <Typography variant="body2" color="inherit">
                        Inicia una nueva operación in situ o selecciona una de las ya creadas para ver su avance y progreso en tiempo real.
                     </Typography>
                  </Grid>
                  <Grid item xs={9} md={9} style={{ padding: '20px 40px' }}>
                     <Grid container spacing={2}>
                        <Grid item xs={6}>
                           <TextField
                              fullWidth
                              label="Nombre"
                              name="nombre"
                              variant="outlined"
                              value={nuevaOperacion.nombre || ''}
                              onChange={handleChangeOperativo}
                              error={dataError.nombre}
                              helperText={
                                 dataError.nombre
                                    ? "Debes ingresar un nombre"
                                    : null
                              }
                           />
                        </Grid>
                        <Grid item xs={6}>
                           <Autocomplete
                              defaultValue={tipoOperativoSeleccionado}
                              options={tipoOperativos}
                              getOptionLabel={(option) => option.nombre}
                              onChange={(event, operativo) => {
                                 if (operativo) {
                                    setNuevaOperacion({
                                       ...nuevaOperacion,
                                       tipooperativo: operativo.idtipooperativo
                                    })
                                 }
                              }}
                              renderInput={(params) =>
                                 <TextField
                                    {...params}
                                    required
                                    label="Tipo Operativo"
                                    variant="outlined"
                                    error={dataError.tipoOperativo}
                                    helperText={
                                       dataError.tipoOperativo
                                          ? "Debes seleccionar un tipo"
                                          : null
                                    }
                                 />
                              }
                           />
                        </Grid>
                        <Grid item xs={6}>
                           <TextField
                              fullWidth
                              label="Empresa"
                              name="empresa"
                              variant="outlined"
                              value={nuevaOperacion.empresa || ''}
                              onChange={handleChangeOperativo}
                              error={dataError.empresa}
                              helperText={
                                 dataError.empresa
                                    ? "Debes completar el campo"
                                    : null
                              }
                           />
                        </Grid>
                        <Grid item xs={6}>
                           <TextField
                              fullWidth
                              label="Lugar de testeo"
                              name="lugartesteo"
                              variant="outlined"
                              value={nuevaOperacion.lugartesteo || ''}
                              onChange={handleChangeOperativo}
                              error={dataError.lugartesteo}
                              helperText={
                                 dataError.lugartesteo
                                    ? "Debes completar el campo"
                                    : null
                              }
                           />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={3} md={3} style={{ padding: '20px 40px' }}>
                     <Button
                        color="primary"
                        variant="contained"
                        disabled={isLoading}
                        onClick={submitNuevoOperativo}>
                        Crear e iniciar
                        {isLoading &&
                           <CircularProgress style={{ marginLeft: '10px' }} size={20} />
                        }
                     </Button>

                  </Grid>
               </Grid>
            }
         </Paper>

         <Grid container spacing={4}>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
               <MaterialTable
                  icons={tableIcons}
                  title="Operativos"
                  columns={[
                     { title: 'Nombre', field: 'nombre' },
                     { title: 'Empresa', field: 'empresa' },
                     { title: 'Lugar', field: 'lugartesteo' },
                     { title: 'Fecha creación', field: 'fechacreacion', type: 'datetime', editable: 'never' },
                     {
                        title: 'Estado', field: 'estado', lookup: estados, type: 'string',
                        render: rowData =>
                           <>
                              {rowData.estado === 'A' ?
                                 <>
                                    <Chip color="primary" size="small" label="Activo" />
                                    <IconButton
                                       edge="start"
                                       color="inherit"
                                       aria-label="open drawer"
                                       onClick={() => verOperacion(rowData.idoperacion)}
                                    >
                                       <Search />
                                    </IconButton>
                                 </>
                                 :
                                 <Chip color="secondary" size="small" label="Cerrado" />
                              }

                           </>
                     },
                  ]}
                  localization={{
                     header: {
                        actions: 'Acciones'
                     },
                     body: {
                        emptyDataSourceMessage: 'No existen datos',
                        addTooltip: 'Nuevo',
                        deleteTooltip: 'Eliminar',
                        editTooltip: 'Editar',
                        editRow: {
                           deleteText: '¿Seguro que deseas eliminarlo?',
                           cancelTooltip: 'Cancelar',
                           saveTooltip: 'Eliminar',
                        }
                     },
                     toolbar: {
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar'
                     },
                     pagination: {
                        labelRowsSelect: 'Filas por página',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página'
                     }
                  }}
                  data={operaciones}
                  options={{
                     filtering: true,
                     actionsColumnIndex: -1
                  }}
                  editable={{
                     /* onRowUpdate: (nuevoOperativo, operativoAnterior) => updateRow(nuevoOperativo, operativoAnterior), */
                     onRowDelete: operativo => deleteRow(operativo),
                  }}
               />
            </Grid>
         </Grid>

         <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleError}>
            <MuiAlert elevation={6} variant="filled" onClose={handleError} severity="error">
               Ha ocurrido un error en la operación, recarga la página y vuelve a intentarlo
            </MuiAlert>
         </Snackbar>
      </>
   );
}

export default Operaciones;