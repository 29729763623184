import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';
import {
    Assignment,
    ExitToApp,
    Help,
    People,
    PlaylistAddCheck
} from '@material-ui/icons';
import ConfiguracionServices from './../../../services/configuracion';

const NavegacionDashboard = () => {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [helpMessage, setHelpMessage] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cargarDatos = async () => {
        let response = await ConfiguracionServices.activo('MENSAJE_AYUDA');
        if (response.data) {
            setHelpMessage(response.data.contenido);
        } else {
            setOpen(true);
        }
    };

    useEffect(() => {
        cargarDatos();

    }, []);


    return (
        <div>
            <ListItem button onClick={() => history.push('/cuenta/')}>
                <ListItemIcon>
                    <Assignment />
                </ListItemIcon>
                <ListItemText primary="Exámenes" />
            </ListItem>
            <ListItem button onClick={() => history.push('/cuenta/nuevo-examen')}>
                <ListItemIcon>
                    <People />
                </ListItemIcon>
                <ListItemText primary="Nuevo exámen" />
            </ListItem>

            <ListItem button onClick={() => window.location.pathname = "/"}>
                <ListItemIcon>
                    <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="Cerrar Sesión" />
            </ListItem>

            {helpMessage !== '' ?
                <>
                    <ListItem button onClick={handleClickOpen}>
                        <ListItemIcon>
                            <Help />
                        </ListItemIcon>
                        <ListItemText primary="¿Necesitas ayuda?" />
                    </ListItem>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">Asistencia</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {helpMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cerrar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                : null}
        </div>
    )
}

export default NavegacionDashboard;