import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Dashboard from './Layout/Dashboard';
import Examenes from './Examenes/Examenes';
import Personas from './Personas/Personas';
import NuevoExamen from './NuevoExamen/NuevoExamen';
import Agendados from './Examenes/Agendados';
import CargaMasiva from './CargaMasiva/CargaMasiva';
import Operativos from './Operativos/Operativos';
import Configuracion from './Configuracion/Configuracion';
import Operaciones from './Operacion/Operaciones';
import DetalleOperacion from './Operacion/DetalleOperacion';
import FormularioPaciente from './Operacion/FormularioPaciente';
import Historico from './Historico/Historico';
import Usuarios from './Usuarios/Usuarios';
import EditarUsuarios from './Usuarios/EditarUsuario';
import NuevoUsuario from './Usuarios/NuevoUsuario';
import NuevaAsignacion from './Asignacion/NuevaAsignacion';
import Asignacion from './Asignacion/Asignacion';
import MiAsignacion from './MiAsignacion/Asignacion';

const AdminRouting = ({ account }) => {
    const history = useHistory();
    const [ready, setReady] = useState(false);
    const [user, setUser] = useState({
        persona: {
            idpersona: ''
        }
    });

    useEffect(() => {
        validateLogin();
    }, [account]);

    useEffect(() => {
        if (account && account.persona) {
            setUser(account);
        } else {
            console.log("push out to login");
            history.push('/login');
        }

        setReady(true);
    }, []);


    const validateLogin = async () => {

        if (!account || Object.keys(account).length === 0) {
            history.push('/login');
        }

        if(account.rol === "paciente"){
            history.push('/cuenta');
        }
    }

    return (
        <Router>
            <Dashboard account={account}>
                    <Switch>
                        <Route path="/admin/home">
                            <UserRouteAccessProvider account={account}>
                                <Examenes />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/agendados">
                            <UserRouteAccessProvider account={account}>
                                <Agendados />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/nuevo-examen">
                            <UserRouteAccessProvider account={account}>
                                <NuevoExamen />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/personas">
                            <UserRouteAccessProvider account={account}>
                                <Personas />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/carga-masiva">
                            <UserRouteAccessProvider account={account}>
                                <CargaMasiva />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/operativos">
                            <UserRouteAccessProvider account={account}>
                                <Operativos />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route exact path="/admin/in-situ/:idoperacion/ingreso">
                            <UserRouteAccessProvider account={account}>
                                <FormularioPaciente />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route exact path="/admin/in-situ/:idoperacion/">
                            <UserRouteAccessProvider account={account}>
                                <DetalleOperacion />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/in-situ">
                            <UserRouteAccessProvider account={account}>
                                <Operaciones />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/historico">
                            <UserRouteAccessProvider account={account}>
                                <Historico />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/configuracion">
                            <UserRouteAccessProvider account={account}>
                                <Configuracion />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/usuarios/:idusuario/editar">
                            <UserRouteAccessProvider account={account}>
                                <EditarUsuarios />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/usuarios/nuevo">
                            <UserRouteAccessProvider account={account}>
                                <NuevoUsuario />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/usuarios">
                            <UserRouteAccessProvider account={account}>
                                <Usuarios />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/asignacion/nuevo">
                            <UserRouteAccessProvider account={account}>
                                <NuevaAsignacion />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/asignacion">
                            <UserRouteAccessProvider account={account}>
                                <Asignacion />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin/mi-asignacion">
                            <UserRouteAccessProvider account={account}>
                                <MiAsignacion user={account.persona.idusuario} />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route exact path="/cuenta/asignacion">
                            <UserRouteAccessProvider account={account}>
                                <Asignacion user={user.persona.idusuario} />
                            </UserRouteAccessProvider>
                        </Route>
                        <Route path="/admin">
                            <UserRouteAccessProvider account={account}>
                                <Examenes />
                            </UserRouteAccessProvider>
                        </Route>
                    </Switch>
            </Dashboard>
        </Router>
    )
}

export default AdminRouting;


const UserRouteAccessProvider = ({account, children}) => {
    let location = useLocation();
    let history = useHistory();

    const [operadorAccess, setOperadorAccess] = useState([
        { path:'/admin', access:false},
        { path:'/admin/home', access:false},
        { path:'/admin/mi-asignacion', access:true},
        { path:'/admin/agendados', access:false},
        { path:'/admin/nuevo-examen', access:true},
        { path:'/admin/personas', access:false},
        { path:'/admin/carga-masiva', access:false},
        { path:'/admin/in-situ', access:true},
        { path:'/admin/historico', access:true},
        { path:'/admin/configuracion', access:false},
        { path:'/admin/usuarios/nuevo', access:false},
        { path:'/admin/usuarios/asignacion', access:false},
        { path:'/admin/usuarios/cuenta/asignacion', access:false},
    ]);

    const [adminAccess, setAdminAccess] = useState([
        { path:'/admin', access:true},
        { path:'/admin/home', access:true},
        { path:'/admin/mi-asignacion', access:false},
        { path:'/admin/agendados', access:true},
        { path:'/admin/nuevo-examen', access:true},
        { path:'/admin/personas', access:true},
        { path:'/admin/carga-masiva', access:true},
        { path:'/admin/in-situ', access:true},
        { path:'/admin/historico', access:true},
        { path:'/admin/configuracion', access:true},
        { path:'/admin/usuarios/nuevo', access:true},
        { path:'/admin/usuarios/asignacion', access:true},
        { path:'/admin/usuarios/cuenta/asignacion', access:true},
    ]);


    useEffect(() => {
        if(account.rol === "operador"){
            let item = operadorAccess.find(item => item.path === location.pathname);
            console.log("item", item);
            if(!item || !item.access){
                history.push('/admin/mi-asignacion');
            }
        }

        if(account.rol === "administrador"){
            let item = adminAccess.find(item => item.path === location.pathname);
            console.log("item", item);
            if(!item || !item.access){
                history.push('/admin');
            }
        }

        if(account.rol === "paciente"){
            history.push('/cuenta');
        }
    });

    return(
        <>{children}</>
    );
};