import React from 'react';
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Grid
} from '@material-ui/core';
import {
   Delete,
   OpenInBrowser
} from '@material-ui/icons';
const base_path = process.env.REACT_APP_PATH || 'http://127.0.0.1:8000/';


const FileView = ({ open, examenURL, handleClose, handleEliminar, file }) => {
   const [openConfirm, setOpenConfirm] = React.useState(false);
   const handleOpenConfirm = () => {
      setOpenConfirm(true);
      console.log(file);
   };
   const handleCloseConfirm = () => {
      setOpenConfirm(false);
   };

   const eliminarArchivo = () => {
      handleCloseConfirm();
      handleEliminar(file);
   }

   const openInWeb = () => {
      window.open(file.path, "_blank");
   }

   const createURL = (url) => {
      if (url && (url.startsWith("https") || url.startsWith("http"))) {
         return url;
      } else {
         return base_path + url
      }
   }

   return (
      <>
         <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            aria-labelledby="draggable-dialog-title"
         >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
               Eliminar archivo
            </DialogTitle>
            <DialogContent>
               <DialogContentText>
                  ¿Estás completamente seguro que deseas eliminar este archivo?
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button autoFocus onClick={handleCloseConfirm} color="primary">
                  Cancelar
               </Button>
               <Button onClick={eliminarArchivo} color="primary">
                  Confirmar eliminación
               </Button>
            </DialogActions>
         </Dialog>

         <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">{"Documento"}</DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  

                  <object data={createURL(file.path)} type="application/pdf" width="500" height="500">
                  <p>El navegador o dispositivo que estás utilizando no permite la previsualización del PDF. Presiona <a href={createURL(file.path)}>aquí</a> para descargar el documento.</p>
                  </object>


               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
               >
                     <Grid item xs={4}>
                        <Button onClick={handleClose} color="primary">Cerrar</Button>
                     </Grid>
                     <Grid item xs={4} style={{textAlign:'center'}}>
                        <Button onClick={openInWeb} color="primary">Abrir <OpenInBrowser /></Button>
                     </Grid>
                     <Grid item xs={4} style={{textAlign:'right'}}>
                     <Button onClick={handleOpenConfirm} color="primary">Eliminar <Delete /></Button>
                     </Grid>
               </Grid>
            </DialogActions>
         </Dialog>
      </>
   )
}

export default FileView;