import React from 'react';
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Divider,
   Grid,
   Typography
} from '@material-ui/core';
import {
   Check,
   Clear,
   Delete,
   Info
} from '@material-ui/icons';
import './ResultadoDialogo.css';

const ResultadoDialogo = ({ open, handleClose, submitResultado }) => {
   const [openConfirm, setOpenConfirm] = React.useState(false);

   const handleCloseConfirm = () => {
      setOpenConfirm(false);
   };

   const handleSubmitResultado = (resultado) => {
      handleCloseConfirm();
      handleClose();
      submitResultado(resultado);
   }

   return (
      <Dialog
         open={open}
         onClose={handleClose}
         aria-labelledby="draggable-dialog-title"
         className="resultado-dialog"
      >
         <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Resultado de exámen
         </DialogTitle>
         <DialogContent>
            <DialogContentText>
               <Divider />
               <Grid container spacing={0} alignItems="stretch">
                  <Grid className="tableOperacion" item xs={4} md={4} style={{ padding: '10px' }}>
                     <Typography variant="h4" component="h2" className="resultado-positivo">
                        <Check /> Reactivo
                     </Typography>
                     <div style={{ marginTop: '20px', marginBottom: '20px' }}><small>Presiona el botón para guardar que el resultado del exámen ha salido positivo</small></div>
                     <Button
                        className="btn-positivo"
                        onClick={() => handleSubmitResultado('reactivo')}
                        color="primary">
                        Reactivo
                     </Button>
                  </Grid>

                  <Grid className="tableOperacion" item xs={4} md={4} style={{ padding: '10px' }}>
                     <Typography variant="h4" component="h2" className="resultado-negativo">
                        <Clear /> No reactivo
                     </Typography>

                     <div style={{ marginTop: '20px', marginBottom: '20px' }}><small>Presiona el botón para guardar que el resultado del exámen ha salido negativo</small></div>
                     <Button
                        className="btn-negativo"
                        onClick={() => handleSubmitResultado('no reactivo')}
                        color="primary">
                        No reactivo
                     </Button>
                  </Grid>

                  <Grid className="tableOperacion" item xs={4} md={4} style={{ padding: '10px' }}>
                     <Typography variant="h4" component="h2" className="resultado-negativo">
                        <Info /> Sin resultado
                     </Typography>

                     <div style={{ marginTop: '20px', marginBottom: '20px' }}><small>Presiona el botón para continuar sin generar el resultado</small></div>
                     <Button
                        className="btn-negativo"
                        onClick={() => handleSubmitResultado('sin resultado')}
                        color="primary">
                        Sin resultado
                     </Button>
                  </Grid>
               </Grid>
               <Divider />
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
               Cerrar
            </Button>

         </DialogActions>
      </Dialog>
   )
}
export default ResultadoDialogo;