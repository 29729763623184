import React, { useState, useEffect } from 'react';
import {
   Button,
   Card,
   Checkbox,
   CardContent,
   Divider,
   FormControl,
   FormControlLabel,
   Grid,
   TextField,
   Snackbar,
   RadioGroup
} from '@material-ui/core';
import {
   NavigateNext
} from '@material-ui/icons';
import PersonaService from './../../../services/persona';
import TipoOperativoServices from './../../../services/tipoOperativo';
import { RutFormat, formatRut, validateRut } from '@fdograph/rut-utilities';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from './../../../components/Loading/Loading';

const BuscarPaciente = ({ handleUser, setReady, setTipoOperativo, tipoOperativo }) => {
   const [operativoDefault] = useState(tipoOperativo || "")
   const [operativo, setOperativo] = useState();
   const [isRut, setIsRut] = useState(false);
   const [dataExam, setDataExam] = useState({
      pasaporte: "",
      rut: "",
      operativo: tipoOperativo || ""
   });
   const [dataError, setDataError] = useState({
      rut: false,
      pasaporte: false,
      operativo: false
   });
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      TipoOperativoServices.getTipoOperativosActivos()
         .then(res => {
            setOperativo(res.data);
            setIsLoading(false);
         })
         .catch(error => {
            setOpen(true);
            setIsLoading(false);
         })

   }, []);

   const handleChange = (e) => {
      let data = {
         ...dataExam,
         [e.target.name]: e.target.value
      }
      setDataExam(data);
      setDataError(errorState => ({
         ...errorState,
         [e.target.name]: false
      }))
   };

   const submit = () => {
      let ready = true;

      if (dataExam.rut.trim() === ""
         && dataExam.pasaporte.trim() === ""
         && dataExam.operativo.trim() === "") {
         errorEnCampo("pasaporte");
         errorEnCampo("rut");
         errorEnCampo("operativo");
         ready = false;
      }

      if (!isRut && dataError.rut) {
         ready = false;
      }

      if (ready) {
         let data;

         data = {
            ...dataExam,
            esExtranjero: isRut
         };

         PersonaService.buscarPersona(data)
            .then((res) => {
               console.log(dataExam.operativo.toLowerCase());
               setTipoOperativo(dataExam.operativo);
               handleUser(res.data.data);
               //setUser(res.data.data);
            })
            .catch((error) => {
               setOpen(true);
            });

         setReady(true);
      }
   }

   const errorEnCampo = (campo) => {
      setDataError(errorState => ({
         ...errorState,
         [campo]: true
      }));
   };

   const onBlurRut = evnt => {
      let rutLower = (dataExam.rut + '').toLowerCase().trim();
      const isRutValid = validateRut(rutLower);
      if (isRutValid) {
         let formatRutValue = formatRut(rutLower, RutFormat.DASH);
         setDataExam(formState => ({
            ...formState,
            rut: formatRutValue
         }));
      } else {
         setDataError(errorState => ({
            ...errorState,
            rut: true
         }))
      }
   };

   /* Alert */
   const [open, setOpen] = React.useState(false);
   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setOpen(false);
   };


   if (isLoading) {
      return (
         <Loading />
      )
   } else {
      return (
         <>
            <form autoComplete="off">
               <Card>
                  <CardContent>
                     <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        alignContent="center"
                     >
                        <Grid
                           item
                           md={3}
                           sm={3}
                           xs={12}
                        >
                           <FormControl component="fieldset">
                              <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                 <FormControlLabel
                                    value="extranjero"
                                    control={<Checkbox onClick={() => setIsRut(!isRut)} color="primary" />}
                                    label="Extranjero"
                                    labelPlacement="end"
                                 />
                              </RadioGroup>
                           </FormControl>
                        </Grid>
                        <Grid
                           item
                           md={3}
                           sm={3}
                           xs={12}
                        >
                           {isRut ?
                              <TextField
                                 fullWidth
                                 label="Pasaporte / DNI"
                                 name="pasaporte"
                                 onChange={handleChange}
                                 value={dataExam.pasaporte}
                                 variant="outlined"
                                 required
                              />
                              :
                              <TextField
                                 fullWidth
                                 label="Rut"
                                 helperText="Ejemplo: 11222333-K"
                                 name="rut"
                                 onChange={handleChange}
                                 onBlur={onBlurRut}
                                 value={dataExam.rut}
                                 variant="outlined"
                                 required
                                 error={dataError.rut}
                                 helperText={
                                    dataError.rut
                                       ? "El rut ingresado no es válido"
                                       : null}
                              />
                           }
                        </Grid>
                        <Grid
                           item
                           md={3}
                           sm={3}
                           xs={12}
                        >
                           <Autocomplete
                              options={operativo}
                              defaultValue={operativoDefault}
                              getOptionLabel={(option) => {
                                 if (option.hasOwnProperty('nombre')) {
                                    return option.nombre;
                                 }
                                 return option;
                              }}
                              renderInput={(params) =>
                                 <TextField
                                    {...params}
                                    error={dataError.operativo}
                                    helperText={
                                       dataError.operativo
                                          ? "Debes seleccionar una opción"
                                          : null}
                                    name="operativo"
                                    onBlur={handleChange}
                                    label="operativo"
                                    variant="outlined" />
                              }
                           />
                        </Grid>
                        <Grid
                           item
                           md={3}
                           sm={3}
                           xs={12}

                        >
                           <Button
                              color="primary"
                              variant="contained"
                              onClick={submit}
                           >
                              Continuar <NavigateNext />
                           </Button>
                        </Grid>
                     </Grid>
                  </CardContent>
                  <Divider />
               </Card>
            </form>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
               <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
                  Ha ocurrido un error en la operación, recarga la página y vuelve a intentar.
               </MuiAlert>
            </Snackbar>
         </>
      )
   }
}

export default BuscarPaciente;