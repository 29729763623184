import API from './api';
import URLS from './URLS';

const ArchivoServices = {

   cargarInformes: function (tipo, file) {
      return API.axios({
         method: 'post',
         url: URLS.archivo + '/' + tipo + '/cargar',
         data: file,
         headers: {
            'Content-Type': `multipart/form-data;`,
         },
      });
   },

   eliminarArchivo: function (archivo) {
      return API.axios({
         method: 'POST',
         url: URLS.archivo + '/eliminar',
         data: archivo
      })
   },

   generarInforme: function (data) {
      return API.axios({
         method: 'POST',
         url: URLS.archivo + '/generar',
         data: data
      })
   },

   verInforme: function (data) {
      return API.axios({
         method: 'GET',
         url: URLS.archivo + '/ver/' + data
      })
   }
};
export default ArchivoServices;