import React, { useEffect, useState } from 'react';
import {
   Input,
   Button,
   Card,
   CardContent,
   Divider,
   Grid,
   TextField
} from '@material-ui/core';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/es";
import {
   DatePicker,
   MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
   NavigateNext
} from '@material-ui/icons';
import MaskedInput from 'react-text-mask';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RegionesComunas from '../../resources/RegionesComunas';
import RequestHeader from './RequestHeader';
moment.locale("es");

const RequestExamSecond = ({ setStep, updateExamenPCR, examenPCR, tipoExamen, submitExamen, isOperacion }) => {
   const [regionInput] = useState(examenPCR.region);
   const [comunaInput] = useState(examenPCR.comuna);
   const [tipoExamenInput] = useState(examenPCR.tipoExamen);
   const [fechaAgendamiento, setFechaAgendamiento] = useState(null);
   const [comunas, setComunas] = useState([]);
   const [dataExam, setDataExam] = useState(examenPCR ||
   {
      region: examenPCR.region || "",
      comuna: examenPCR.comuna || "",
      ciudad: "",
      calle: "",
      telefono: "",
      correo: "",
      fechaAgendamiento: "",
      tipoExamen: ""
   });
   const [dataError, setDataError] = useState({
      region: false,
      comuna: false,
      ciudad: false,
      calle: false,
      telefono: false,
      correo: false,
      fechaAgendamiento: false,
      tipoExamen: false
   });

   useEffect(() => {
      if (examenPCR && examenPCR.comuna) {
         handleComunas(examenPCR.region);
      } else {
         errorEnCampo("comuna");
      }
   }, []);

   useEffect(() => {
      if (examenPCR.fechaAgendamiento) {
         setFechaAgendamiento(moment(examenPCR.fechaAgendamiento, 'DD/MM/YYYY'));
      }
   }, []);

   useEffect(() => {
      let fecha = moment(fechaAgendamiento).format('DD/MM/YYYY');
      setDataExam(data => ({
         ...data,
         fechaAgendamiento: fecha
      }));
   }, [fechaAgendamiento]);

   const submit = () => {
      let validate = {
         region: false,
         comuna: false,
         ciudad: false,
         calle: false,
         telefono: false,
         correo: false,
         fechaAgendamiento: false,
         tipoExamen: false
      };
      validacionCampos(validate);

      if (!hasError(validate)) {
         checkTipoExamen();
      } else {
         setDataError(validate);
      }
   }

   const hasError = (validate) => {
      let errorFound = true;
      if (!validate.region
         && !validate.comuna
         && !validate.ciudad
         && !validate.calle
         && !validate.telefono
         && !validate.correo
         && !validate.fechaAgendamiento
         && !validate.tipoExamen) {
         errorFound = false;
      }
      return errorFound;
   };

   const validacionCampos = (validate) => {
      validate.region = checkState("region");
      validate.comuna = checkState("comuna");
      validate.ciudad = checkState("ciudad");
      validate.calle = checkState("calle");
      validate.telefono = checkState("telefono");
      validate.correo = checkCorreo();
      validate.fechaAgendamiento = checkFechaAgendamiento();
      validate.tipoExamen = checkState("tipoExamen");
   }

   const checkState = (atributo) => {
      if (dataExam[atributo]) {
         if (dataExam[atributo].trim() === "" ||dataExam[atributo] === "Seleccionar...") {
            return true;
         }
         
      } else {
         return true;
      }
      return false;
   }

   const checkFechaAgendamiento = () => {
      if (fechaAgendamiento) {
         return false;
      } else {
         return true;
      }
   }

   const checkCorreo = () => {
      return !(/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(dataExam.correo));
   }

   const checkTipoExamen = () => {
      if (isOperacion) {
         submitExamen(dataExam);
      } else {
         if (dataExam.tipoExamen === "PCR" || dataExam.tipoExamen === "Antigeno") {
            updateExamenPCR(dataExam);
            setStep(3);
         } else {
            updateExamenPCR(dataExam);
            submitExamen(dataExam);
         }
      }
   }

   const errorEnCampo = (campo) => {
      setDataError(errorState => ({
         ...errorState,
         [campo]: true
      }));
   };

   const handleRegion = (e) => {
      let data = {
         ...dataExam,
         region: e.target.value,
         comuna: "Seleccionar..."
      }
      setDataExam(data);

      let value = e.target.value;
      handleComunas(value);
   };

   const handleComunas = (value) => {
      let comunasFiltradas = RegionesComunas.filter((element =>
         element.name === value
      ))

      if (comunasFiltradas && comunasFiltradas[0]) {
         setComunas(comunasFiltradas[0].communes);
      }
   };

   const handleChange = (e) => {
      let data = {
         ...dataExam,
         [e.target.name]: e.target.value
      }
      setDataExam(data);
      setDataError(errorState => ({
         ...errorState,
         [e.target.name]: false
      }))
   };

   const removeError = () => {
      setDataError(errorState => ({
         ...errorState,
         fechaAgendamiento: false
      }))
   }

   return (
      <>
         <form autoComplete="off">
            <Card>
               <RequestHeader />
               <Divider />
               <CardContent>
                  <Grid
                     container
                     spacing={3}
                  >

                     <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                     >
                        <Autocomplete
                           options={RegionesComunas}
                           getOptionLabel={(option) => option.name}
                           defaultValue={RegionesComunas.find(region => region.name === regionInput)}
                           renderInput={(params) =>
                              <TextField
                                 {...params}
                                 name="region"
                                 required
                                 onBlur={handleRegion}
                                 onChange={handleRegion}
                                 label="Región"
                                 variant="outlined"
                                 error={dataError.region}
                                 helperText={
                                    dataError.region
                                       ? "Debes seleccionar una región"
                                       : null
                                 }
                              />
                           }
                        />
                     </Grid>
                     <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                     >
                        <Autocomplete
                           defaultValue={comunaInput}
                           options={comunas}
                           getOptionLabel={(option) => {
                              if (option.hasOwnProperty('name')) {
                                 return option.name;
                              }
                              return option;
                           }}
                           renderInput={(params) =>
                              <TextField
                                 {...params}
                                 name="comuna"
                                 required
                                 onBlur={handleChange}
                                 label="Comuna"
                                 variant="outlined"
                                 error={dataError.comuna}
                                 helperText={
                                    dataError.comuna
                                       ? "Debes seleccionar una comuna"
                                       : null
                                 }
                              />
                           }
                        />
                     </Grid>

                     <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                     >
                        <TextField
                           fullWidth
                           label="Ciudad"
                           name="ciudad"
                           onChange={handleChange}
                           value={dataExam.ciudad}
                           required
                           variant="outlined"
                           error={dataError.ciudad}
                           helperText={
                              dataError.ciudad
                                 ? "Debes ingresar una ciudad"
                                 : null
                           }
                        />
                     </Grid>
                     <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                     >
                        <TextField
                           fullWidth
                           label="Dirección"
                           name="calle"
                           onChange={handleChange}
                           value={dataExam.calle}
                           variant="outlined"
                           error={dataError.calle}
                           helperText={
                              dataError.calle
                                 ? "Debes ingresar tu dirección"
                                 : null
                           }
                        />
                     </Grid>
                     <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                     >
                        <TextField
                           required
                           fullWidth
                           value={dataExam.telefono}
                           onChange={handleChange}
                           label="Teléfono"
                           name="telefono"
                           type="number"
                           pattern="[0-9]*" 
                           inputMode="numeric" 
                           variant="outlined"
                           defaultValue={dataExam.telefono}
                           inputComponent={TextMaskCustom}
                           error={dataError.telefono}
                              helperText={
                                 dataError.telefono
                                    ? "Debes ingresar un teléfono"
                                    : null
                              }
                        />  
                     </Grid>
                     <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                     >
                        <TextField
                           fullWidth
                           label="Correo"
                           name="correo"
                           onChange={handleChange}
                           value={dataExam.correo}
                           variant="outlined"
                           required
                           type="email"
                           error={dataError.correo}
                           helperText={
                              dataError.correo
                                 ? "Debes ingresar un email"
                                 : null
                           }
                        />
                     </Grid>
                     <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                     >
                        <MuiPickersUtilsProvider
                           utils={MomentUtils}
                           libInstance={moment}
                        >
                           <DatePicker
                              required
                              fullWidth
                              disableToolbar
                              label="Fecha de exámen"
                              value={fechaAgendamiento}
                              onBlur={removeError}
                              name="fechaAgendamiento"
                              inputVariant="outlined"
                              format="DD/MM/yyyy"
                              error={dataError.fechaAgendamiento}
                              helperText={
                                 dataError.fechaAgendamiento
                                    ? "Debes ingresar una fecha"
                                    : null
                              }
                              onChange={setFechaAgendamiento}
                           />
                        </MuiPickersUtilsProvider>
                     </Grid>
                     <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                     >
                        <Autocomplete
                           defaultValue={tipoExamen.find(comu => comu.nombre === tipoExamenInput)}
                           options={tipoExamen}
                           getOptionLabel={(option) => option.nombre}
                           renderInput={(params) =>
                              <TextField
                                 {...params}
                                 name="tipoExamen"
                                 required
                                 onBlur={handleChange}
                                 label="Tipo Examen"
                                 variant="outlined"
                                 error={dataError.tipoExamen}
                                 helperText={
                                    dataError.tipoExamen
                                       ? "Debes seleccionar un tipo"
                                       : null
                                 }
                              />
                           }
                        />
                     </Grid>
                  </Grid>


                  <Grid
                     container
                     spacing={3}
                  >
                     <Grid
                        item
                        md={12}
                        xs={12}

                     >
                        <Divider />
                     </Grid>
                     <Grid
                        item
                        sm={6}
                        md={6}
                        xs={12}
                     >
                        <Button
                           color="primary"
                           variant="contained"
                           onClick={submit}
                        >
                           Continuar <NavigateNext />
                        </Button>
                     </Grid>

                     <Grid
                        item
                        sm={6}
                        md={6}
                        xs={12}
                        style={{ textAlign: 'right' }}
                     >
                        <Button
                           onClick={() => setStep(1)}
                        >
                           Volver
                        </Button>
                     </Grid>


                  </Grid>
               </CardContent>
               <Divider />

            </Card>
         </form>
      </>
   )
}

function TextMaskCustom(props) {
   const { inputRef, ...other } = props;
 
   return (
     <MaskedInput
       {...other}
       ref={(ref) => {
         inputRef(ref ? ref.inputElement : null);
       }}
       mask="(+569) 999 999 99"
       placeholderChar={'\u2000'}
       showMask
     />
   );
 }
 

export default RequestExamSecond;