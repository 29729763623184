import { useState } from 'react';
import {
  IconButton,
  Grid, 
  Paper,
  Menu, 
  MenuItem,
  Tooltip,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle, 
  TextField,
  Snackbar
} from "@material-ui/core";
import { 
    AttachMoney, 
    CancelPresentation, 
    Edit, 
    HourglassEmpty, 
    LocalShipping, 
    Room,
    Phone,
    WhatsApp
} from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import ExamenServices from '../../../services/examen';
import './asignacion.css';


const CardAsignacion = ({examen, estados, reloadData, medioPago}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [ open, setOpen] = useState(false);
  const [data, setData] = useState(
    {
        montopago: null,
        mediopago: "",
        observacion:examen.observacion
    });
  const [dataError, setDataError] = useState({
      montopago: false,
      mediopago: false,
  });
  const [message, setMessage] = useState({
    tipo: "",
    mensaje: "",
  });

  /* Alert */
  const [errorOpen, setErrorOpen] = useState(false);
  const handleError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cambiarEstado = async (estado) => {
    if(estado.nombre === "Pagado"){
      setOpen(true);
    }else{

      let data = {
        idexamen: examen.idexamen,
        idestado: estado.idestado,
        montopago: null,
        mediopago: null
      }
      const response = await ExamenServices.updateExamen(data);
      if(response && response.data && response.data.success){
        reloadData();
      }else{
        console.log("FAIL");
      }
    }
  };

  const handleMedioPago = (idmediopago) => {
    if(idmediopago){
      let dataAux = {
        ...data,
        mediopago: idmediopago
      }
      setData(dataAux);
      setDataError(dataError => ({
        ...dataError,
        mediopago: false
      }))
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submit = async ()  => {
    let validate = {
      montopago: false,
      mediopago: false
    }
    validacionCampos(validate);

    if (!hasError(validate)) {
      let estadoPagado = estados.filter(item => item.nombre === "Pagado");
      console.log(estadoPagado);
      
      let dataAux = {
        idexamen: examen.idexamen,
        idestado: estadoPagado[0].idestado,
        idmediopago: data.mediopago,
        montopago: data.montopago
      }
      const response = await ExamenServices.updateExamen(dataAux);
      if(response && response.data && response.data.success){
        reloadData();
        setMessage({
          tipo: "success",
          mensaje: "Actualización realizada",
        });
      }else{
        setMessage({
          tipo: "error",
          mensaje: "Ha ocurrido un error en la actualización del exámen",
        });
      }
    } else {
      setDataError(validate);
    }
  }


  const saveObservacion = async (ev) => {
    console.log(ev.target.value);

    let dataAux = {
      idexamen: examen.idexamen,
      observacion:ev.target.value
    }

    const response = await ExamenServices.updateExamen(dataAux);
    if(response && response.data && response.data.success){
      setMessage({
        tipo: "success",
        mensaje: "Actualización realizada",
      });
    }else{
      setMessage({
        tipo: "error",
        mensaje: "Ha ocurrido un error en la actualización del exámen",
      });
    }
  }

  const validacionCampos = (validate) => {
    validate.montopago = (!data.montopago || !data.montopago > 0 );
    validate.mediopago =  (!data.mediopago);
  }

  const hasError = (validate) => {
    let errorFound = true;
     if (!validate.montopago
      && !validate.mediopago
      ) {
      errorFound = false;
    } 
    return errorFound;
  };

  const openDireccion = () => {
    let baseURL      = 'https://www.google.com/maps/place/';
    let direccion    = examen.persona.direccion + ', ' + examen.persona.comuna;
    let direccionAux = direccion.replace(" ", "+");
    window.open(baseURL + direccionAux, "_blank");
  }

  const openTelefono = () => {
    let baseURL     = 'tel:';
    let telefonoAux = examen.persona.telefono.replace("(+", "");
    telefonoAux     = telefonoAux.replace(")", "");
    telefonoAux     = telefonoAux.replace(" ", "");
    window.open(baseURL + telefonoAux, "_blank");
  }

  const openWhatssApp = () => {
    let baseURL     = 'https://api.whatsapp.com/send?lang=es&phone=';
    let telefonoAux = examen.persona.telefono.replace("(+", "");
    telefonoAux     = telefonoAux.replace(")", "");
    telefonoAux     = telefonoAux.replace(" ", "");
    window.open(baseURL + telefonoAux, "_blank");
  }

  const renderEstado = (estado) => {
    switch (estado) {
      case "Pendiente":
      return  <Tooltip title="Pendiente">
                <div className="circle-icon color-pendiente">
                  <HourglassEmpty />
                </div>
              </Tooltip>
      case "En ruta":
      return  <Tooltip title="En ruta">
                <div className="circle-icon color-encurso">
                  <LocalShipping />
                </div>
              </Tooltip>
      case "Pagado":
      return  <Tooltip title="Pagado">
                <div className="circle-icon color-pagados">
                  <AttachMoney />
                </div>
              </Tooltip>
      case "Cancelado":
      return  <Tooltip title="Cancelado">
                <div className="circle-icon color-cancelados">
                  <CancelPresentation />
                </div>
              </Tooltip>
      default:
      return  <Tooltip title="No definido">
                <div className="circle-icon color-pendiente">
                  <HourglassEmpty />
                </div>
              </Tooltip>
    } 
  };

  return(
    <>
    <Paper style={{ padding: "10px", marginBottom:'10px' }}>
      <Grid className="card-asignacion asignacion-detalle" container spacing={2} >
        <Grid item>
          <Grid item xs={4}>
            {renderEstado(examen.estado)}
          </Grid>
          <Grid item style={{marginTop:'10px'}}>
            
            <IconButton aria-label="Editar" color="primary" onClick={handleClick}>
              <Edit />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {estados.map(estado => 
                <MenuItem 
                  onClick={() => cambiarEstado(estado)} 
                  disabled={estado.nombre === examen.estado}>{estado.nombre}
                </MenuItem>
              )}
            </Menu>

          </Grid>
        </Grid>

        <Grid item md={4} sm={4} xs={8}>
          <small>Nº EXAMEN: <strong>{examen.idexamen}</strong></small>
          <h2>{examen.persona.identificador}</h2>
          <h2>{examen.persona.nombres}</h2>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Grid item>
            <small>Tipo exámen</small>
            <h4>{examen.tipoexamen}</h4>
          </Grid>
          <Grid item>
            <small>Teléfono:</small>
            <div style={{display:'flex', alignContent:'center', alignItems:'center' }}>
              <h4>{examen.persona.telefono}</h4>
              <IconButton 
                aria-label="Teléfono" 
                color='primary' 
                size='small'
                onClick={() => openTelefono()}
                style={{marginTop:'-10px'}}
              >
                <Phone style={{fontSize:'18px'}} />
              </IconButton>
              <IconButton 
                aria-label="WhatssApp" 
                color='primary' 
                size='small'
                onClick={() => openWhatssApp()}
                style={{marginTop:'-10px'}}
              >
                <WhatsApp style={{fontSize:'18px'}} />
              </IconButton>
            </div>
          </Grid>
          {examen.mediopago && examen.montopago &&
            <Grid item>
              <small>Pago:</small>
              <div style={{display:'flex', alignContent:'center', alignItems:'center' }}>
                <h4>
                  <NumberFormat
                    value={examen.montopago}
                    displayType={'text'}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix={'$'}
                    renderText={(value, props) => <>{value} </>}
                  />
                - {examen.mediopago}
                </h4>
                <IconButton 
                  aria-label="Editar" 
                  color='primary' 
                  size='small'
                  onClick={() => setOpen(true)}
                  style={{marginTop:'-10px'}}
                >
                  <Edit style={{fontSize:'18px'}} />
                </IconButton>
              </div>
            </Grid>
          }
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Grid item>
            <small>Dirección:</small>
            <div style={{display:'flex', alignContent:'center', alignItems:'center' }}>
              <h4>{examen.persona.direccion}, {examen.persona.comuna}</h4>
              <IconButton 
                aria-label="Dirección" 
                color='primary' 
                size='small'
                onClick={() => openDireccion()}
                style={{marginTop:'-10px'}}
              >
                <Room style={{fontSize:'18px'}} />
              </IconButton>
            </div>
          </Grid>
          <Grid item>
            
            <div style={{display:'flex', alignContent:'center', alignItems:'center', marginTop:'6px',marginBottom:'5px' }}>
              <TextField
                label="Observacion"
                value={data.observacion}
                onBlur={saveObservacion}
                onChange={handleChange}
                name="observacion"
                variant="outlined"
                fullWidth
                multiline
                size="small"
                rows={2}
                error={dataError.observacion}
                helperText={
                  dataError.observacion
                    ? "Debes ingresar un monto"
                    : null}
              />
            </div>
          </Grid>
          
        </Grid>
      </Grid>
    </Paper>

    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">{"Registrar Pago"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
              <Autocomplete
                options={medioPago}
                getOptionLabel={(option) => option.nombre}
                onChange={(event, newValue) => {
                  if(newValue){
                    handleMedioPago(newValue.idmediopago)
                  }
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        name="mediopago"
                        label="Medio pago"
                        error={dataError.mediopago}
                        helperText={
                            dataError.mediopago
                                ? "Debes seleccionar una opción"
                                : null}
                        variant="outlined" />
                } />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  label="Monto pago"
                  value={data.montopago}
                  onChange={handleChange}
                  name="montopago"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  error={dataError.mediopago}
                  helperText={
                    dataError.mediopago
                        ? "Debes ingresar un monto"
                        : null}
                />
              </Grid>
            </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
        <Button onClick={submit} color="primary">Guardar</Button>
      </DialogActions>
    </Dialog>

    <Snackbar open={errorOpen} autoHideDuration={20000} onClose={handleError}>
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleError}
        severity={message.tipo}
      >
        {message.mensaje}
      </MuiAlert>
    </Snackbar>
  </>
  )
};


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=','
      isNumericString
      prefix="$"
    />
  );
}

export default CardAsignacion;