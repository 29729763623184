import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OperativoServices from '../../services/operativo';
import { Page, PDFViewer, PDFDownloadLink, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import LogoCuiden from './../../resources/images/logoCuiden.png';
import firma from './../../resources/images/firma.png';
import Loading from './../../components/Loading/Loading';
import RobotoBold from './../../resources/font/Roboto-Bold.ttf';
import RobotoBoldItalic from './../../resources/font/Roboto-BoldItalic.ttf';
import RobotoMedium from './../../resources/font/Roboto-Medium.ttf';
import RobotoMediumItalic from './../../resources/font/Roboto-MediumItalic.ttf';

//https://react-pdf.org/
Font.register({
   family: 'Roboto',
   fonts: [
      {
         src: RobotoMedium
      },
      {
         src: RobotoBold,
         fontWeight: 'bold'
      },
      {
         src: RobotoMediumItalic,
         fontWeight: 'normal',
         fontStyle: 'italic'
      },
      {
         src: RobotoBoldItalic,
         fontWeight: 'bold',
         fontStyle: 'italic'
      }
   ]
})

const styles = StyleSheet.create({
   body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 50,
   },
   subtitle: {
      fontSize: 14,
      marginTop: 20,
      marginBottom: 20
   },
   text: {
      margin: 2,
      marginBottom: 4,
      fontSize: 12,
      textAlign: 'justify',
      fontFamily: 'Roboto',
   },
   textSign: {
      margin: 2,
      marginBottom: 6,
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Roboto',
   },
   span: {
      color: 'grey',
   },
   image: {
      maxWidth: 200,
      margin: 'auto',
      marginBottom: 30,
      marginTop: 20
   },
   imageSign: {
      maxWidth: 200,
      margin: 'auto',
      marginBottom: 30,
      marginTop: 60
   },
   header: {
      fontSize: 18,
      marginBottom: 40,
      textAlign: 'center',
      color: 'black',
      fontWeight: 'bold',
      fontFamily: 'Roboto'
   },
   pdfviewer: {
      width: '100%',
      height: '100vh',
      overflow: 'hidden'
   }
});

const PrintableResultadoOperacionPDF = () => {
   const { data } = useParams();
   const [isLoading, setIsLoading] = useState(true);
   const [resultado, setResultado] = useState({
      empresa: '',
      fecha: '',
      iddetalleoperacion: null,
      lugartesteo: '',
      primerapellido: '',
      primernombre: '',
      resultado: '',
      rut: '',
      segundoapellido: '',
      segundonombre: ''
   });

   useEffect(() => {
      OperativoServices.getResultadoOperativo(data)
         .then((res) => {
            if (res.data.success) {
               setResultado(res.data.data);
            }
            setIsLoading(false);
         })
         .catch((error) => {
            setIsLoading(false);
            console.log(error);
         })
   }, []);

   if(isLoading){
      return(
         <Loading />
      )
   } else {
      return (
         <PDFViewer style={styles.pdfviewer}>
            <Document>
               <Page size="LETTER" style={styles.body}>
                  <Image
                     style={styles.image}
                     src={LogoCuiden}
                  />
                  <Text style={styles.header}>Test rapido Antigenos SARS COV2</Text>
                  <Text style={styles.subtitle}>Informacion Personal Paciente:</Text>
                  <Text style={styles.text}><span style={styles.span}>Rut: </span>{resultado.rut}</Text>
                  <Text style={styles.text}><span style={styles.span}>Nombres: </span>{resultado.primernombre + ' ' + resultado.segundonombre + ' ' + resultado.primerapellido + ' ' + resultado.segundoapellido}</Text>
                  <Text style={styles.text}><span style={styles.span}>Empresa: </span>{resultado.empresa}</Text>
                  <Text style={styles.text}><span style={styles.span}>Lugar de testeo: </span>{resultado.lugartesteo}</Text>
                  <Text style={styles.text}><span style={styles.span}>Fecha:</span>{resultado.fecha}</Text>
   
                  <Text style={styles.subtitle}>Resultado Test de Antigenos Nasofaringeo:</Text>
                  <Text style={styles.text}><span style={styles.span}>Resultado: </span>{resultado.resultado}</Text>
   
                  <Image
                     style={styles.imageSign}
                     src={firma}
                  />
   
                  <Text style={styles.textSign}><span style={styles.span}>Digitador Responsable:</span></Text>
                  <Text style={styles.textSign}>EU MATIAS BRICENO AGUILERA</Text>
                  <Text style={styles.textSign}><span style={styles.span}>https://cuiden.cl</span></Text>
               </Page>
            </Document></PDFViewer>
      )
   }
};

export default PrintableResultadoOperacionPDF;