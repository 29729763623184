import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RequestExamFirst from './RequestExamFirst';
import RequestExamSecond from './RequestExamSecond';
import RequestExamThird from './RequestExamThird';
import Loading from './../../components/Loading/Loading';
import Failscreen from './../../components/Failscreen/Failscreen';
import SucessMessage from './../../components/SucessMessage/SucessMessage';
import NacionalidadServices from './../../services/nacionalidad';
import SexoServices from './../../services/sexo';
import MotivoExamenServices from './../../services/motivoExamen';
import SintomasServices from './../../services/sintomas';
import ExamenServices from './../../services/examen';
import OperativoServices from './../../services/operativo';
import TipoExamenServices from './../../services/tipoExamen';
import { Button, Container, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import TopLoading from './../../components/TopLoading/TopLoading';
import './RequestExam.css';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
moment.locale("es");

const RequestExam = ({ user, backButton, tipoOperativo, printable, operacion }) => {
   const [isSending, setIsSending] = useState(false);
   const [nacionalidad, setNacionalidad] = useState([]);
   const [sexo, setSexo] = useState([]);
   const [motivoExamen, setMotivoExamen] = useState([]);
   const [sintomas, setSintomas] = useState([]);
   const [tipoExamen, setTipoExamen] = useState([]);
   const [step, setStep] = useState(0);
   const [isBackButton] = useState(backButton || true);
   const [isPrintable] = useState(printable || false);
   const [isOperacion] = useState(operacion || false);
   const history = useHistory();
   const [examenPCR, setExamenPCR] = useState(
      {
         idexamen: "",
         rut: "",
         primerNombre: "",
         segundoNombre: "",
         primerApellido: "",
         segundoApellido: "",
         fechaNacimiento: null,
         telefono: "",
         correo: "",
         calle: "",
         ciudad: "",
         comuna: "",
         region: "",
         pasaporte: "",
         sexo: "",
         nacionalidad: "Chile",
         esExtranjero: false,
         tieneSintomas: false,
         fechaSintomas: "",
         fechaContactoEstrecho: "",
         motivoExamen: "Laboral",
         auxSintomas: {
            ninguno: true
         },
         tipoExamen: '',
         sintomas: [],
         prevision: "",
         fechaAgendamiento: null,
      }
   );

   const updateExamenPCR = (data) => {
      let state = examenPCR;
      setExamenPCR({
         ...state,
         ...data
      });
   }

   useEffect(() => {

      let data = {
         idexamen: "",
         rut: "",
         primerNombre: "",
         segundoNombre: "",
         primerApellido: "",
         segundoApellido: "",
         fechaNacimiento: null,
         telefono: "",
         correo: "",
         calle: "",
         ciudad: "",
         comuna: "",
         region: "",
         pasaporte: "",
         sexo: "",
         nacionalidad: "Chile",
         esExtranjero: false,
         tieneSintomas: false,
         fechaSintomas: "",
         fechaContactoEstrecho: "",
         motivoExamen: "",
         auxSintomas: {
            ninguno: false
         },
         tipoExamen: '',
         sintomas: [],
         fechaAgendamiento: null,
      };

      if (user) {
        let telefonoTrimmed = user.telefono.replace("(","").replace("+","").replace(")","").replaceAll(" ","");

         let fecha= null;
         if(user.fechaNacimiento){
            fecha = moment(user.fechaNacimiento, 'DD/MM/YYYY');
         }
         data = {
            ...user,
            fechaNacimiento: fecha,
            tieneSintomas: false,
            fechaSintomas: "",
            fechaContactoEstrecho: "",
            motivoExamen: "",
            auxSintomas: {
               ninguno: false
            },
            tipoExamen: '',
            sintomas: [],
            fechaAgendamiento: "",
            telefono: telefonoTrimmed
         };
      }

      if (isOperacion) {
         //Agrego reglas de negocio
         //1. Fecha agendamiento fijada en día actual
         //2. Por defecto el tipo de examen es antigeno
         let fechaAgendamientoHoy = moment().format('DD/MM/YYYY');

         data['fechaAgendamiento'] = fechaAgendamientoHoy;
         data['tipoExamen'] = 'Antigeno';
         data['motivoExamen'] = "Laboral";
         data['auxSintomas'] = {
            ninguno: true
         };
      }

      setExamenPCR(data);

      fetchAll();
   }, [user]);

   const reload = () => {
      window.location.reload();
   }

   const fetchAll = async () => {
      try {
         const nacionalidad = await NacionalidadServices.getNacionalidades();
         const sexo = await SexoServices.getSexos();
         const examen = await MotivoExamenServices.getMotivoExamenes();
         const sintoma = await SintomasServices.getSintomas();
         const tipoExamen = await TipoExamenServices.getTipoExamenes();
         setNacionalidad(nacionalidad.data);
         setSexo(sexo.data);
         setMotivoExamen(examen.data);
         setSintomas(sintoma.data);
         setTipoExamen(tipoExamen.data);
         (nacionalidad && sexo && examen && sintoma && tipoExamen) ? setStep(1) : setStep(5);
      } catch (e) {
         setStep(5);
      }
   }

   const submitExamen = async (data) => {
      setIsSending(true);

      let operativo = {
         tipoOperativo: tipoOperativo
      };
      let state = {
         ...examenPCR,
         ...data,
         ...operativo
      };
      setExamenPCR(state);

      try {
         const response = await ExamenServices.newExamen(state);
         if (response && response.data.data === "OK") {
            let state = examenPCR;
            setExamenPCR({
               ...state,
               idexamen: response.data.idexamen
            });

            if (isOperacion) {
               let detalleOperacion = {
                  estado: 'ingresado',
                  idpersona: response.data.idpersona,
                  idoperacion: operacion,
                  idexamen: response.data.idexamen
               };

               const responseDetalle = await OperativoServices.saveOperacionDetalle(detalleOperacion);
               if (responseDetalle && responseDetalle.data.success) {
                  history.push('/admin/in-situ/' + operacion);
               } else {
                  setErrorOpen(true);
               }
            } else {
               setStep(4);
               //finalstep
            }
         } else {
            setErrorOpen(true);
         }
         setIsSending(false);

      } catch (error) {
         setErrorOpen(true);
         setIsSending(false);
      }
   }

   /* Alert */
   const [errorOpen, setErrorOpen] = React.useState(false);
   const handleError = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setErrorOpen(false);
   };

   if (step === 0) {
      return <Loading />
   } else {
      return (
         <Container maxWidth="md" style={{ marginTop: '30px' }}>
            {isSending &&
               <TopLoading />
            }

            {step === 1 ?
               <RequestExamFirst
                  setStep={setStep}
                  examenPCR={examenPCR}
                  updateExamenPCR={updateExamenPCR}
                  sexo={sexo}
                  nacionalidad={nacionalidad}
                  backButton={isBackButton}
                  tipoOperativo={tipoOperativo}
               />
               : null}

            {step === 2 ?
               <RequestExamSecond
                  setStep={setStep}
                  examenPCR={examenPCR}
                  updateExamenPCR={updateExamenPCR}
                  tipoExamen={tipoExamen}
                  submitExamen={submitExamen}
                  isOperacion={isOperacion}
               />
               : null}

            {step === 3 ?
               <RequestExamThird
                  setStep={setStep}
                  examenPCR={examenPCR}
                  updateExamenPCR={updateExamenPCR}
                  motivoExamen={motivoExamen}
                  sintomas={sintomas}
                  submitExamen={submitExamen}
               />
               : null}

            {step === 4 ?
               <SucessMessage
                  isPrintable={isPrintable}
                  examenPCR={examenPCR}
                  backButton={isBackButton}
               />
               : null}

            {step === 5 ?
               <Failscreen>
                  <Button
                     variant="outlined"
                     color="primary"
                     onClick={reload} >Recargar</Button>
               </Failscreen>
               : null}

            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleError}>
               <MuiAlert elevation={6} variant="filled" onClose={handleError} severity="error">
                  Ha ocurrido un error en el envío, revisa que hayas completado todos los campos de manera correcta.
               </MuiAlert>
            </Snackbar>

         </Container>
      )
   }
}
export default RequestExam;