import React, { useState, useEffect } from 'react';
import {
   Grid,
   FormControl,
   Typography
} from '@material-ui/core';
import ConfigMensajeAyuda from './ConfigMensajeAyuda';
import ConfigEnvioCorreo from './ConfigEnvioCorreo';

const Configuracion = () => {
   return (
      <>
         <Grid container spacing={4}>
            <Grid item xs={12} style={{ padding: '25px 15px' }}>
               <Typography component="h1" variant="h6" color="inherit" >
                  Configuración
                  </Typography>
            </Grid>
         </Grid>

         <ConfigMensajeAyuda />

         <ConfigEnvioCorreo />
      </>
   );
}

export default Configuracion;