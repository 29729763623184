import API from './api';
import URLS from './URLS';

const TipoOperativoServices = {

   getTipoOperativos: function () {
      return API.axios({
         method: 'get',
         url: `${URLS.tipoOperativo}`
      });
   },

   getTipoOperativosActivos: function () {
      return API.axios({
         method: 'get',
         url: URLS.tipoOperativo + '/activos'
      });
   },

   saveTipoOperativos: function (operativoNuevo) {
      return API.axios({
         method: 'post',
         url: URLS.tipoOperativo + '/nuevo',
         data: operativoNuevo
      })
   },

   deleteTipoOperativos: function (idTipoOperativo) {
      return API.axios({
         method: 'delete',
         url: URLS.tipoOperativo + '/delete/' + idTipoOperativo
      })
   },

   updateTipoOperativos: function (operativo) {
      return API.axios({
         method: 'put',
         url: URLS.tipoOperativo + '/update/' + operativo.idtipooperativo,
         data: operativo
      })
   }
};

export default TipoOperativoServices;