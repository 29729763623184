import React, { useState } from 'react';
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Grid,
   TextField,
} from '@material-ui/core';
import {
   AddCircle
} from '@material-ui/icons';
import {
   DatePicker,
   DateTimePicker,
   MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

const resultado = [ { id:1, nombre:'Positivo', }, { id:2, nombre:'Negativo' } ]

const GenerarExamen = ({ open, data, handleClose, handleGenerarExamen }) => {
   const [fechaAtencion, setFechaAtencion] = useState(null);
   const [tomaMuestra, setTomaMuestra] = useState(null);
   const [dataExam, setDataExam] = useState(
      {
         nombre: data.persona.nombres + ' ' + data.persona.primerapellido + ' ' + data.persona.segundoapellido,
         identificador: data.persona.identificador,
         fechaNacimiento: data.persona.fechanacimiento,
         direccion: data.persona.direccion+ ', ' + data.persona.comuna + ', Chile',
         celular: data.persona.telefono,
         idpersona: data.persona.idpersona,
         idexamen: data.idexamen,
         fechaAtencion: "",
         tomaMuestra: "",
         resultado:"",
         nombreTest:"",
         tipoMuestra:"",
         observaciones:""
      });
      const [dataError, setDataError] = useState({
         fechaAtencion: false,
         tomaMuestra: false,
         resultado: false,
         nombreTest: false,
         tipoMuestra: false,
         observaciones: false
      });
   

   const handleChange = (e) => {
      let data = {
          ...dataExam,
          [e.target.name]: e.target.value
      }
      setDataExam(data);
      setDataError(errorState => ({
          ...errorState,
          [e.target.name]: false
      }))
  };

  const submit = () => {
      let validate = {
         fechaAtencion: false,
         tomaMuestra: false,
         resultado: false,
         nombreTest: false,
         tipoMuestra: false,
         observaciones: false
      }
      validacionCampos(validate);

      if (!hasError(validate)) {
         let jsonData = {
            nombre: dataExam.nombre,
            identificador: dataExam.identificador,
            fechaNacimiento: dataExam.fechaNacimiento,
            direccion: dataExam.direccion,
            celular: dataExam.celular,
            fechaAtencion: moment(fechaAtencion).format('DD/MM/YYYY'),
            tomaMuestra: moment(tomaMuestra).format('DD/MM/YYYY HH:mm'),
            resultado: dataExam.resultado,
            nombreTest: dataExam.nombreTest,
            tipoMuestra: dataExam.tipoMuestra,
            observaciones: dataExam.observaciones
         };
         
         let output = {
            idexamen: dataExam.idexamen,
            idpersona: dataExam.idpersona,
            jsondata: JSON.stringify(jsonData)
         }
         
         handleGenerarExamen(output);
      } else {
         setDataError(validate);
      }
   }

   const validacionCampos = (validate) => {
      validate.fechaAtencion = checkFecha(fechaAtencion);
      validate.tomaMuestra = checkFecha(tomaMuestra);
      validate.resultado = checkState("resultado");
      validate.nombreTest = checkState("nombreTest");
      validate.tipoMuestra = checkState("tipoMuestra");
      validate.observaciones = checkState("observaciones");
   }

   const checkState = (atributo) => {
      if (dataExam[atributo]) {
          if (dataExam[atributo].trim() === "") {
              return true;
          }
      } else {
          return true;
      }
      return false;
  }

   const hasError = (validate) => {
      let errorFound = true;
      if (!validate.fechaAtencion
         && !validate.tomaMuestra
         && !validate.resultado
         && !validate.nombreTest
         && !validate.tipoMuestra
         && !validate.observaciones){
         errorFound = false;
      }
      return errorFound;
   };

   const removeError = (campo) => {
      setDataError(errorState => ({
         ...errorState,
         [campo]: false
      }));
   };

   const checkFecha = (field) => {
      return field? false : true
  }

   return (
      <>
         <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">{"Generar exámen"}</DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  
               <Grid container spacing={3}>
                  
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: '16px' }}>
                     <TextField
                        fullWidth
                        required
                        label="Nombre paciente"
                        name="nombre"
                        variant="outlined"
                        onChange={handleChange}
                        value={dataExam.nombre.toUpperCase()}
                        error={dataError.nombre}
                        helperText={
                           dataError.nombre
                              ? "El nombre ingresado no es válido"
                              : null}
                     />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12} style={{ marginBottom: '4px' }}>
                     <TextField
                        fullWidth
                        required
                        label="Dirección"
                        name="direccion"
                        variant="outlined"
                        onChange={handleChange}
                        value={dataExam.direccion.toUpperCase()}
                        error={dataError.direccion}
                        helperText={
                           dataError.direccion
                              ? "La dirección ingresada no es válida"
                              : null}
                     />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} style={{ marginBottom: '4px' }}>
                     <TextField
                        fullWidth
                        required
                        label="RUT/DNI"
                        name="identificador"
                        variant="outlined"
                        onChange={handleChange}
                        value={dataExam.identificador.toUpperCase()}
                        error={dataError.identificador}
                        helperText={
                           dataError.identificador
                              ? "La dirección ingresada no es válida"
                              : null}
                     />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} style={{ marginBottom: '4px' }}>
                     <TextField
                        fullWidth
                        required
                        label="Fecha Nacimiento"
                        name="fechaNacimiento"
                        variant="outlined"
                        onChange={handleChange}
                        value={dataExam.fechaNacimiento.toUpperCase()}
                        error={dataError.fechaNacimiento}
                        helperText={
                           dataError.fechaNacimiento
                              ? "La dirección ingresada no es válida"
                              : null}
                     />
                  </Grid>
                  
                  <Grid item md={6} sm={6} xs={6} style={{ marginBottom: '4px' }}>
                     <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} >
                        <DatePicker
                           required
                           fullWidth
                           label="Fecha de atención"
                           value={fechaAtencion}
                           onBlur={() => removeError("fechaAtencion")}
                           name="fechaAtencion"
                           inputVariant="outlined"
                           format="DD/MM/yyyy"
                           openTo="date"
                           views={["year", "month", "date"]}
                           onChange={setFechaAtencion}
                           error={dataError.fechaAtencion}
                           helperText={
                              dataError.fechaAtencion
                                 ? "Fecha inválida"
                                 : "DD/MM/AAAA"
                           }
                        />
                     </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item md={6} sm={6} xs={6} style={{ marginBottom: '4px' }}>
                     <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} >
                        <DateTimePicker
                           required
                           fullWidth
                           ampm={false}
                           label="Toma de muestra"
                           value={tomaMuestra}
                           onBlur={() => removeError("tomaMuestra")}
                           format="DD/MM/yyyy HH:mm"
                           name="tomaMuestra"
                           inputVariant="outlined"
                           onChange={setTomaMuestra}
                           allowKeyboardControl={true}
                           error={dataError.tomaMuestra}
                           helperText={
                              dataError.tomaMuestra
                                 ? "Fecha inválida"
                                 : "DD/MM/AAAA"
                           }
                        />
                     </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item md={6} sm={6} xs={6} style={{  marginBottom: '4px' }}>
                     <Autocomplete
                        options={resultado}
                        freeSolo
                        getOptionLabel={(option) => option.nombre}
                        renderInput={(params) =>
                           <TextField
                              {...params}
                              name="resultado"
                              onBlur={handleChange}
                              label="Resultado"
                              error={dataError.resultado}
                              helperText={
                                 dataError.resultado
                                       ? "Debes seleccionar una opción"
                                       : null}
                              variant="outlined" />
                        } />
                  </Grid>

                  <Grid item md={6} sm={6} xs={6} style={{ marginBottom: '4px' }}>
                     <TextField
                        fullWidth
                        required
                        label="Nombre del test"
                        name="nombreTest"
                        variant="outlined"
                        onChange={handleChange}
                        value={dataExam.nombreTest}
                        error={dataError.nombreTest}
                        helperText={
                           dataError.nombreTest
                              ? "El nombre del test ingresado no es válido"
                              : null}
                     />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} style={{marginBottom: '4px' }}>
                     <TextField
                        fullWidth
                        required
                        label="Tipo de muestra"
                        name="tipoMuestra"
                        variant="outlined"
                        onChange={handleChange}
                        value={dataExam.tipoMuestra}
                        error={dataError.tipoMuestra}
                        helperText={
                           dataError.tipoMuestra
                              ? "El tipo de muestra ingresado no es válido"
                              : null}
                     />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} style={{  marginBottom: '4px' }}>
                     
                     <TextField
                        fullWidth
                        required
                        label="Observaciones"
                        name="observaciones"
                        variant="outlined"
                        onChange={handleChange}
                        value={dataExam.observaciones}
                        error={dataError.observaciones}
                        helperText={
                           dataError.observaciones
                              ? "La observación ingresada es inválida"
                              : null}
                     />

                  </Grid>
               </Grid>

               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} color="primary">Cerrar</Button>
               <Button onClick={submit} color="primary">Generar informe <AddCircle /></Button>
            </DialogActions>
         </Dialog>
      </>
   )
}

export default GenerarExamen;