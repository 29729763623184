import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ArchivoServices from './../../../services/archivo';
import MuiAlert from '@material-ui/lab/Alert';
import {
   Button,
   CircularProgress,
   Grid,
   ListItemText,
   ListItem,
   ListItemIcon,
   ListSubheader,
   List,
   Snackbar,
   Paper,
   Typography
} from '@material-ui/core';

import {
   Check,
   Close
} from '@material-ui/icons';

const baseStyle = {
   flex: 1,
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   padding: '20px',
   borderWidth: 2,
   borderRadius: 2,
   borderColor: '#eeeeee',
   borderStyle: 'dashed',
   backgroundColor: '#fafafa',
   color: '#bdbdbd',
   outline: 'none',
   transition: 'border .24s ease-in-out'
};

const activeStyle = {
   borderColor: '#2196f3'
};

const acceptStyle = {
   borderColor: '#00e676'
};

const rejectStyle = {
   borderColor: '#ff1744'
};

const CargaComponente = ({ tipoCarga }) => {
   const {
      acceptedFiles,
      fileRejections,
      isDragActive,
      isDragAccept,
      isDragReject,
      getRootProps,
      getInputProps
   } = useDropzone({
      accept: 'application/zip, application/octet-stream, zip,application/x-zip-compressed,multipart/x-zip,.rar,application/rar'
   });
   const [loading, setLoading] = useState(false);
   const [tipo] = useState(tipoCarga);
   const [resultado, setResultado] = useState({
      documentosErrores: [],
      documentosCargados: [],
      success: false
   });
   const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
   }), [
      isDragActive,
      isDragReject,
      isDragAccept
   ]);

   const acceptedFileItems = acceptedFiles.map(file => (
      <li key={file.path}>
         {file.path} - {file.size} bytes
      </li>
   ));

   const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
         {file.path} - {file.size} bytes
         <ul>
            {errors.map(e => (
               <li key={e.code}>{e.message}</li>
            ))}
         </ul>
      </li>
   ));

   /* Alert */
   const [open, setOpen] = React.useState(false);
   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setOpen(false);
   };

   const cargarArchivo = async () => {
      setLoading(true);
      let formData = new FormData();
      formData.append('file', acceptedFiles[0]);

      ArchivoServices.cargarInformes(tipo, formData)
         .then(res => {
            setResultado(res.data);
            setLoading(false);
         })
         .catch(err => {
            setOpen(true);
            setLoading(false);
         })
   };

   return (
      <>
         <Paper>
            <Grid container spacing={4}>
               <Grid item xs={12} style={{ padding: '20px 40px' }}>
                  <Typography component="h1" variant="h6" color="inherit" style={{ marginBottom: '20px' }} noWrap>
                     Masiva de {tipo}
                  </Typography>
                  <section>
                     <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <Typography component="h1" variant="h6" color="inherit" >
                           Carga el archivo aquí
                           </Typography>
                        <em>Solo se soportan archivos en formato .zip</em>
                     </div>
                     <p style={{ color: '#888' }}>Para procesar correctamente los archivos debes colocar como nombre de archivo el RUT o Pasaporte/DNI de la persona.</p><p style={{ color: '#888' }}>Ejemplo: Si la persona tiene un rut de 13.420.800-0 el archivo PDF debe llamarse 13420800-K.PDF</p>

                     <aside>
                        <ul>{acceptedFileItems}</ul>
                        <ul>{fileRejectionItems}</ul>
                     </aside>

                     {(resultado.documentosErrores && resultado.documentosErrores.length > 0) &&
                        < List subheader={<ListSubheader>Examen no encontrado</ListSubheader>} dense>
                           {resultado.documentosErrores.map((item =>
                              <ListItem>
                                 <ListItemIcon><Close /></ListItemIcon>
                                 <ListItemText primary={item.identificador} />
                              </ListItem>
                           ))}
                        </List>
                     }

                     {(resultado.documentosCargados && resultado.documentosCargados.length > 0) &&
                        < List subheader={<ListSubheader>Documentos cargados</ListSubheader>} dense>
                           {resultado.documentosCargados.map((item =>
                              <ListItem>
                                 <ListItemIcon><Check /></ListItemIcon>
                                 <ListItemText primary={item.identificador} />
                              </ListItem>
                           ))}
                        </List>
                     }

                     <Button
                        color="primary"
                        variant="contained"
                        disabled={acceptedFileItems.length === 0 || loading}
                        onClick={cargarArchivo}>
                        Procesar archivo
                        {loading &&
                           <CircularProgress style={{ marginLeft: '10px' }} size={20} />
                        }
                     </Button>
                  </section>
               </Grid>
            </Grid>
         </Paper >
         <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
               Ha ocurrido un error en el procesamiento de la carga masiva
            </MuiAlert>
         </Snackbar>
      </>
   );
}

export default CargaComponente;