import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
   Grid,
   Typography,
   Button,
   Grow,
   ClickAwayListener,
   Paper,
   Popper,
   MenuItem,
   MenuList
} from '@material-ui/core';
import MaterialTable from 'material-table';
import {
   AddBox,
   AccessAlarm,
   Assignment,
   AssignmentTurnedIn,
   ArrowDownward,
   ArrowDropDown,
   Check,
   ChevronLeft,
   ChevronRight,
   Clear,
   DeleteOutline,
   FirstPage,
   FilterList,
   Edit,
   LastPage,
   MoreVert,
   Remove,
   SaveAlt,
   Search,
   ViewColumn,
   PersonAdd,
   Timer,
   NotificationsActive
} from '@material-ui/icons';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import './DetalleOperacion.css';
import { makeStyles } from '@material-ui/core/styles';
import OperativoServices from './../../../services/operativo';
import Loading from './../../../components/Loading/Loading';
import ResultadoDialogo from './Componentes/ResultadoDialogo';
moment.locale("es");

const tableIcons = {
   Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
   Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
   Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
   DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
   Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
   Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
   Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
   PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
   ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
   ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const timeToReload = 20;

const DetalleOperacion = () => {
   const { idoperacion } = useParams();
   const [dataOperacion, setDataOperacion] = useState({});
   const [isLoading, setIsLoading] = useState(true);
   const [fetchTime, setFetchTime] = useState();
   const [detalleOperacionSeleccionada, setDetalleOperacionSeleccionada] = useState({});
   const history = useHistory();
   const handleIngresoPaciente = () => {
      history.push('/admin/in-situ/' + idoperacion + '/ingreso');
   }

   const enEspera = (enEspera) => {
      let fecha = moment(enEspera, 'YYYY/MM/DD hh:mm:ss');
      let ahora = moment();

      let segundos = ahora.diff(fecha, 'seconds');
      let resultado = {
         initialMinute: Math.trunc(segundos / 60),
         initialSeconds: (segundos - (Math.floor(segundos / 60) * 60))
      };

      return resultado;
   }

   useEffect(() => {
      let timer = setInterval(() => setFetchTime(moment()), timeToReload * 1000);
      return () => {
         clearInterval(timer);
      };
   }, []);

   useEffect(() => {
      fetchData();
   }, [fetchTime]);

   const fetchData = async () => {
      const response = await OperativoServices.getOperacionFull(idoperacion);
      if (response.data && response.data.success) {
         console.log(response.data.data);
         setDataOperacion(response.data.data);
      } else {
         console.log("algo falló");
      }

      setIsLoading(false);
   }

   const handleEliminar = async (detalleOperacion) => {
      const response = await OperativoServices.deleteOperacionDetalle(detalleOperacion);
      setFetchTime(moment());
   };

   const handleEstado = async (detalleOperacion) => {
      const response = await OperativoServices.cambiarEstadoOperacionDetalle(detalleOperacion);
      setFetchTime(moment());
   }

   const tituloIngresados = () => {
      return <> <div className="custom-table-title"><h6 className="MuiTypography-root MuiTypography-h6"> <Assignment /> Ingresados </h6> </div> </>
   }

   const tituloEnEspera = () => {
      return <> <div className="custom-table-title"><h6 className="MuiTypography-root MuiTypography-h6"> <Timer /> En espera </h6> </div> </>
   }

   const tituloAtendidos = () => {
      return <> <div className="custom-table-title"><h6 className="MuiTypography-root MuiTypography-h6"> <AssignmentTurnedIn /> Atendidos </h6> </div> </>
   }

   const handleResultado = (data) => {
      setDetalleOperacionSeleccionada(data);
      setShowResultado(true);
   }

   const handleTicket = (data) => {

   };

   const submitResultado = async (resultado) => {
      let detalleOperacionAux = detalleOperacionSeleccionada;
      detalleOperacionAux['resultado'] = resultado;
      detalleOperacionAux['fecharegistro'] = moment();

      const response = await OperativoServices.procesarResultado(detalleOperacionAux);
      console.log(response);
      setFetchTime(moment());
      window.location.reload();
   };

   /* Alert */
   const [showResultado, setShowResultado] = useState(false);
   const handleClose = () => {
      setShowResultado(false);
   };

   if (isLoading) {
      return (
         <Loading />
      )
   } else {
      return (
         <>
            <Grid container spacing={0}>
               <Grid item xs={8} md={8} style={{ padding: '10px' }}>
                  <Typography component="h1" variant="h6" color="inherit">
                     {dataOperacion.operacion.nombre} / <small>{dataOperacion.operacion.fechacreacion}</small>
                  </Typography>
               </Grid>
               <Grid item xs={4} md={4} style={{ padding: '10px 0px', textAlign: 'right' }}>
                  <Button
                     onClick={handleIngresoPaciente}
                  >
                     <PersonAdd style={{ marginRight: '10px' }} /> Ingreso paciente
                  </Button>
               </Grid>
            </Grid>

            <Grid container spacing={0} alignItems="stretch">
               <Grid className="tableOperacion" item xs={6} md={6} style={{ padding: '10px' }}>
                  <MaterialTable
                     icons={tableIcons}
                     title={tituloIngresados()}
                     columns={[
                        { title: 'Nombre', field: 'primernombre', render: row => row.primernombre + ' ' + row.primerapellido },
                        { title: 'RUT / DNI', field: 'nombreusuario' },
                        {
                           title: 'Opciones', render: dataRow =>
                              <PopOver>
                                 <MenuItem onClick={() => handleEstado(dataRow)}>Avanzar</MenuItem>
                                 <a href={'/printable/etiqueta/' + dataRow.idexamen} target="_blank" rel="noreferrer">
                                    <MenuItem>Ticket</MenuItem>
                                 </a>
                                 <MenuItem onClick={() => handleEliminar(dataRow)}>Eliminar</MenuItem>
                              </PopOver>
                        }

                     ]}
                     localization={{
                        header: {
                           actions: 'Acciones'
                        },
                        body: {
                           emptyDataSourceMessage: 'No existen datos'
                        },
                        pagination: {
                           labelRowsSelect: 'Filas por página',
                           labelDisplayedRows: '{from}-{to} de {count}',
                           firstTooltip: 'Primera página',
                           previousTooltip: 'Página anterior',
                           nextTooltip: 'Próxima página',
                           lastTooltip: 'Última página'
                        }
                     }}
                     data={dataOperacion.ingresados}
                     options={{
                        sorting: false,
                        search: false,
                        pageSizeOptions: [5, 20, 50, 80, 100],
                        actionsColumnIndex: -1
                     }}
                  />
               </Grid>
               <Grid className="tableOperacion" item xs={6} md={6} style={{ padding: '10px' }}>
                  <MaterialTable
                     icons={tableIcons}
                     title={tituloEnEspera()}
                     columns={[
                        {
                           title: 'Nombre', field: 'primernombre', render: rowData =>
                              <>
                                 <div>
                                    <span>{rowData.primernombre + ' ' + rowData.primerapellido}</span>
                                 </div>
                                 <div>
                                    <span>{rowData.nombreusuario}</span>
                                 </div>
                              </>
                        },
                        {
                           title: 'Esperando', field: 'fecharegistro', type: 'datetime',
                           render: rowData =>
                              <>
                                 <ContadorComponente
                                    inicial={enEspera(rowData.fecharegistro)}
                                 />
                              </>
                        },
                        {
                           title: 'Opciones', render: dataRow =>
                              <PopOver>
                                 <MenuItem onClick={() => handleResultado(dataRow)}>Resultado</MenuItem>
                                 <MenuItem onClick={() => handleEstado(dataRow)}>Retroceder</MenuItem>
                                 <MenuItem onClick={() => handleEliminar(dataRow)}>Eliminar</MenuItem>
                              </PopOver>
                        }
                     ]}
                     localization={{
                        header: {
                           actions: 'Acciones'
                        },
                        body: {
                           emptyDataSourceMessage: 'No existen datos'
                        },
                        pagination: {
                           labelRowsSelect: 'Filas por página',
                           labelDisplayedRows: '{from}-{to} de {count}',
                           firstTooltip: 'Primera página',
                           previousTooltip: 'Página anterior',
                           nextTooltip: 'Próxima página',
                           lastTooltip: 'Última página'
                        }
                     }}
                     data={dataOperacion.enespera}
                     options={{
                        sorting: false,
                        search: false,
                        pageSizeOptions: [5, 20, 50, 80, 100],
                        actionsColumnIndex: -1,
                     }}
                  />
               </Grid>
            </Grid>
            <Grid container spacing={0} alignItems="strech">
               <Grid className="tableOperacion" item xs={12} md={12} style={{ padding: '10px' }}>
                  <MaterialTable
                     icons={tableIcons}
                     title={tituloAtendidos()}
                     columns={[
                        {
                           title: 'Nombre',
                           field: 'primernombre',
                           filtering: false,
                           render: row => row.primernombre + ' ' + row.primerapellido
                        },
                        { title: 'RUT / DNI', field: 'nombreusuario' },
                        { title: 'Resultado', field: 'resultado' },
                        { title: 'Fecha', field: 'fecharegistro', type: 'datetime', filtering: false }
                     ]}
                     localization={{
                        header: {
                           actions: 'Acciones'
                        },
                        body: {
                           emptyDataSourceMessage: 'No existen datos'
                        },
                        pagination: {
                           labelRowsSelect: 'Filas por página',
                           labelDisplayedRows: '{from}-{to} de {count}',
                           firstTooltip: 'Primera página',
                           previousTooltip: 'Página anterior',
                           nextTooltip: 'Próxima página',
                           lastTooltip: 'Última página'
                        }
                     }}

                     data={dataOperacion.atendidos}
                     options={{
                        sorting: false,
                        search: false,
                        pageSizeOptions: [5, 20, 50, 80, 100],
                        filtering: true,
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true,
                     }}
                  />
               </Grid>
            </Grid>

            <ResultadoDialogo
               detalleOperacionSeleccionada={detalleOperacionSeleccionada}
               open={showResultado}
               handleClose={handleClose}
               submitResultado={submitResultado}
            />

         </>
      )
   }

};

export default DetalleOperacion;

const ContadorComponente = ({ inicial }) => {
   const { initialMinute = 0, initialSeconds = 0 } = inicial;
   const [minutes, setMinutes] = useState(initialMinute);
   const [seconds, setSeconds] = useState(initialSeconds);
   const [clases, setClases] = useState('default');

   useEffect(() => {
      let myInterval = setInterval(() => {
         setSeconds(seconds + 1);

         if (seconds === 59) {
            setSeconds(0);
            setMinutes(minutes + 1);
         }

         if (minutes > 3) {
            if (minutes > 5) {
               setClases('error');
            } else {
               setClases('warning');
            }
         }
      }, 1000)
      return () => {
         clearInterval(myInterval);
      };
   });

   return (
      <div className={clases}>
         {clases === 'warning' ?
            <AccessAlarm />
            :
            <>
               {clases === 'error' ?
                  <NotificationsActive />
                  :
                  <Timer />
               }
            </>
         }
         {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </div>
   )
};

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
   },
   paper: {
      marginRight: theme.spacing(2),
   },
}));


const PopOver = ({ children }) => {
   const classes = useStyles();
   const [open, setOpen] = React.useState(false);
   const anchorRef = React.useRef(null);

   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
         return;
      }

      setOpen(false);
   };

   function handleListKeyDown(event) {
      if (event.key === 'Tab') {
         event.preventDefault();
         setOpen(false);
      }
   }

   // return focus to the button when we transitioned from !open -> open
   const prevOpen = React.useRef(open);
   React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
         anchorRef.current.focus();
      }

      prevOpen.current = open;
   }, [open]);

   return (
      <>
         <div className={classes.root}>
            <div>
               <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
               >
                  <MoreVert />
               </Button>
               <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                  style={{ zIndex: '10' }}>
                  {({ TransitionProps, placement }) => (
                     <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                     >
                        <Paper>
                           <ClickAwayListener onClickAway={handleClose}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                 {children}
                              </MenuList>
                           </ClickAwayListener>
                        </Paper>
                     </Grow>
                  )}
               </Popper>
            </div>
         </div>
      </>
   )
}