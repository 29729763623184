import React, { useEffect, useState } from 'react';
import RequestExam from './../../RequestExam/RequestExam';
import BuscarPaciente from './BuscarPaciente';

const NuevoExamen = () => {
   const [user, setUser] = useState(null);
   const [ready, setReady] = useState(false);
   const [tipoOperativo, setTipoOperativo] = useState();

   const handleUser = (user) => {
      setReady(false);
      setUser(user);
   }

   useEffect(() => {
      if (user) {
         setReady(true);
      }
   }, [user]);

   return (
      <>
         <BuscarPaciente
            handleUser={handleUser}
            setReady={setReady}
            setTipoOperativo={setTipoOperativo}
         />
         {ready &&
            <RequestExam
               user={user}
               backButton="false"
               tipoOperativo={tipoOperativo}
               printable="true"
            />
         }
      </>
   )
};

export default NuevoExamen;