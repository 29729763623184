import API from './api';
import URLS from './URLS';

const MedioPagoService = {

    getMedioPagos: function () {
        return API.axios({
            method: 'get',
            url: `${URLS.mediopago}`
        });
    },

};

export default MedioPagoService;