import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Dashboard from './Layout/Dashboard';
import RequestExam from '../RequestExam/RequestExam';
import Examenes from './Examenes/Examenes';

let agendamientoDefault = process.env.REACT_APP_OPERATIVO || "Agendamiento";

const CuentaRouting = ({ account }) => {
    const history = useHistory();
    const [ready, setReady] = useState(false);
    const [user, setUser] = useState({
        persona: {
            idpersona: ''
        }
    });

    useEffect(() => {
        if (account && account.persona) {
            setUser(account);
        } else {
            console.log("push out to login");
            history.push('/login');
        }

        setReady(true);
    }, []);

    return (
        <>
            {ready &&
                <Router>
                    <Switch>
                        <Dashboard>
                            <Route exact path="/cuenta/nuevo-examen">
                                <UserRouteAccessProvider account={account}>
                                    <RequestExam
                                        user={user.persona}
                                        tipoOperativo={agendamientoDefault}
                                        backButton={'false'}
                                    />
                                </UserRouteAccessProvider>
                            </Route>
                            <Route exact path="/cuenta">
                                <UserRouteAccessProvider account={account}>
                                    <Examenes user={user.persona.idpersona} />
                                </UserRouteAccessProvider>
                            </Route>
                        </Dashboard>
                    </Switch>
                </Router>
            }
        </>
    )
}

export default CuentaRouting;

const UserRouteAccessProvider = ({account, children}) => {
    let location = useLocation();
    let history = useHistory();

    const [pacienteAccess, setPacienteAccess] = useState([
        { path:'/cuenta', access:true},
        { path:'/cuenta/nuevo-examen', access:true},
    ]);

    useEffect(() => {
        if(account.rol !== "paciente"){
            history.push('/admin/home');
        } else {
            let item = pacienteAccess.find(item => item.path === location.pathname);
            console.log("item", item);
            if(!item || !item.access){
                history.push('/cuenta');
            }
        }
    });

    return(
        <>{children}</>
    );
};