import React, { useState, useEffect } from "react";
import {
  Divider,
  Grid,
  Paper
} from "@material-ui/core";
import {
  AttachMoney,
  CancelPresentation,
  HourglassEmpty,
  LocalShipping
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import './asignacion.css';

//Servicios
import AsignacionServices from "../../../services/asignacion";
import Loading from "../../../components/Loading/Loading";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import CardAsignacion from "./CardAsignacion";
import EstadoService from "../../../services/estado";
import MedioPagoService from "../../../services/medioPago";

moment.locale("es");

const MiAsignacion = ({user}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fechaAsignacion, setFechaAsignacion] = useState();
  const [estados, setEstados ] = useState([]);
  const [medioPago, setMedioPago] = useState([]);

  const [dataError, setDataError] = useState({
    personaAsignada: false,
    fechaAsignacion: false,
    examenesSeleccionados: false,
  });

  const [examenesAsignados, setExamenesAsignados] = useState([]);
  const [isLoadingAsignados, setIsLoadingAsignados] = useState(false);
  const [resumenCifras, setResumenCifras ] = useState({
    pendientes: 0,
    encurso: 0,
    cancelados: 0,
    pagados: 0
  })
  

  useEffect(() => {
    fetchExamenes();
    fetchEstados();
    fetchMedioPago();
  }, []);

  useEffect(() => {
    let examCancelados = 0;
    let examPendientes = 0;
    let examEnCurso = 0;
    let examPagados = 0;

    if(examenesAsignados){
      examenesAsignados.forEach(examen => {
        if(examen.estado === "Pendiente"){ examPendientes++ }
        if(examen.estado === "En ruta"){ examEnCurso++ }
        if(examen.estado === "Pagado"){ examPagados++ }
        if(examen.estado === "Cancelado"){ examCancelados++ }
      });
  
      setResumenCifras({
        pendientes: examPendientes,
        encurso: examEnCurso,
        cancelados: examCancelados,
        pagados: examPagados
      })
    }
  }, [examenesAsignados]);

  const fetchEstados = async () => {
    const response = await EstadoService.getEstados();
    if(response && response.data){
      setEstados(response.data);
    }
  }

  const fetchMedioPago = async () => {
    const response = await MedioPagoService.getMedioPagos();
    if(response && response.data){
      setMedioPago(response.data);
    }
  }

  const fetchExamenes = async () => {
    setIsLoadingAsignados(true);

    let data = {
      idusuario: user,
      fechaAsignacion: moment(fechaAsignacion).format("DD/MM/YYYY"),
    };

    const response = await AsignacionServices.examenesAsignados(data);
    if (response && response.data) {
      setExamenesAsignados(response.data);
    }

    setIsLoadingAsignados(false);
  };

  useEffect(() => {
    fetchExamenes();
  }, [fechaAsignacion]);

  const handleReloadData = () => {
    fetchExamenes();
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid container spacing={2}>
          
          <Grid item md={7} xs={12}>
            <Paper style={{ padding: "0px" }}>
              <Grid container spacing={0}>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    libInstance={moment}
                  >
                    <DatePicker
                      autoOk
                      orientation="landscape"
                      variant="static"
                      openTo="date"
                      value={fechaAsignacion}
                      onChange={setFechaAsignacion}
                      required
                      fullWidth
                      label="Fecha de Asignación"
                      name="fechaAsignacion"
                      inputVariant="outlined"
                      format="DD/MM/yyyy"
                      views={["year", "month", "date"]}
                      error={dataError.fechaNacimiento}
                      helperText={
                        dataError.fechaNacimiento ? "Fecha inválida" : ""
                      }
                    />
                  </MuiPickersUtilsProvider>
               
              </Grid>
            </Paper>
          </Grid>

          <Grid item md={5} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={6}>
                <Paper style={{ padding: "10px" }}>
                  
                  <Grid className="card-asignacion" container spacing={2} alignContent="center" alignItems="center">
                    <Grid item>
                      <div className="circle-icon color-pendiente">
                        <HourglassEmpty />
                      </div>
                    </Grid>
                    <Grid item>
                      <small>Exámenes Pendientes</small>
                      <h2>{resumenCifras.pendientes}</h2>
                    </Grid>
                  </Grid>
                  
                </Paper>
              </Grid>
              <Grid item md={12} xs={6}>
                <Paper style={{ padding: "10px" }}>

                  <Grid className="card-asignacion" container spacing={2} alignContent="center" alignItems="center">
                    <Grid item>
                      <div className="circle-icon color-encurso">
                        <LocalShipping />
                      </div>
                    </Grid>
                    <Grid item>
                      <small>Exámenes En curso</small>
                      <h2>{resumenCifras.encurso}</h2>
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>
              <Grid item md={12} xs={6}>
                <Paper style={{ padding: "10px" }}>

                  <Grid className="card-asignacion" container spacing={2} alignContent="center" alignItems="center">
                    <Grid item>
                      <div className="circle-icon color-pagados">
                        <AttachMoney />
                      </div>
                    </Grid>
                    <Grid item>
                      <small>Exámenes Pagados</small>
                      <h2>{resumenCifras.pagados}</h2>
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>
              <Grid item md={12} xs={6}>
                <Paper style={{ padding: "10px" }}>

                  <Grid className="card-asignacion" container spacing={2} alignContent="center" alignItems="center">
                    <Grid item>
                      <div className="circle-icon color-cancelados">
                        <CancelPresentation />
                      </div>
                    </Grid>
                    <Grid item>
                      <small>Exámenes Cancelados</small>
                      <h2>{resumenCifras.cancelados}</h2>
                    </Grid>
                  </Grid>

                </Paper>
              </Grid>

            </Grid>
          </Grid>

          <Grid item md={12} xs={12} style={{textAlign:'center'}}>
            <Divider style={{marginBottom:'10px'}} />
            <small>EXAMENES ASIGNADOS</small>
          </Grid>

          <Grid item md={12} xs={12}>
            {isLoadingAsignados ? 
              <Loading />
            : 
              <>
                {examenesAsignados.map(examen =>
                    <CardAsignacion 
                      key={examen.idexamen} 
                      examen={examen} 
                      estados={estados} 
                      reloadData={handleReloadData}
                      medioPago={medioPago}
                    />
                )}
                {examenesAsignados && examenesAsignados.length === 0 ?
                  <>
                  <Paper style={{ padding: "20px", textAlign:'center' }}>
                    <p>¡Un momento! al parecer no tienes exámenes asignados en la fecha indicada</p>
                  </Paper>
                  </>
                : null }
              </>
            }
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MiAsignacion;
