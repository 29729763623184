import API from './api';
import URLS from './URLS';

const TipoExamenServices = {

   getTipoExamenes: function () {
      return API.axios({
         method: 'get',
         url: `${URLS.tipoExamen}`
      });
   },

};

export default TipoExamenServices;