import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Etiqueta.css';
import PersonaService from './../../services/persona';
import Loading from './../../components/Loading/Loading';

const PrintableEtiqueta = () => {
   const { idexamen } = useParams();
   const [isLoading, setIsLoading] = useState(true);
   const [hasError, setHasError] = useState(false);
   const [data, setData] = useState({
      nombre: 'Luis Alejandro Ponce Sánchez',
      identificador: '17120464-K',
      clave: '17120-1989',
   });

   useEffect(() => {
      buscarPrintable();
   }, []);

   const buscarPrintable = async () => {
      setIsLoading(true);
      let response = await PersonaService.printableCuenta(idexamen);
      if (response.data.success) {
         setData(response.data.data)
         setIsLoading(false);
      } else {
         setHasError(true);
         setIsLoading(false);
      }
   };

   if (isLoading && hasError) {
      return (
         <Loading />
      )
   } else {
      return (
         <>
            {isLoading && !hasError ?
               <Loading />
               : null}

            {hasError ?
               <p>No se ha podido cargar el documento</p>
               : null}

            {!isLoading && !hasError ?
               <>
                  <div className="etiquetaWrap" link="#0563C1" vlink="#954F72">
                     <div class="WordSection1">
                        <div className="tagWrapper">
                           <div className="tagInside">
                              <p class="MsoNormal">{data.nombre}<br />{data.identificador}</p>
                           </div>
                        </div>
                        <div className="tagWrapper">
                           <div className="tagInside">
                              <p class="MsoNormal"><span>RESULTADOS EN<br /><a href="https://www.cuiden.cl">www.cuiden.cl</a></span></p>
                              <p class="MsoNormal"><br />Usuario: {data.identificador} <br />Clave: {data.clave}</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </>
               : null
            }
         </>
      );
   }
}

export default PrintableEtiqueta;