import API from './api';

const PersonaService = {

    personas: function () {
        return API.axios({
            method: 'get',
            url: 'personas'
        })
    },

    buscarPersona: function (persona) {
        return API.axios({
            method: 'post',
            url: 'buscarPersona',
            params: persona
        })
    },

    deletePersona: function (idpersona) {
        return API.axios({
            method: 'delete',
            url: 'personas/delete/' + idpersona
        })
    },

    updatePersona: function (persona) {
        return API.axios({
            method: 'put',
            url: 'personas/update/' + persona.idpersona,
            data: persona
        })
    },

    printableCuenta: function (idexamen) {
        return API.axios({
            method: 'get',
            url: 'printable/etiqueta/' + idexamen
        })
    },

};
export default PersonaService;