import axios from 'axios';
import URLS from './URLS';

//import socketIOClient from "socket.io-client";
var token = null;

const API = {
    axios: axios.create({
        baseURL: URLS.baseURL,
        withCredentials: false,
    }),
};

// var temp = undefined;

var isApiLoged = false;
var logedPatient = {};
const setLogedPatient = (p) => { logedPatient = p; return p };
const getLogedPatient = () => logedPatient;
const interceptNewJWT = (axios) => {
    /* axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            if (response.headers["new-jwt"]) {
                token = response.headers["new-jwt"];
                setNewToken(token);
            }
            return response;
        },
        function (error) {
            console.log(error);
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        }
    ); */
};

const setNewToken = (newToken) => {
    /* API.axios = axios.create({
        baseURL: URLS.baseURL,
        withCredentials: true,
        headers: { Authorization: "Bearer " + newToken },
    }); */

    console.log(newToken);

    interceptNewJWT(API.axios);

    if (newToken === null) {
        isApiLoged = false;
    } else {
        isApiLoged = true;
    }
};

export { setNewToken, isApiLoged, setLogedPatient, getLogedPatient };
export default API;
