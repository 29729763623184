import './Failscreen.css';
import {
    Grid
} from '@material-ui/core';
import {
    WifiOff
} from '@material-ui/icons';

const Failscreen = ({ children }) => {

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className="failedScreen"
        >

            <Grid className="failedWrap" item xs={8}>
                <h4>Oups, tuvimos un problema :(</h4>
                <WifiOff
                    style={{
                        color: '#38c588',
                        margin: '10px',
                        fontSize: '80px'
                    }} />

                <p>No hemos podido cargar todos los componentes necesarios, puedes volver a intentar recargando la página o presionando el botón.</p>

                {children}
            </Grid>
        </Grid>
    )
};

export default Failscreen;