import React from 'react';
import {
   Button,
   Dialog,
   Divider,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Grid,
   Typography,
   IconButton
} from '@material-ui/core';



const ExamenView = ({ open, examenPop, handleClose }) => {
   return (
      <Dialog
         open={open}
         onClose={handleClose}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
      >
         <DialogTitle id="alert-dialog-title">{"Detalle exámen"}</DialogTitle>
         <DialogContent>
            <DialogContentText id="alert-dialog-description">
               <Grid container spacing={3}>
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: '16px', marginBottom: '4px' }}>
                     <Typography color="textSecondary" display="block" variant="caption" margin="dense" > Información Personal </Typography>
                     <Divider />
                  </Grid>
               </Grid>

               <Grid container spacing={3}>
                  <Grid item md={6} sm={6} xs={6}>
                     <Typography variant="caption"> RUT/DNI </Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.identificador} </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Nombre</Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.nombre} </Typography>
                  </Grid>
                  <Grid item md={4} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Fecha nacimiento</Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.fechanacimiento} </Typography>
                  </Grid>
                  <Grid item md={4} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Sexo </Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.sexo} </Typography>
                  </Grid>
                  <Grid item md={4} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Nacionalidad </Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.nacionalidad} </Typography>
                  </Grid>
               </Grid>

               <Grid container spacing={3}>
                  <Grid item md={12} sm={12} xs={12} style={{ marginTop: '16px', marginBottom: '4px' }}>
                     <Typography color="textSecondary" display="block" variant="caption" margin="dense" > Información de Contacto </Typography>
                     <Divider />
                  </Grid>
               </Grid>

               <Grid container spacing={3}>
                  <Grid item md={6} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Teléfono </Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.telefono} </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Email </Typography>
                     <Typography color="textPrimary" display="block" noWrap="false"> {examenPop.correo} </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Calle </Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.calle} </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Ciudad </Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.ciudad} </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Comuna </Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.comuna} </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                     <Typography display="block" variant="caption"> Región </Typography>
                     <Typography color="textPrimary" display="block"> {examenPop.region} </Typography>
                  </Grid>
               </Grid>

               {(examenPop.tipoexamen === "pcr" || examenPop.tipoexamen === "antigeno") ?
                  <>
                     <Grid container spacing={3}>
                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: '16px', marginBottom: '4px' }}>
                           <Typography color="textSecondary" display="block" variant="caption" margin="dense" > Información del exámen </Typography>
                           <Divider />
                        </Grid>
                     </Grid>

                     <Grid container spacing={3}>
                        <Grid item md={6} sm={6} xs={12}>
                           <Typography display="block" variant="caption"> Motivo del exámen </Typography>
                           <Typography color="textPrimary" display="block"> {examenPop.motivoExamen} </Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                           <Typography display="block" variant="caption"> Ultimo contacto </Typography>
                           <Typography color="textPrimary" display="block"> {examenPop.ultimoContacto}
                           </Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                           <Typography display="block" variant="caption"> ¿Presenta algún tipo de sintoma? </Typography>
                           <Typography color="textPrimary" display="block"> {examenPop.sintomas}
                           </Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                           <Typography display="block" variant="caption"> Fecha sintomas </Typography>
                           <Typography color="textPrimary" display="block"> {examenPop.fechaSintomas} </Typography>
                        </Grid>
                     </Grid>
                  </>
                  : null}
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleClose} color="primary">Cerrar</Button>
         </DialogActions>
      </Dialog>
   )
}

export default ExamenView;