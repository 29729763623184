const previsiones = [
    {
        value:'copago-nivel-3',
        name: 'Copago Nivel 3'
    },
    {
        value:'corthorn-quality3',
        name: 'Corthorn Quality'
    },
    {
        value:'dialisis-san-jose-chimbarongo-eirl',
        name: 'Dialisis San Jose Chimbarongo EIRL'
    },
    {
        value:'dialisis-vitta',
        name: 'Dialisis Vitta'
    },
    {
        value:'dipreca',
        name: 'Dipreca'
    },
    {
        value:'enap-refinerias-sa',
        name: 'Enap Refinerias SA'
    },
    {
        value:'fonasa',
        name: 'Fonasa'
    },
    {
        value:'fonasa-be',
        name: 'Fonasa Be'
    },
    {
        value:'fundacion',
        name: 'Fundación'
    },
    {
        value:'ingenieria-y-montajes-geyce-limitada',
        name: 'Ingenieria y Montajes Geyce Limitada'
    },
    {
        value:'instituto-de-prevision-social',
        name: 'Instituto de Previsión Social'
    },
    {
        value:'isapre-banmedica-sa',
        name: 'Isapre Banmedica SA'
    },
    {
        value:'isapre-colmena-golden-cross',
        name: 'Isapre Colmena Golden Cross'
    },
    {
        value:'isapre-consalud',
        name: 'Isapre Consalud'
    },
    {
        value:'isapre-cruz-blanca',
        name: 'Isapre Cruz Blanca'
    },
    {
        value:'isapre-mas-vida',
        name: 'Isapre Mas Vida'
    },
    {
        value:'isapre-vida-tres',
        name: 'Isapre Vida Tres'
    },
    {
        value:'nexxo-sa',
        name: 'Nexxo SA'
    },
    {
        value:'particular',
        name: 'Particular'
    },
    {
        value:'sociedad-hemodialisis-valparaiso',
        name: 'Sociedad Hemodialisis Valparaíso'
    },
    
]

export default previsiones;