import React, { forwardRef, useState, useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Button,
  Card,
  Checkbox,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  RadioGroup,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { RutFormat, formatRut, validateRut } from '@fdograph/rut-utilities';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RegionesComunas from './../../../resources/RegionesComunas';
import MaskedInput from 'react-text-mask';

//Servicios
import UsuarioService from '../../../services/usuario';
import NacionalidadServices from './../../../services/nacionalidad';
import SexoServices from './../../../services/sexo';

import Loading from './../../../components/Loading/Loading';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useHistory, useParams } from 'react-router-dom';

moment.locale("es");

const EditarUsuarios = () => {
  const history = useHistory();
  const { idusuario } = useParams();
  const [datos, setDatos] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sexo, setSexo] = useState([]);
  const [nacionalidad, setNacionalidad] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [isRut, setIsRut] = useState(false);
  const [sexoInput, setSexoInput] = useState();
  const [nacionalidadInput, setNacionalidadInput] = useState();
  const [regionInput, setRegionInput] = useState();
  const [rolInput, setRolInput] = useState();
  const [comunaInput, setComunaInput] = useState();
  const [fechaNacimientoPicker, setFechaNacimientoPicker] = useState(null);
  const [message, setMessage] = useState({
    tipo: '',
    mensaje: ''
  })
  const [user, setUser] = useState(
  {
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    fechaNacimiento: "",
    sexo: "",
    rut: "",
    pasaporte: "",
    nacionalidad: "",
    region: "",
    comuna: "",
    ciudad: "",
    calle: "",
    telefono: "",
    correo: "",
    nombreusuario: "",
    password: "",
    repassword:"",
    rol:"",
    esExtranjero:false,
    idusuario:"",
  });

  const [dataError, setDataError] = useState({
    primerNombre: false,
    segundoNombre: false,
    primerApellido: false,
    segundoApellido: false,
    fechaNacimiento: false,
    sexo: false,
    rut: false,
    pasaporte: false,
    nacionalidad: false,
    region: false,
    comuna: false,
    ciudad: false,
    calle: false,
    telefono: false,
    correo: false,
    nombreusuario:false,
    password:false,
    repassword:false,
    rol:false,
    esExtranjero:false
  });


  const fetchAll = async () => {
    try {
      setIsLoading(true);

      const nacionalidad = await NacionalidadServices.getNacionalidades();
      const sexo = await SexoServices.getSexos();
      const roles = await UsuarioService.getRoles();

      // Replace por ID del usuario
      const usuarios = await UsuarioService.getUsuarios();
      if (usuarios && usuarios.data) {
        setDatos(usuarios.data);
      }

      setNacionalidad(nacionalidad.data);
      setSexo(sexo.data);
      setRoles(roles.data);

      if(idusuario){
        console.log(idusuario);
        const response = await UsuarioService.getUsuario(idusuario);
        if(response && response.data.success){
          
          setUser(response.data.data);
          setNacionalidadInput(response.data.data.nacionalidad);
          setSexoInput(response.data.data.sexo);
          setRegionInput(response.data.data.region);
          setComunaInput(response.data.data.comuna);
          setRolInput(response.data.data.rol);
          setFechaNacimientoPicker(moment(response.data.data.fechaNacimiento, 'DD/MM/YYYY'));
          
        }else{
          setMessage({ tipo: 'error', mensaje: 'No se ha logrado cargar la información del usuario' });
          setErrorOpen(true);
        }
      }

      setIsLoading(false);

    } catch (e) {

      setMessage({ tipo: 'error', mensaje: 'Ha ocurrido un problema, intenta cargando la página nuevamente' });
      setErrorOpen(true);
      setIsLoading(false);
      
    }
  }


  useEffect(() => {
    let fecha = moment(fechaNacimientoPicker).format('DD/MM/YYYY');
    setUser(data => ({
      ...data,
      fechaNacimiento: fecha
    }));
  }, [fechaNacimientoPicker]);


  const submit = async ()  => {
    let validate = {
      primerNombre: false,
      segundoNombre: false,
      primerApellido: false,
      segundoApellido: false,
      fechaNacimiento: false,
      sexo: false,
      rut: false,
      pasaporte: false,
      nacionalidad: false,
      region: false,
      comuna: false,
      ciudad: false,
      calle: false,
      telefono: false,
      correo: false,
      nombreusuario:false,
      rol:false
    }
    validacionCampos(validate);

    if (!hasError(validate)) {
      const response = await UsuarioService.updateUsuarioCuenta(user);
      if(response.data.success){
        setMessage({ tipo: 'success', mensaje: 'Se ha actualizado la información correctamente' });
        setErrorOpen(true);
      }else{
        setMessage({ tipo: 'error', mensaje: 'Ha ocurrido un error en el guardado de datos, verifica que los campos esten seleccionados' });
        setErrorOpen(true);
      }
    } else {
      setDataError(validate);
    }
  }

  const submitPassword = async () => {
    let validate = {
      password:false,
      repassword:false,
    }
    validacionCamposPassword(validate);

    if (!hasErrorPassword(validate)) {
      const response = await UsuarioService.updatePassword(user);
      if(response.data.success){
        setMessage({ tipo: 'success', mensaje: 'Se ha actualizado la contraseña correctamente' });
        setErrorOpen(true);
      }else{
        setMessage({ tipo: 'error', mensaje: 'Ha ocurrido un error en la operación' });
        setErrorOpen(true);
      }
    } else {
      setDataError(validate);
    }
  }

  const validacionCampos = (validate) => {
    validate.primerNombre = checkState("primerNombre");
    validate.primerApellido = checkState("primerApellido");
    validate.segundoApellido = checkState("segundoApellido");
    validate.fechaNacimiento = checkFechaNacimiento();
    validate.sexo = checkState("sexo");
    validate.nacionalidad = checkState("nacionalidad");
    validate.rut = checkIdentificador();
    validate.pasaporte = checkIdentificador();
    validate.region = checkState("region");
    validate.comuna = checkState("comuna");
    validate.ciudad = checkState("ciudad");
    validate.calle = checkState("calle");
    validate.telefono = checkState("telefono");
    validate.correo = checkCorreo();
    validate.nombreusuario = checkState("nombreusuario");
    validate.rol = checkState("rol");
  }

  const validacionCamposPassword = (validate) => {
    validate.repassword = checkState("repassword");
    validate.password   = checkState("password");
    validate.password   = checkPassword();
  }


  //Todo: verificación de usuario duplicado
  //Todo: Verificar formateo de usuario
  //Todo: verificar largo minimo para contraseña

  const hasError = (validate) => {
    let errorFound = true;
    if (!validate.primerNombre
      && !validate.primerApellido
      && !validate.segundoApellido
      && !validate.fechaNacimiento
      && !validate.sexo
      && !validate.nacionalidad
      && !validate.pasaporte
      && !validate.rut
      && !validate.region
      && !validate.comuna
      && !validate.ciudad
      && !validate.calle
      && !validate.telefono
      && !validate.correo
      && !validate.nombreusuario
      && !validate.rol) {
      errorFound = false;
    }
    return errorFound;
  };

  const hasErrorPassword = (validate) => {
    let errorFound = true;
    if (!validate.password && !validate.repassword) {
      errorFound = false;
    }
    return errorFound;
  };

  const checkCorreo = () => {
    return !(/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(user.correo));
 }

  const checkPassword = () => {
    if(user.password.trim() === "" || user.repassword.trim() === ""){
      return true;
    }
    return user.password != user.repassword 
  }

  const checkIdentificador = () => {
    if (isRut) {
      
      return checkState("pasaporte");
    } else {
      return checkState("rut");
    }
  }

  const checkFechaNacimiento = () => {
    if (fechaNacimientoPicker) {
      return false;
    } else {
      return true;
    }
  }

  const checkState = (atributo) => {
    if (user[atributo]) {
      if (user[atributo].trim() === "") {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  const handleChange = (e) => {
    let data = {
      ...user,
      [e.target.name]: e.target.value
    }
    setUser(data);
    setDataError(errorState => ({
      ...errorState,
      [e.target.name]: false
    }))
  };

  const removeError = () => {
    setDataError(errorState => ({
      ...errorState,
      fechaNacimiento: false
    }))
  }

  const onBlurRut = evnt => {
    let rutLower = (user.rut + '').toLowerCase().trim();
    const isRutValid = validateRut(rutLower);
    if (isRutValid) {
      let formatRutValue = formatRut(rutLower, RutFormat.DASH);
      setUser(formState => ({
        ...formState,
        rut: formatRutValue
      }));
    } else {
      setDataError(errorState => ({
        ...errorState,
        rut: true
      }))
    }
  };

  const handleIdentificador = () => {
    setDataError(errorState => ({
      ...errorState,
      rut: false,
      pasaporte: false
    }));
    setIsRut(!isRut);

    let data = {
      ...user,
      esExtranjero: !isRut
    }
    setUser(data);
  }

  const handleRegion = (e) => {
    let data = {
       ...user,
       region: e.target.value,
       comuna: "Seleccionar..."
    }
    setUser(data);

    let value = e.target.value;
    handleComunas(value);
  };

  const handleComunas = (value) => {
    let comunasFiltradas = RegionesComunas.filter((element =>
        element.name === value
    ))

    if (comunasFiltradas && comunasFiltradas[0]) {
        setComunas(comunasFiltradas[0].communes);
    }
  };

  const errorEnCampo = (campo) => {
    setDataError(errorState => ({
      ...errorState,
      [campo]: true
    }));
  };

  useEffect(() => {
    fetchAll();
  }, []);

  /* Alert */
  const [errorOpen, setErrorOpen] = React.useState(false);
  const handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
  };

  return (
    <>
      <Paper>
        {isLoading ?
          <Loading />
          :
          <Grid container spacing={2} style={{ marginTop: '10px', padding: '20px 30px' }}>

            <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6" color="inherit">Nuevo usuario </Typography>
            </Grid>

            <Grid item md={4} sm={4} xs={6} >
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel
                    value="extranjero"
                    control={<Checkbox onClick={() => handleIdentificador()} color="primary" />}
                    label="Extranjero"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item md={4} sm={4} xs={6} >
              {isRut ?
                <TextField
                  fullWidth
                  disabled
                  label="Pasaporte / DNI"
                  name="pasaporte"
                  onChange={handleChange}
                  value={user.pasaporte}
                  variant="outlined"
                  required
                  error={dataError.pasaporte}
                  helperText={
                    dataError.pasaporte
                      ? "El pasaporte ingresado no es válido"
                      : null}
                />
                :
                <TextField
                  fullWidth
                  label="Rut"
                  disabled
                  name="rut"
                  onChange={handleChange}
                  onBlur={onBlurRut}
                  value={user.rut}
                  variant="outlined"
                  required
                  error={dataError.rut}
                  helperText={
                    dataError.rut
                      ? "El rut ingresado no es válido"
                      : "Ejemplo: 11222333-K"}
                />
              }
            </Grid>
            
            <Grid item md={4} sm={4} xs={6} >
              <Autocomplete
                defaultValue={nacionalidad.find(nac => nac.nombre === nacionalidadInput)}
                options={nacionalidad}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    error={dataError.nacionalidad}
                    helperText={
                      dataError.nacionalidad
                        ? "Debes seleccionar una opción"
                        : null}
                    name="nacionalidad"
                    onBlur={handleChange}
                    label="Nacionalidad"
                    variant="outlined" />
                }
              />
            </Grid>

            <Grid item md={3} sm={6} xs={6} >
              <TextField
                fullWidth
                label="Primer Nombre"
                name="primerNombre"
                onChange={handleChange}
                value={user.primerNombre}
                required
                variant="outlined"
                error={dataError.primerNombre}
                helperText={
                  dataError.primerNombre
                    ? "Debes ingresar un nombre"
                    : null
                }
              />
            </Grid>
            
            <Grid item md={3} sm={6} xs={6} >
              <TextField
                fullWidth
                label="Segundo Nombre"
                name="segundoNombre"
                onChange={handleChange}
                value={user.segundoNombre}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={3} sm={6} xs={6} >
              <TextField
                fullWidth
                label="Apellido Paterno"
                name="primerApellido"
                onChange={handleChange}
                value={user.primerApellido}
                variant="outlined"
                required
                error={dataError.primerApellido}
                helperText={
                  dataError.primerApellido
                    ? "Debes ingresar un apellido"
                    : null
                }
              />
            </Grid>
            
            <Grid item md={3} sm={6} xs={6} >
              <TextField
                fullWidth
                label="Apellido Materno"
                name="segundoApellido"
                onChange={handleChange}
                value={user.segundoApellido}
                variant="outlined"
                required
                error={dataError.segundoApellido}
                helperText={
                  dataError.segundoApellido
                    ? "Debes ingresar un apellido"
                    : null
                }
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12} >
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                libInstance={moment}
              >
                <DatePicker
                  required
                  fullWidth
                  disableFuture
                  label="Fecha de Nacimiento"
                  value={fechaNacimientoPicker}
                  onBlur={removeError}
                  name="fechaNacimiento"
                  inputVariant="outlined"
                  format="DD/MM/yyyy"
                  openTo="year"
                  views={["year", "month", "date"]}
                  onChange={setFechaNacimientoPicker}
                  error={dataError.fechaNacimiento}
                  helperText={
                    dataError.fechaNacimiento
                      ? "Fecha inválida"
                      : "DD/MM/AAAA"
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item md={3} sm={6} xs={12} >
              <Autocomplete
                defaultValue={sexo.find(sex => sex.nombre === sexoInput)}
                options={sexo}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    name="sexo"
                    onBlur={handleChange}
                    label="Sexo"
                    error={dataError.sexo}
                    helperText={
                      dataError.sexo
                        ? "Debes seleccionar una opción"
                        : null}
                    variant="outlined" />
                } />
            </Grid>

            <Grid item md={12} xs={12} >
              <Divider />
            </Grid>

            <Grid item md={12} xs={12} >
            <Typography component="h1" variant="h6" color="inherit">Información de contacto </Typography>
            </Grid>

            <Grid item md={4} sm={6} xs={12} >
              <Autocomplete
                  options={RegionesComunas}
                  getOptionLabel={(option) => option.name}
                  defaultValue={RegionesComunas.find(region => region.name === regionInput)}
                  renderInput={(params) =>
                    <TextField
                        {...params}
                        name="region"
                        required
                        onBlur={handleRegion}
                        onChange={handleRegion}
                        label="Región"
                        variant="outlined"
                        error={dataError.region}
                        helperText={
                          dataError.region
                              ? "Debes seleccionar una región"
                              : null
                        }
                    />
                  }
              />
            </Grid>
            
            <Grid item md={4} sm={6} xs={12} >
              <Autocomplete
                  defaultValue={comunaInput}
                  options={comunas}
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty('name')) {
                        return option.name;
                    }
                    return option;
                  }}
                  renderInput={(params) =>
                    <TextField
                        {...params}
                        name="comuna"
                        required
                        onBlur={handleChange}
                        label="Comuna"
                        variant="outlined"
                        error={dataError.comuna}
                        helperText={
                          dataError.comuna
                              ? "Debes seleccionar una comuna"
                              : null
                        }
                    />
                  }
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12} >
              <TextField
                  fullWidth
                  label="Ciudad"
                  name="ciudad"
                  onChange={handleChange}
                  value={user.ciudad}
                  required
                  variant="outlined"
                  error={dataError.ciudad}
                  helperText={
                    dataError.ciudad
                        ? "Debes ingresar una ciudad"
                        : null
                  }
              />
            </Grid>
            
            <Grid item md={4} sm={6} xs={12} >
              <TextField
                  fullWidth
                  label="Dirección"
                  name="calle"
                  onChange={handleChange}
                  value={user.calle}
                  variant="outlined"
                  error={dataError.calle}
                  helperText={
                    dataError.calle
                        ? "Debes ingresar tu dirección"
                        : "Calle, #000"
                  }
              />
            </Grid>
            
            <Grid item md={4} sm={6} xs={12} >
              <TextField
                required
                fullWidth
                value={user.telefono}
                onChange={handleChange}
                label="Teléfono"
                name="telefono"
                type="number"
                pattern="[0-9]*" 
                inputMode="numeric" 
                variant="outlined"
                defaultValue={user.telefono}
                inputComponent={TextMaskCustom}
                error={dataError.telefono}
                  helperText={
                      dataError.telefono
                        ? "Debes ingresar un teléfono"
                        : null
                  }
            />  
            </Grid>
            
            <Grid item md={4} sm={6} xs={12} >
              <TextField
                  fullWidth
                  label="Correo"
                  name="correo"
                  onChange={handleChange}
                  value={user.correo}
                  variant="outlined"
                  required
                  type="email"
                  error={dataError.correo}
                  helperText={
                    dataError.correo
                        ? "Debes ingresar un email"
                        : null
                  }
              />
            </Grid>

            <Grid item md={12} xs={12} >
              <Divider />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6" color="inherit">Información de la cuenta</Typography>
            </Grid>
            
            <Grid item md={6} xs={3}>
              <TextField
                fullWidth
                disabled
                label="Nombre de usuario"
                name="nombreusuario"
                onChange={handleChange}
                value={user.nombreusuario}
                variant="outlined"
                required
                error={dataError.nombreusuario}
                helperText={
                  dataError.nombreusuario
                    ? "El nombre de usuario ingresado no es válido"
                    : 'El nombre de usuario no puede ser el rut'}
              />
            </Grid>
            <Grid item md={6} xs={3}>
              <Autocomplete
                defaultValue={roles && roles.find(nac => nac.nombre === rolInput)}
                options={roles}
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    error={dataError.rol}
                    helperText={
                      dataError.rol
                        ? "Debes seleccionar una opción"
                        : null}
                    name="rol"
                    onBlur={handleChange}
                    label="Rol"
                    variant="outlined" />
                }
              />
            </Grid>

            <Grid item md={12} xs={12} >
              <Divider />
            </Grid>
            <Grid item md={6} xs={12} >
              <Button
                color="primary"
                variant="contained"
                onClick={submit}
              >
                Guardar Cambios
              </Button>

              <Button style={{marginLeft:'20px'}} onClick={() => history.push('/admin/usuarios')} >
                Volver
              </Button>
            </Grid>

          </Grid>
        }

      </Paper>

      <Paper style={{marginTop:'30px'}}>
        <Grid container spacing={2} style={{ marginTop: '10px', padding: '20px 30px' }}>
          <Grid item xs={12} md={12}>
            <Typography component="h1" variant="h6" color="inherit">Cambiar contraseña </Typography>
          </Grid>

          <Grid item md={6} xs={3}>
            <TextField
              fullWidth
              label="Contraseña"
              name="password"
              onChange={handleChange}
              value={user.password}
              variant="outlined"
              required
              type="password"
              error={dataError.password}
              helperText={
                dataError.password
                  ? "La contraseña ingresada no es válida o no coincide"
                  : null}
            />
          </Grid>

          <Grid item md={6} xs={3}>
            <TextField
              fullWidth
              label="Repetir Contraseña"
              name="repassword"
              onChange={handleChange}
              value={user.repassword}
              variant="outlined"
              type="password"
              required
              error={dataError.repassword}
              helperText={
                dataError.repassword
                  ? "La contraseña ingresada no es válida o no coincide"
                  : null}
            />
          </Grid>

          <Grid item md={12} xs={12} >
            <Divider />
          </Grid>
            
          <Grid item md={6} xs={12} >
            <Button
              color="primary"
              variant="contained"
              onClick={submitPassword}
            >
              Guardar Cambios
            </Button>

            <Button style={{marginLeft:'20px'}} onClick={() => history.push('/admin/usuarios')} >
              Volver
            </Button>
          </Grid>

        </Grid>
      </Paper>

      <Snackbar open={errorOpen} autoHideDuration={20000} onClose={handleError}>
        <MuiAlert elevation={6} variant="filled" onClose={handleError} severity={message.tipo}>
            {message.mensaje}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask="(+569) 999 999 99"
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export default EditarUsuarios;