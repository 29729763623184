import React, { forwardRef, useState, useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import {
    IconButton,
    Snackbar
} from '@material-ui/core';
import {
    AddBox,
    ArrowDownward,
    Assignment,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    FirstPage,
    FilterList,
    Edit,
    LastPage,
    MonetizationOn,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import ExamenServices from '../../../services/examen';
import Loading from './../../../components/Loading/Loading';
import ExamenView from './../../../components/ExamenView/ExamenView';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const base_path = process.env.REACT_APP_PATH || 'http://127.0.0.1:8000/';

const Examenes = ({ user }) => {
    const [datos, setDatos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [examenPop, setExamenPop] = useState({
        identificador: '',
        nombre: '',
        fechanacimiento: '',
        sexo: '',
        nacionalidad: '',
        telefono: '',
        correo: '',
        calle: '',
        ciudad: '',
        comuna: '',
        region: '',
    });
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const loadExamen = async (id) => {
        ExamenServices.detalleExamen(id)
            .then(res => {
                setExamenPop(res.data.data);
                setOpen(true);
            })
            .catch(error => {
                setOpen(false);
                setErrorOpen(true);
            })
    };

    useEffect(() => {
        setIsLoading(true);
        ExamenServices.buscarExamenesUsuario(user)
            .then(res => {
                setDatos(res.data);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setErrorOpen(true);
            });
    }, []);

    /* Alert */
    const [errorOpen, setErrorOpen] = React.useState(false);
    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen(false);
    };


    if (isLoading) {
        return <Loading />
    } else {
        return (
            <>
                {isLoading ?
                    <Loading />
                    :
                    <MaterialTable
                        icons={tableIcons}
                        title="Exámenes"
                        columns={[
                            {
                                title: 'ID', field: 'idexamen',
                                render: rowData =>
                                    <>
                                        <span>{rowData.idexamen}</span>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="open drawer"
                                            onClick={() => loadExamen(rowData.idexamen)}
                                        >
                                            <Search />
                                        </IconButton>
                                    </>
                            },
                            { title: 'Examen', field: 'tipoexamen' },
                            { title: 'Fecha agendamiento', field: 'fechaagendamiento', type: 'date', dateSetting: { locale: 'es-ES' } },
                            { title: 'RUT / Pasaporte', field: 'persona.identificador' },
                            { title: 'Nombres', field: 'persona.nombres' },
                        ]}
                        localization={{
                            header: {
                                actions: 'Documentos'
                            },
                            body: {
                                emptyDataSourceMessage: 'No existen datos',
                            },
                            pagination: {
                                labelRowsSelect: 'Filas por página',
                                labelDisplayedRows: '{from}-{to} de {count}',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Página anterior',
                                nextTooltip: 'Próxima página',
                                lastTooltip: 'Última página'
                            }
                        }}
                        data={datos}
                        options={{
                            filtering: true,
                            actionsColumnIndex: 1,
                            pageSize: 10,
                        }}
                        actions={[
                            rowData => (
                                {
                                    icon: () => (
                                        <BotonDescarga url={rowData.informe}>
                                            <Assignment />
                                        </BotonDescarga>
                                    ),
                                    tooltip: 'Descargar informe',
                                    hidden: rowData.informe.length === 0
                                }
                            ),
                            rowData => (
                                {
                                    icon: () => (
                                        <BotonDescarga rowData={rowData} url={rowData.boleta}>
                                            <MonetizationOn />
                                        </BotonDescarga>
                                    ),
                                    tooltip: 'Descargar boleta',
                                    hidden: rowData.boleta.length === 0
                                }
                            )

                        ]}
                    />
                }

                <ExamenView
                    examenPop={examenPop}
                    open={open}
                    handleClose={handleClose}
                />

                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleError}>
                    <MuiAlert elevation={6} variant="filled" onClose={handleError} severity="error">
                        Ha ocurrido un error en la operación, recarga la página y vuelve a intentarlo
                    </MuiAlert>
                </Snackbar>
            </>
        );
    }
}

export default Examenes;

const BotonDescarga = ({ url, children }) => {
    const createURL = (url) => {
        if (url.startsWith("https") || url.startsWith("http")) {
            return url;
        } else {
            return base_path + url
        }
    }


    return (
        <a href={createURL(url.path)} target="_blank" rel="noreferrer">
            {children}
        </a>
    )
}