import React from 'react';
import CargaComponente from './CargaComponente';

import {
   Grid
} from '@material-ui/core';

const CargaMasiva = () => {
   return (
      <Grid container spacing={4}>
         <Grid item xs={6}>
            <CargaComponente
               tipoCarga="informe"
            />
         </Grid>
         <Grid item xs={6}>
            <CargaComponente
               tipoCarga="boleta"
            />
         </Grid>
      </Grid>
   )
}
export default CargaMasiva;
