import React from 'react';
import './SucessMessage.css';
import {
    Button,
    Grid
} from '@material-ui/core';
import {
    CheckCircle,
    Print
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

const SucessMessage = ({ children, backButton, isPrintable, examenPCR, isOperacion }) => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className="sucessMessage"
        >

            <Grid className="sucessMessageWrap" item xs={8}>
                <h4>¡Todo listo!</h4>
                <div>
                    <CheckCircle
                        style={{
                            color: '#38c588',
                            margin: '10px',
                            fontSize: '80px'
                        }} />
                </div>
                <div>
                    {isPrintable ?
                        (
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid item md={6} xs={6}>
                                    <a href={'/printable/consentimiento/' + examenPCR.idexamen} target="_blank" rel="noreferrer">
                                        <Button
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <Print style={{ marginRight: '20px' }} /> Consentimiento
                                        </Button>
                                    </a>
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <a href={'/printable/etiqueta/' + examenPCR.idexamen} target="_blank" rel="noreferrer">
                                        <Button
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <Print style={{ marginRight: '20px' }} /> Etiqueta
                                        </Button>
                                    </a>
                                </Grid>
                            </Grid>
                        )
                        : <p>Dentro de poco serás contactado para confirmar tus datos y cita.</p>
                    }
                </div>
            </Grid>
            {backButton === "true" &&
                <Link style={{ marginTop: '20px' }} to="/">Volver al inicio</Link>
            }
        </Grid>
    )
};

export default SucessMessage;