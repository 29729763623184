import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import {
  Assignment,
  AssignmentTurnedIn,
  Build,
  Dashboard,
  DateRange,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Layers,
  LibraryAdd,
  FormatListBulleted,
  Publish,
  People,
  PeopleAlt,
  PlaylistAddCheck,
  PostAdd,
  Settings,
  Star,
  Storage,
} from "@material-ui/icons";

const NavegacionDashboard = ({account}) => {
  const history = useHistory();
 

  if(account.rol === "admin"){
    return <AdminMenu />
  } else if (account.rol === "operador"){
    return <OperadorMenu />
  } else {
    history.push("/cuenta");
    return(
      <></>
    );
  }
};

export default NavegacionDashboard;


const AdminMenu = () => {
  let history = useHistory();
  const [openBaseDatos, setOpenBaseDatos] = useState(false);
  const [openAsignacion, setOpenAsignacion] = useState(false);
  const [openConfig, setopenConfig] = useState(false);

  return(
    <div>
      <ListItem button onClick={() => history.push("/admin/home")}>
        <Tooltip title="Exámenes">
          <ListItemIcon> 
            <Assignment />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Exámenes" />
      </ListItem>
      <ListItem button onClick={() => history.push("/admin/agendados")}>
        <Tooltip title="Exámenes Agendados">
          <ListItemIcon>
            <DateRange />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="E. Agendados" />
      </ListItem>
      <ListItem button onClick={() => history.push("/admin/nuevo-examen")}>
        <Tooltip title="Ingreso Paciente">
          <ListItemIcon>
            <LibraryAdd />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Ingreso Paciente" />
      </ListItem>

      <ListItem button onClick={() => history.push("/admin/carga-masiva")}>
        <Tooltip title="Carga Masiva">
          <ListItemIcon>
            <Publish />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Carga Masiva" />
      </ListItem>

      <ListItem button onClick={() => history.push("/admin/operativos")}>
        <Tooltip title="Operativos">
          <ListItemIcon>
            <Layers />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Operativos" />
      </ListItem>

      <ListItem button onClick={() => history.push("/admin/in-situ")}>
        <Tooltip title="Operativo in situ">
          <ListItemIcon>
            <Star />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Operativo in situ" />
      </ListItem>

      <ListItem button onClick={() => setOpenAsignacion(!openAsignacion)}>
        <ListItemIcon>
          <AssignmentTurnedIn />
        </ListItemIcon>
        <ListItemText primary="Asignación" />
        {openAsignacion ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openAsignacion} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ paddingLeft: "5px", backgroundColor: "#f2f2f2" }}
        >
          <ListItem button onClick={() => history.push("/admin/asignacion")}>
            <Tooltip title="Panel Control">
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Panel Control" />
          </ListItem>
          <ListItem
            button
            onClick={() => history.push("/admin/asignacion/nuevo")}
          >
            <Tooltip title="Asginar exámenes">
              <ListItemIcon>
                <PostAdd />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Asginar exámenes" />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={() => setOpenBaseDatos(!openBaseDatos)}>
        <ListItemIcon>
          <Storage />
        </ListItemIcon>
        <ListItemText primary="Base de datos" />
        {openBaseDatos ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openBaseDatos} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ paddingLeft: "5px", backgroundColor: "#f2f2f2" }}
        >
          <ListItem button onClick={() => history.push("/admin/personas")}>
            <Tooltip title="Personas">
              <ListItemIcon>
                <People />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Personas" />
          </ListItem>
          <ListItem button onClick={() => history.push("/admin/historico")}>
            <Tooltip title="Busca examenes">
              <ListItemIcon>
                <FormatListBulleted />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="E. Históricos" />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={() => setopenConfig(!openConfig)}>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Configuración" />
        {openConfig ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openConfig} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ paddingLeft: "5px", backgroundColor: "#f2f2f2" }}
        >
          <ListItem button onClick={() => history.push("/admin/configuracion")}>
            <Tooltip title="Parámetros del sistema">
              <ListItemIcon>
                <Build fontSize="small" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Parámetros del sistema" />
          </ListItem>
          <ListItem button onClick={() => history.push("/admin/usuarios")}>
            <Tooltip title="Usuarios del sistema">
              <ListItemIcon>
                <PeopleAlt fontSize="small" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Usuarios de sistema" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button>
        <Tooltip title="Cerrar Sesión">
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Cerrar Sesión" />
      </ListItem>
    </div>
  )
};

const OperadorMenu = () => {
  let history = useHistory();

  return(
    <div>

      <ListItem button onClick={() => history.push('/admin/mi-asignacion')}>
        <ListItemIcon>
            <PlaylistAddCheck />
        </ListItemIcon>
        <ListItemText primary="Asignación" />
      </ListItem>
      
      <ListItem button onClick={() => history.push("/admin/nuevo-examen")}>
        <Tooltip title="Ingreso Paciente">
          <ListItemIcon>
            <LibraryAdd />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Ingreso Paciente" />
      </ListItem>

      <ListItem button>
        <Tooltip title="Cerrar Sesión">
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Cerrar Sesión" />
      </ListItem>
    </div>
  )
}