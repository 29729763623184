import API from './api';
import URLS from './URLS';

const ExamenServices = {

    newExamen: function (dataExamen) {
        return API.axios({
            method: 'post',
            url: `${URLS.examen}`,
            data: dataExamen
        });
    },

    buscarExamenes: function () {
        return API.axios({
            method: 'get',
            url: 'examenes'
        })
    },

    buscarExamenesUsuario: function (idusuario) {
        return API.axios({
            method: 'get',
            url: 'obtenerExamenes/' + idusuario
        })
    },

    buscarExamenesAgendados: function () {
        return API.axios({
            method: 'get',
            url: 'obtenerAgendados'
        })
    },

    buscarExamenesNoAsignados: function (){
        return API.axios({
            method: 'get',
            url: 'examenes/sinAsignar'
        })
    },

    detalleExamen: function (idexamen) {
        return API.axios({
            method: 'get',
            url: 'detalleExamen/' + idexamen
        })
    },

    eliminarExamen: function (idexamen) {
        return API.axios({
            method: 'post',
            url: 'eliminarExamen/' + idexamen
        })
    },

    printableConsentimiento: function (idexamen){
        return API.axios({
            method:'get',
            url: 'printable/'+ idexamen
        })
    },


    getExamenesHistoricos: function(queryData){
        return API.axios({
            method: 'post',
            url: 'examenesHistoricos',
            data: queryData
    })},

    updateExamen: function (data){
        return API.axios({
            method:'put',
            url: URLS.examen + '/' + data.idexamen,
            data: data
        })
    }


};
export default ExamenServices;