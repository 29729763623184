import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { Page, PDFViewer, Text, View, Image, Document, StyleSheet, Font, PDFDownloadLink } from '@react-pdf/renderer';
import LogoCuiden from './../../resources/images/logoCuiden.png';
import firma from './../../resources/images/firma-template.png';
import Loading from '../../components/Loading/Loading';
import RobotoBold from './../../resources/font/Roboto-Bold.ttf';
import RobotoBoldItalic from './../../resources/font/Roboto-BoldItalic.ttf';
import RobotoMedium from './../../resources/font/Roboto-Medium.ttf';
import RobotoMediumItalic from './../../resources/font/Roboto-MediumItalic.ttf';
import ArchivoServices from '../../services/archivo';
import { Grid } from '@material-ui/core';
import { Block, CloudDownload } from '@material-ui/icons';
import moment from 'moment';

//https://react-pdf.org/
Font.register({
   family: 'Roboto',
   fonts: [
      {
         src: RobotoMedium
      },
      {
         src: RobotoBold,
         fontWeight: 'bold'
      },
      {
         src: RobotoMediumItalic,
         fontWeight: 'normal',
         fontStyle: 'italic'
      },
      {
         src: RobotoBoldItalic,
         fontWeight: 'bold',
         fontStyle: 'italic'
      }
   ]
})

const styles = StyleSheet.create({
   body: {
      paddingTop: 20,
      paddingBottom: 30,
      paddingHorizontal: 60,
   },
   subtitle: {
      fontSize: 14,
      marginTop: 20,
      marginBottom: 20
   },
   text: {
      margin: 2,
      marginBottom: 4,
      fontSize: 10,
      textAlign: 'justify',
      fontFamily: 'Roboto',
      display:'block'
   },
   small:{
      margin:2,
      fontSize:9,
      fontFamily:'Roboto',
      fontWeight:'normal',
      color: 'grey',
   },
   strong:{
      fontWeight:'bold',
      color:'black'
   },
   textSign: {
      margin: 2,
      marginBottom: 6,
      fontSize: 12,
      textAlign: 'center',
      fontFamily: 'Roboto',
   },
   textSignTwo: {
      margin: 2,
      marginBottom: 0,
      fontSize: 11,
      color:'black',
      textAlign: 'center',
      fontFamily: 'Roboto',
   },
   span: {
      color: 'grey'
   },
   image: {
      maxWidth: 130,
      marginBottom: 20,
      marginTop: 20
   },
   qrcode:{
      width:100,
      height:100
   },
   imageSign: {
      maxWidth: 200,
      margin: 'auto',
      marginBottom: 0,
      marginTop: 60
   },
   header: {
      fontSize: 11,
      marginBottom: 40,
      textAlign: 'center',
      color: 'black',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      textDecoration:'underline'
   },
   pdfviewer: {
      width: '100%',
      height: '100vh',
      overflow: 'hidden'
   },
   row: {
      display:'flex', 
      flexDirection: "row", 
      flexWrap: "wrap"
   }
});

const ResultadoTemplatePDF = () => {
   const { data } = useParams();
   const [isLoading, setIsLoading] = useState(true);
   const [qrCode, setQrcode ] = useState("");
   const [ error, setError ] = useState(false);
   const [fileData , setFileData] = useState({
      direccion:"",
      fechaAtencion:"",
      identificador:"",
      nombre:"",
      fechaNacimiento:"",
      celular:"",
      nombreTest:"",
      observaciones:"",
      resultado:"",
      tipoMuestra:"",
      tomaMuestra:"",
      edad:""
   });

   useEffect(() => {
      fetchAll();
   },[]);

   const fetchAll = async () => {
      setIsLoading(true);
      const response = await ArchivoServices.verInforme(data);
      if(response && response.data && response.data.success){
         let jsonToData = JSON.parse(response.data.data.jsondata);
         let fechaNacimiento = moment(jsonToData.fechaNacimiento, 'DD/MM/YYYY');
         jsonToData["edad"] = (moment().diff(fechaNacimiento, 'years'))
         setFileData(jsonToData);
         if(response.data.base64){
            setQrcode("data:image/png;base64, "+response.data.base64);
         }
      }else{
         setError(true);
      }
      setIsLoading(false);
   };

   if(isLoading){
      return(
         <Loading />
      )
   } else {
      return (
         <>
            {error ?
               
               <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  className="failedScreen"
               >
                  <Grid className="failedWrap" item xs={8}>
                     <h4>Exámen no encontrado(</h4>
                     <Block
                        style={{
                              color: '#38c588',
                              margin: '10px',
                              fontSize: '80px'
                        }} />
                     <p>No hemos podido encontrar el exámen que estás buscando, ¿Estás seguro de haber presionado o accedido a la dirección correcta?</p>
                  </Grid>
               </Grid>
            
            :
               <>
                  <BrowserView>
                     <PDFViewer style={styles.pdfviewer}>
                        <PDFRendered fileData={fileData} qrCode={qrCode} />
                     </PDFViewer>
                  </BrowserView>
                  <MobileView>
                     <PDFDownloadLink 
                     style={{position:'absolute',top:0,left:0,width:'100%',height:'100%',zIndex:10}}
                     document={<PDFRendered fileData={fileData} qrCode={qrCode} />} fileName={"examen-"+fileData.identificador+".pdf"}>
                        <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                        className="failedScreen"
                        >
                           <Grid className="failedWrap" style={{backgroundColor:'#38c588',color:'white',padding:'20px'}} item xs={8}>
                              <CloudDownload
                                 style={{
                                       color: 'white',
                                       margin: '10px',
                                       fontSize: '40px'
                                 }} />
                              <p style={{color:'white',fontSize:'20px',textDecoration:'none',fontWeight:'bold'}}>Descargar resultado</p>
                           </Grid>
                        </Grid>
                     </PDFDownloadLink>
                  </MobileView>
               </>
         }
         </>
      )
   }
};

export default ResultadoTemplatePDF;

const PDFRendered = ({fileData, qrCode}) => {
   return(
     
      <Document>
            {/* <PDFViewer style={styles.pdfviewer}> */}
            <Page size="LETTER" style={styles.body} wrap>

               <View style={{
                  width:'100%',
                  position:'absolute',
                  zIndex:0,
                  bottom:'30%',
                  margin:'auto',
                  textAlign:'center',
                  right:'10%'
               }}>
                  <Image
                     style={{
                        opacity:'0.1',
                        width:'90%',
                        margin:'auto'
                     }}
                     src={LogoCuiden}
                  />
               </View>

               <View style={{
                  position:'relative',
                  zIndex:1
               }}>
                  <Image style={styles.image} src={LogoCuiden} />
                  <Text style={styles.header}>INFORME DE RESULTADO TEST RÁPIDO DETECCIÓN ANTÍGENO SARS-CoV-2</Text>
                  
                  <View style={styles.row}>
                     <View style={{display:'flex', width:'50%'}}>
                        <Text style={styles.text}><span style={styles.span}>Nombre:  </span>{fileData.nombre.toUpperCase()}</Text>
                        <Text style={styles.text}><span style={styles.span}>RUT / DNI: </span>{fileData.identificador.toUpperCase()}</Text>
                     </View>
                     <View style={{display:'flex'}}>
                        <Text style={styles.text}><span style={styles.span}>Fecha de atención:  </span>{fileData.fechaAtencion}</Text>
                        <Text style={styles.text}><span style={styles.span}>Toma de muestra:  </span>{fileData.tomaMuestra}</Text>
                        
                     </View>
                  </View>

                  <View style={styles.row}>
                    <View style={{display:'flex', width:'50%'}}>
                      <Text style={styles.text}><span style={styles.span}>Fecha Nacimiento:  </span>{fileData.fechaNacimiento.toUpperCase()}</Text>
                      <Text style={styles.text}><span style={styles.span}>Edad: </span>{fileData.edad} años</Text>
                    </View>
                    <View>
                      <Text style={styles.text}><span style={styles.span}>Celular:  </span>{fileData.celular.toUpperCase()}</Text>
                    </View>
                  </View>

                  <View style={styles.row}>
                      <Text style={styles.text}><span style={styles.span}>Dirección:  </span>{fileData.direccion.toUpperCase()}</Text>
                  </View>

                  <View style={{marginTop:'20px',marginBottom:'10px'}}>
                     <Text style={styles.text}><span style={styles.span}>Resultado Test Rápido Detección Antígeno:  </span>{fileData.resultado.toUpperCase()}</Text>
                  </View>

                  
                  <View style={styles.row}>
                     <View style={{display:'flex', width:'50%'}}>
                        <Text style={styles.text}><span style={styles.span}>Nombre Test:  </span>{fileData.nombreTest.toUpperCase()}</Text>
                     </View>
                     <View style={{display:'flex'}}>
                        <Text style={styles.text}><span style={styles.span}>Tipo de muestra:  </span>{fileData.tipoMuestra.toUpperCase()}</Text>
                     </View>
                  </View>

                  <View style={{marginTop:'30px',marginBottom:'30px'}}>
                     <Text style={styles.small}><strong style={styles.strong}>Valores de referencia</strong></Text>
                     <Text style={styles.small}><strong style={styles.strong}>Negativo:</strong> Existe una alta probabilidad de no tener el virus SARS-CoV-2, en las vías respiratorias.</Text>
                     <Text style={styles.small}><strong style={styles.strong}>Positivo:</strong> Se detecta antígenos del virus SARS-CoV-2.</Text>
                  </View>
   

                  <Text style={styles.text}><strong>Observaciones: </strong><span style={styles.span}>{fileData.observaciones}</span></Text>

                  <View style={styles.row}>
                     <View style={{display:'flex', width:'50%'}}>
                        <View style={{marginTop:'0px',marginBottom:'0px'}}>
                           <Image style={styles.imageSign} src={firma} />
                           <Text style={styles.textSignTwo}>ENFERMERO</Text>
                           <Text style={styles.textSignTwo}>17.025.254-3</Text>
                           <Text style={styles.textSignTwo}>Registro SIS: 503232</Text>
                        </View>
                     </View>
                  </View>
                  

                  <View style={styles.row}>
                     <View style={{display:'flex', width:'50%'}}>
                        
                        <View style={{marginTop:'30px',marginBottom:'0px'}}>
                           <Text style={styles.textSign}><span style={styles.span}>https://cuiden.cl</span></Text>
                           <Text style={styles.textSign}>eumatias@cuiden.cl</Text>
                           <Text style={styles.textSign}><span style={styles.span}>+56948481332</span></Text>
                        </View>
                     </View>
                     <View style={{display:'flex', width:'40%'}}>
                        <View>
                           <Image style={styles.qrcode} src={qrCode} width={100} height={100} />
                        </View>
                     </View>
                  </View>
               </View>
            </Page>
            {/*  </PDFViewer> */}
         </Document>
   )
}