import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    TextField,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import UsuarioServices from './../../services/usuario';
import TopLoading from './../../components/TopLoading/TopLoading';



const RecuperarClave = () => {
    const [usuario, setUsuario] = useState({
        nombreusuario: ''
    });
    const [isSending, setIsSending] = useState(false);
    const [sended, setSended] = useState(false);
    const [error, setError] = useState('');
    const [mensajeRespuesta, setMensajeRespuesta] = useState();

    const handleChange = (e) => {
        setUsuario({
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async () => {
        setError('');
        setIsSending(true);

        if (!usuario.nombreusuario || usuario.nombreusuario.trim() === "") {
            setError("El nombre de usuario es obligatorio");
            setIsSending(false);
            return;
        }

        try {
            const response = await UsuarioServices.recuperarClave(usuario);
            if (response.data.success) {
                setSended(true);
                setError("");
                setMensajeRespuesta(response.data.data);
            } else {
                setSended(false);
                setError("No hemos encontrado un usuario asociado");
            };
        } catch {
            setError("No se ha podido enviar el correo");
        }
        setIsSending(false);
    }

    return (
        <Box
            style={{
                minHeight: '100vh',
                backgroundColor: '#f2f2f2',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}
            direction="column"
            alignItems="center"
            justify="center"
        >
            {isSending &&
                <TopLoading />

            }
            <Container maxWidth="sm" style={{ textAlign: 'center' }}>
                <form>
                    <Box md={{ mb: 5 }}>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            Recuperar contraseña
                        </Typography>
                    </Box>
                    <TextField
                        fullWidth
                        label="RUT / Pasaporte / DNI"
                        margin="normal"
                        name="nombreusuario"
                        type="text"
                        onChange={handleChange}
                        variant="outlined"
                        required
                    />

                    <Box sx={{ py: 4 }} style={{ marginTop: '40px', marginBottom: '40px' }}>
                        <Grid container spacing={3}>

                            {sended &&
                                <Grid item xs={12}>
                                    <Alert severity="success">{mensajeRespuesta}</Alert>
                                </Grid>
                            }

                            {error &&
                                <Grid item xs={12}>
                                    <Alert severity="error">{error}</Alert>
                                </Grid>
                            }
                        </Grid>
                        <Grid container spacing={3} alignContent="center" alignItems="center">
                            <Grid item xs={8}>
                                <Button
                                    disabled={isSending}
                                    onClick={handleSubmit}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                >
                                    Solicitar recuperación
                                </Button>

                            </Grid>
                            <Grid item xs={4}>
                                <Link to="/login" color="primary" variant="body2">Volver</Link>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Container>
        </Box >
    )
}

export default RecuperarClave;

function Alert(props) {
    return <MuiAlert variant="filled" {...props} />;
}