import React, { useState, useEffect } from 'react';
import {
   Grid,
   CircularProgress,
   Checkbox,
   Button,
   Divider,
   FormControl,
   FormControlLabel,
   Paper,
   Typography,
   TextField,
   RadioGroup,
   Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ConfiguracionServices from '../../../services/configuracion';
import Loading from './../../../components/Loading/Loading';
import { TrendingUpOutlined } from '@material-ui/icons';

const ConfigMensajeAyuda = () => {
   const [isChecked, setIsChecked] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [msjAsistencia, setMsjAsistencia] = useState({
      key: 'EMAIL_EXAMEN',
      contenido: '',
      eshabilitado: true,
   })

   const handleChange = (e) => {
      let state = msjAsistencia;
      setMsjAsistencia({
         ...state,
         [e.target.name]: e.target.value
      });
   };

   const handleHabilitado = () => {
      let state = msjAsistencia;
      setMsjAsistencia({
         ...state,
         eshabilitado: !isChecked
      });
      setIsChecked(!isChecked);
   }

   const submit = async () => {
      setIsLoading(true);
      let response = await ConfiguracionServices.update(msjAsistencia);
      if (!response.data) {
         setOpen(true);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      cargarDatos();

   }, []);


   const cargarDatos = async () => {
      setIsLoading(true);
      let response = await ConfiguracionServices.configuracion(msjAsistencia.key);
      if (response.data) {
         setMsjAsistencia(response.data);
         setIsChecked(response.data.eshabilitado)
      }
      setIsLoading(false);
   };


   /* Alert */
   const [open, setOpen] = React.useState(false);
   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setOpen(false);
   };


   return (
      <>
         <Paper>
            {isLoading ?
               <Loading />
               :
               <Grid container spacing={4} style={{ marginTop: '35px' }}>
                  <Grid item xs={12} md={6} style={{ padding: '20px 40px' }}>
                     <Typography component="h1" variant="h6" color="inherit">
                        Envío de correos
               </Typography>
                     <Typography variant="body2" color="inherit">
                        {msjAsistencia.contenido}
                     </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: '20px 40px' }}>
                     <Grid container spacing={2}>
                        <Grid item xs={12}>
                           <FormControl component="fieldset">
                              <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                 <FormControlLabel
                                    value={isChecked}

                                    control={<Checkbox defaultChecked={isChecked} onClick={handleHabilitado} color="primary" />}
                                    label="Habilitar el envío de correo"
                                    labelPlacement="end"
                                 />
                              </RadioGroup>
                           </FormControl>
                        </Grid>
                        <Divider />
                        <Grid item xs={12}>
                           <Button
                              color="primary"
                              variant="contained"
                              disabled={isLoading}
                              onClick={submit}>
                              Guardar Cambios
                           {isLoading &&
                                 <CircularProgress style={{ marginLeft: '10px' }} size={20} />
                              }
                           </Button>
                        </Grid>

                     </Grid>

                  </Grid>
               </Grid>
            }

         </Paper>

         <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
               Ha ocurrido un error en el procesamiento de la carga masiva
               </MuiAlert>
         </Snackbar>
      </>
   )
}

export default ConfigMensajeAyuda;