import React from 'react';
import {
   CardHeader
} from '@material-ui/core';

const RequestHeader = () => {
   return (
      <CardHeader
         className="formHeader"
         title="Agendar un exámen"
         subheader="Completa los campos con los datos de la persona que desea recibir el exámen"
      />
   )
};

export default RequestHeader;