import React, { forwardRef, useState, useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    FirstPage,
    FilterList,
    Edit,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import TipoOperativoServices from '../../../services/tipoOperativo';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Operativos = () => {
    const [datos, setDatos] = useState([]);

    useEffect(() => {
        TipoOperativoServices.getTipoOperativos()
            .then(res => {
                setDatos(res.data);
            })
            .catch(error => {
                setErrorOpen(true);
            });
    }, []);


    const newRow = async (nuevoOperativo) => {
        TipoOperativoServices.saveTipoOperativos(nuevoOperativo)
            .then(res => {
                if (res.data.success) {
                    nuevoOperativo.idtipooperativo = res.data.data.idtipooperativo;
                    setDatos([...datos, nuevoOperativo]);
                }
            })
            .catch(error => {
                setErrorOpen(true);
            })
    };

    const deleteRow = async (operativo) => {
        TipoOperativoServices.deleteTipoOperativos(operativo.idtipooperativo)
            .then(res => {
                if (res.data.success) {
                    const aux = [...datos];
                    const index = operativo.tableData.id;
                    aux.splice(index, 1);
                    setDatos([...aux]);
                }
            })
            .catch(error => {
                setErrorOpen(true);
            })
    }

    const updateRow = async (nuevoOperativo, operativoAnterior) => {
        TipoOperativoServices.updateTipoOperativos(nuevoOperativo)
            .then(res => {
                if (res.data.success) {
                    const aux = [...datos];
                    const index = operativoAnterior.tableData.id;
                    aux[index] = nuevoOperativo;
                    setDatos([...aux]);
                }
            })
            .catch(error => {
                setErrorOpen(true);
            })
    }

    /* Alert */
    const [errorOpen, setErrorOpen] = React.useState(false);
    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen(false);
    };

    return (
        <>
            <MaterialTable
                icons={tableIcons}
                title="Operativos"
                columns={[
                    { title: 'ID', field: 'idtipooperativo', editable: 'never' },
                    { title: 'Nombre', field: 'nombre' },
                    { title: 'Codigo negocio', field: 'codigonegocio' },
                    { title: 'Habilitado', field: 'eshabilitado', type: 'boolean' },
                ]}
                localization={{
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No existen datos',
                        addTooltip: 'Nuevo',
                        deleteTooltip: 'Eliminar',
                        editTooltip: 'Editar',
                        editRow: {
                            deleteText: '¿Seguro que deseas eliminarlo?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Eliminar',
                        }
                    },
                    toolbar: {
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar'
                    },
                    pagination: {
                        labelRowsSelect: 'Filas por página',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página'
                    }
                }}
                data={datos}
                options={{
                    filtering: true,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                }}

                editable={{
                    onRowAdd: nuevoOperativo => newRow(nuevoOperativo),
                    onRowUpdate: (nuevoOperativo, operativoAnterior) => updateRow(nuevoOperativo, operativoAnterior),
                    onRowDelete: operativo => deleteRow(operativo),
                }}
            />

            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleError}>
                <MuiAlert elevation={6} variant="filled" onClose={handleError} severity="error">
                    Ha ocurrido un error en la operación, recarga la página y vuelve a intentarlo
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default Operativos;