import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Card,
    Checkbox,
    CardContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    RadioGroup
} from '@material-ui/core';
import {
    NavigateNext
} from '@material-ui/icons';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { RutFormat, formatRut, validateRut } from '@fdograph/rut-utilities';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RequestHeader from './RequestHeader';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import previsiones from './../../resources/isapres';
moment.locale("es");



const RequestExamFirst = ({
    setStep,
    updateExamenPCR,
    examenPCR,
    sexo,
    nacionalidad,
    backButton,
    tipoOperativo
}) => {
    const [isBackButton] = useState(backButton || true);
    const [isRut, setIsRut] = useState(false);
    const [sexoInput] = useState(examenPCR.sexo);
    const [previsionInput] = useState(examenPCR.prevision);
    const [nacionalidadInput] = useState(examenPCR.nacionalidad);
    const [fechaNacimientoPicker, setFechaNacimientoPicker] = useState(null);
    const [dataExam, setDataExam] = useState(examenPCR ||
    {
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        fechaNacimiento: "",
        sexo: "",
        rut: "",
        pasaporte: "",
        nacionalidad: "",
        prevision: ""
    });
    const [dataError, setDataError] = useState({
        primerNombre: false,
        segundoNombre: false,
        primerApellido: false,
        segundoApellido: false,
        fechaNacimiento: false,
        sexo: false,
        rut: false,
        pasaporte: false,
        nacionalidad: false,
        prevision:false
    });

    useEffect(() => {
        console.log(examenPCR.fechaNacimiento);
        if (examenPCR.fechaNacimiento) {
            setFechaNacimientoPicker(moment(examenPCR.fechaNacimiento, 'DD/MM/YYYY'));
        }
    }, []);

    useEffect(() => {
        let fecha = moment(fechaNacimientoPicker).format('DD/MM/YYYY');
            setDataExam(data => ({
                ...data,
                fechaNacimiento: fecha
            }));
    }, [fechaNacimientoPicker]);

    const submit = () => {
        let validate = {
            primerNombre: false,
            segundoNombre: false,
            primerApellido: false,
            segundoApellido: false,
            fechaNacimiento: false,
            sexo: false,
            rut: false,
            pasaporte: false,
            nacionalidad: false
        }
        validacionCampos(validate);

        if (!hasError(validate)) {
            updateExamenPCR(dataExam);
            setStep(2);
        } else {
            setDataError(validate);
        }
    }

    const validacionCampos = (validate) => {
        validate.primerNombre = checkState("primerNombre");
        validate.primerApellido = checkState("primerApellido");
        validate.segundoApellido = checkState("segundoApellido");
        validate.fechaNacimiento = checkFechaNacimiento();
        validate.sexo = checkState("sexo");
        validate.nacionalidad = checkState("nacionalidad");
        validate.rut = checkIdentificador();
        validate.pasaporte = checkIdentificador();
    }

    const hasError = (validate) => {
        let errorFound = true;
        if (!validate.primerNombre
            && !validate.primerApellido
            && !validate.segundoApellido
            && !validate.fechaNacimiento
            && !validate.sexo
            && !validate.nacionalidad
            && !validate.pasaporte
            && !validate.rut) {
            errorFound = false;
        }
        return errorFound;
    };

    const checkIdentificador = () => {
        if (isRut) {
            return checkState("pasaporte");
        } else {
            return checkState("rut");
        }
    }

    const checkFechaNacimiento = () => {
        if (fechaNacimientoPicker) {
            return false;
        } else {
            return true;
        }
    }

    const checkState = (atributo) => {
        if (dataExam[atributo]) {
            if (dataExam[atributo].trim() === "") {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    const handleChange = (e) => {
        let data = {
            ...dataExam,
            [e.target.name]: e.target.value
        }
        setDataExam(data);
        setDataError(errorState => ({
            ...errorState,
            [e.target.name]: false
        }))
    };

    const removeError = () => {
        setDataError(errorState => ({
            ...errorState,
            fechaNacimiento: false
        }))
    }

    const onBlurRut = evnt => {
        let rutLower = (dataExam.rut + '').toLowerCase().trim();
        const isRutValid = validateRut(rutLower);
        if (isRutValid) {
            let formatRutValue = formatRut(rutLower, RutFormat.DASH);
            setDataExam(formState => ({
                ...formState,
                rut: formatRutValue
            }));
        } else {
            setDataError(errorState => ({
                ...errorState,
                rut: true
            }))
        }
    };

    const handleIdentificador = () => {
        setDataError(errorState => ({
            ...errorState,
            rut: false,
            pasaporte: false
        }));
        setIsRut(!isRut);

        
        let data = {
            ...dataExam,
            esExtranjero: !isRut
        }
        setDataExam(data);
    }

    return (
        <>
            <form autoComplete="off">
                <Card>
                    <RequestHeader />
                    <Divider />
                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Primer Nombre"
                                    name="primerNombre"
                                    onChange={handleChange}
                                    value={dataExam.primerNombre}
                                    required
                                    variant="outlined"
                                    error={dataError.primerNombre}
                                    helperText={
                                        dataError.primerNombre
                                            ? "Debes ingresar un nombre"
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Segundo Nombre"
                                    name="segundoNombre"
                                    onChange={handleChange}
                                    value={dataExam.segundoNombre}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Apellido Paterno"
                                    name="primerApellido"
                                    onChange={handleChange}
                                    value={dataExam.primerApellido}
                                    variant="outlined"
                                    required
                                    error={dataError.primerApellido}
                                    helperText={
                                        dataError.primerApellido
                                            ? "Debes ingresar un apellido"
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Apellido Materno"
                                    name="segundoApellido"
                                    onChange={handleChange}
                                    value={dataExam.segundoApellido}
                                    variant="outlined"
                                    required
                                    error={dataError.segundoApellido}
                                    helperText={
                                        dataError.segundoApellido
                                            ? "Debes ingresar un apellido"
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                md={tipoOperativo.toLowerCase() === "agendamiento" ? 4 : 6 }
                                sm={6}
                                xs={12}
                            >
                                <MuiPickersUtilsProvider
                                    utils={MomentUtils}
                                    libInstance={moment}
                                >
                                    <DatePicker
                                        required
                                        fullWidth
                                        disableFuture
                                        allowKeyboardControl
                                        label="Fecha de Nacimiento"
                                        defaultValue={null}
                                        value={fechaNacimientoPicker}
                                        onBlur={removeError}
                                        name="fechaNacimiento"
                                        inputVariant="outlined"
                                        format="DD/MM/yyyy"
                                        openTo="year"
                                        views={["year", "month", "date"]}
                                        onChange={setFechaNacimientoPicker}
                                        error={dataError.fechaNacimiento}
                                        helperText={
                                            dataError.fechaNacimiento
                                                ? "Fecha inválida"
                                                : "DD/MM/AAAA"
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid
                                item
                                md={tipoOperativo.toLowerCase() === "agendamiento" ? 4 : 6 }
                                sm={6}
                                xs={12}
                            >
                                <Autocomplete
                                    defaultValue={sexo.find(sex => sex.nombre === sexoInput)}
                                    options={sexo}
                                    getOptionLabel={(option) => option.nombre}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            name="sexo"
                                            onBlur={handleChange}
                                            label="Sexo"
                                            error={dataError.sexo}
                                            helperText={
                                                dataError.sexo
                                                    ? "Debes seleccionar una opción"
                                                    : null}
                                            variant="outlined" />
                                    } />
                            </Grid>
                            
                            {tipoOperativo.toLowerCase() === "agendamiento" ?
                                <Grid
                                    item
                                    md={4}
                                    sm={6}
                                    xs={12}
                                >
                                    <Autocomplete
                                        defaultValue={previsiones.find(prevision => prevision.name === previsionInput)}
                                        options={previsiones}
                                        getOptionLabel={(prevision) => prevision.name}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                name="prevision"
                                                onBlur={handleChange}
                                                label="Previsión Social"
                                                error={dataError.prevision}
                                                helperText={
                                                    dataError.prevision
                                                        ? "Debes seleccionar una opción"
                                                        : null}
                                                variant="outlined" />
                                        } />
                                </Grid>
                            : null}
                        </Grid>


                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                sm={4}
                                xs={6}
                            >
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                        <FormControlLabel
                                            value="extranjero"
                                            control={<Checkbox onClick={() => handleIdentificador()} color="primary" />}
                                            label="Soy extranjero y no tengo rut"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                sm={4}
                                xs={6}
                            >
                                {isRut ?
                                    <TextField
                                        fullWidth
                                        label="Pasaporte / DNI"
                                        name="pasaporte"
                                        onChange={handleChange}
                                        value={dataExam.pasaporte}
                                        variant="outlined"
                                        required
                                        error={dataError.pasaporte}
                                        helperText={
                                            dataError.pasaporte
                                                ? "El pasaporte ingresado no es válido"
                                                : null}
                                    />
                                    :
                                    <TextField
                                        fullWidth
                                        label="Rut"
                                        name="rut"
                                        onChange={handleChange}
                                        onBlur={onBlurRut}
                                        value={dataExam.rut}
                                        variant="outlined"
                                        required
                                        error={dataError.rut}
                                        helperText={
                                            dataError.rut
                                                ? "El rut ingresado no es válido"
                                                : "Ejemplo: 11222333-K"}
                                    />
                                }
                            </Grid>


                            <Grid
                                item
                                md={4}
                                sm={4}
                                xs={6}
                            >
                                <Autocomplete
                                    defaultValue={nacionalidad.find(nac => nac.nombre === nacionalidadInput)}
                                    options={nacionalidad}
                                    getOptionLabel={(option) => option.nombre}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={dataError.nacionalidad}
                                            helperText={
                                                dataError.nacionalidad
                                                    ? "Debes seleccionar una opción"
                                                    : null}
                                            name="nacionalidad"
                                            onBlur={handleChange}
                                            label="Nacionalidad"
                                            variant="outlined" />
                                    }
                                />

                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}

                            >
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={submit}
                                >
                                    Continuar <NavigateNext />
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                </Card>
                {isBackButton === "true" ?
                    < Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                            style={{
                                margin: '20px',
                                textAlign: 'center'
                            }}
                        >
                            <Link to="/">ir al inicio</Link>

                        </Grid>
                    </Grid>

                    : null}
            </form>
        </>
    )
}

export default RequestExamFirst;