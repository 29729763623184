import React from 'react';
import { Grid } from '@material-ui/core';
import './Loading.css';

const Loading = () => {
	return (
		<Grid
			container
			direction="row"
			justify="center"
			className="spinnerWrapper"
			alignItems="center">
			<div className="lds-roller">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</Grid>
	)
};

export default Loading;
