import React, { useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Typography,
    TextField,
} from '@material-ui/core';
import API, {
    setNewToken,
    isApiLoged,
    getLogedPatient,
    setLogedPatient,
  } from "./../../services/api";
import MuiAlert from '@material-ui/lab/Alert';
import { Link, useHistory } from 'react-router-dom';
import LoginService from './../../services/usuario';

function Alert(props) {
    return <MuiAlert variant="filled" {...props} />;
}

const Login = ({ setAccount }) => {
    const history = useHistory();
    const [login, setLogin] = useState({
        nombreusuario: "",
        password: ""
    });
    const [dataError, setDataError] = useState({
        nombreusuario: false,
        password: false
    });
    const [isLoading, setIsLoading] = useState(false);
    const errorEnCampo = (campo) => {
        setDataError(errorState => ({
            ...errorState,
            [campo]: true
        }));
    };

    const [error, setError] = useState();
    const hashed = "5f59cc3c8619c54d759c5fdc23b62b198ecdb9307b3fdcb6a2f7efa4a418281c";

    const handleSubmit = async () => {
        let ready = true;
        setIsLoading(true);

        if (login.nombreusuario.trim() === "") {
            errorEnCampo("nombreusuario");
            ready = false;
        }

        if (login.password.trim() === "") {
            errorEnCampo("password");
            ready = false;
        }

        if (ready) {

            const response = await LoginService.login(login);
            console.log(response);

            if (response.data.success) {
                console.log(response.data);
                setAccount(response.data.data);
                setNewToken(response.data.data.token);
                localStorage.setItem('saem', JSON.stringify(response.data.data));

                console.log(response.data.data.rol);
                if(response.data.data.rol === "paciente"){
                    history.push('/cuenta');
                }else{
                    history.push('/admin/home');
                }
            } else {
                setError("La credenciales son incorrectas");
            }   
        } 
        setIsLoading(false);
    }

    const handleChange = (e) => {
        e.preventDefault();
        let state = {
            ...login,
            [e.target.name]: e.target.value
        }
        setLogin(state);
        setDataError(errorState => ({
            ...errorState,
            [e.target.name]: false
        }));
        setError(null);
    }

    return (
        <Box
            style={{
                minHeight: '100vh',
                backgroundColor: '#f2f2f2',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Container maxWidth="sm" style={{ textAlign: 'center' }}>
                <form>
                    <Box sx={{ mb: 3 }}>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            Ver resultados
                        </Typography>
                    </Box>
                    <TextField
                        fullWidth
                        label="RUT / Pasaporte / DNI"
                        margin="normal"
                        name="nombreusuario"
                        type="text"
                        variant="outlined"
                        onBlur={handleChange}
                        required
                        error={dataError.nombreusuario}
                        helperText={
                            dataError.nombreusuario
                                ? "Debes completar este campo"
                                : null
                        }
                    />
                    <TextField
                        fullWidth
                        label="Contraseña"
                        margin="normal"
                        name="password"
                        type="password"
                        variant="outlined"
                        onBlur={handleChange}
                        required
                        error={dataError.password}
                        helperText={
                            dataError.password
                                ? "Debes completar este campo"
                                : null
                        }
                    />
                    <Typography component="p" variant="body2" color="inherit">¿No recuerdas tu contraseña?, Solicita una nueva clave <Link to="/recuperar-clave">aquí</Link></Typography>

                    <Box sx={{ py: 4 }} style={{ marginTop: '40px', marginBottom: '40px' }}>
                        <Grid container spacing={3}>

                            {error &&
                                <Grid item xs={12}>
                                    <Alert severity="error">{error}</Alert>
                                </Grid>
                            }

                        </Grid>

                        <Grid container spacing={3} alignContent="center" alignItems="center">
                            <Grid item xs={8}>
                                <Button
                                    onClick={handleSubmit}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    disabled={isLoading}
                                >
                                    Ingresar
                                    {isLoading &&
                                        <CircularProgress style={{ marginLeft: '10px' }} size={20} />
                                    }
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Link to="/" color="primary" variant="body2">Volver</Link>
                            </Grid>
                        </Grid>

                    </Box>
                </form>
            </Container>
        </Box >
    )
}

export default Login;