import React, { forwardRef, useState, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { IconButton, Snackbar } from "@material-ui/core";
import {
  AddBox,
  ArrowDownward,
  Assignment,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  FirstPage,
  FilterList,
  Edit,
  LastPage,
  MonetizationOn,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import MaterialTable from "material-table";
import ExamenServices from "./../../../services/examen";
import ArchivoServices from "./../../../services/archivo";
import Loading from "./../../../components/Loading/Loading";
import ExamenView from "./../../../components/ExamenView/ExamenView";
import FileView from "./../../../components/FileView/FileView";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const TablaHistoricos = ({ datos }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tipo, setTipo] = useState(""); /* examen | informe | boleta */
  const [file, setFile] = useState({});
  const [examenPop, setExamenPop] = useState({
    identificador: "",
    nombre: "",
    fechanacimiento: "",
    sexo: "",
    nacionalidad: "",
    telefono: "",
    correo: "",
    calle: "",
    ciudad: "",
    comuna: "",
    region: "",
  });
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const loadInforme = (informe) => {
    setFile(informe);
    setTipo("informe");
    setOpen(true);
  };

  const loadBoleta = (boleta) => {
    setFile(boleta);
    setTipo("boleta");
    setOpen(true);
  };

  const loadExamen = async (id) => {
    ExamenServices.detalleExamen(id)
      .then((res) => {
        setExamenPop(res.data.data);
        setOpen(true);
        setTipo("examen");
      })
      .catch((error) => {
        setOpen(false);
        setErrorOpen(true);
      });
  };

  /* Alert */
  const [errorOpen, setErrorOpen] = React.useState(false);
  const handleError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const handleEliminar = async (file) => {
    setIsLoading(true);
    let response = await ArchivoServices.eliminarArchivo(file);
    if (response.data.success) {
      window.location.reload();
    } else {
      setErrorOpen(true);
    }
  };

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title="Exámenes"
        columns={[
          {
            title: "ID",
            field: "idexamen",
            render: (rowData) => (
              <>
                <span>{rowData.idexamen}</span>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => loadExamen(rowData.idexamen)}
                >
                  <Search />
                </IconButton>
              </>
            ),
          },
          { title: "Examen", field: "tipoexamen" },
          { title: "Operativo", field: "tipooperativo" },
          {
            title: "Fecha registro",
            field: "fecharealizacion",
            type: "datetime",
            dateSetting: { locale: "es-ES" },
          },
          {
            title: "Fecha Agendamiento",
            field: "fechaagendamiento",
            type: "date",
            dateSetting: { locale: "es-ES" },
          },
          { title: "RUT / Pasaporte", field: "persona.identificador" },
          { title: "Nombres", field: "persona.nombres" },
          { title: "Apellido Paterno", field: "persona.primerapellido" },
          { title: "Apellido Materno", field: "persona.segundoapellido" },
          { title: "Fecha de Nacimiento", field: "persona.fechanacimiento" },
          { title: "Género", field: "persona.sexo" },
          { title: "Dirección", field: "persona.direccion" },
          { title: "Comuna", field: "persona.comuna" },
          { title: "Teléfono", field: "persona.telefono" },
          { title: "Email", field: "persona.correo" },
        ]}
        localization={{
          header: {
            actions: "Documentos",
          },
          body: {
            emptyDataSourceMessage: "No existen datos",
            deleteTooltip: "Eliminar",
            editRow: {
              deleteText: "¿Seguro que deseas eliminarlo?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Eliminar",
            },
          },
          pagination: {
            labelRowsSelect: "Filas por página",
            labelDisplayedRows: "{from}-{to} de {count}",
            firstTooltip: "Primera página",
            previousTooltip: "Página anterior",
            nextTooltip: "Próxima página",
            lastTooltip: "Última página",
          },
        }}
        data={datos}
        options={{
          filtering: true,
          exportButton: true,
          exportAllData: true,
          pageSize: 10,
        }}
        actions={[
          (rowData) => ({
            icon: () => (
              <Assignment onClick={() => loadInforme(rowData.informe)} />
            ),
            tooltip: "Ver informe",
            hidden: rowData.informe.length === 0,
          }),
          (rowData) => ({
            icon: () => (
              <MonetizationOn onClick={() => loadBoleta(rowData.boleta)} />
            ),
            tooltip: "Descargar boleta",
            hidden: rowData.boleta.length === 0,
          }),
        ]}
      />

      {tipo === "examen" && (
        <ExamenView
          examenPop={examenPop}
          open={open}
          handleClose={handleClose}
        />
      )}

      {tipo === "informe" && (
        <FileView
          examenPop={examenPop}
          open={open}
          handleClose={handleClose}
          file={file}
          handleEliminar={handleEliminar}
        />
      )}

      {tipo === "boleta" && (
        <FileView
          examenPop={examenPop}
          open={open}
          handleClose={handleClose}
          file={file}
          handleEliminar={handleEliminar}
        />
      )}

      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleError}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleError}
          severity="error"
        >
          Ha ocurrido un error en la operación, recarga la página y vuelve a
          intentarlo
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default TablaHistoricos;
