import API from './api';
import URLS from './URLS';

const AsignacionServices = {

   guardarAsignacion: function (asignacion) {
      return API.axios({
         method: 'POST',
         url: URLS.asignacion,
         data: asignacion
      })
   },

   examenesAsignados: function ( data ) {
      return API.axios({
         method: 'post',
         url: URLS.asignacion + '/ver',
         data: data
      })
   },

   borrarAsignacion: function (idasignacion) {
      return API.axios({
         method: 'delete',
         url: URLS.asignacion + '/delete/' + idasignacion
      })
   },

   /* eliminarArchivo: function (archivo) {
      return API.axios({
         method: 'POST',
         url: URLS.archivo + '/eliminar',
         data: archivo
      })
   } */
};
export default AsignacionServices;