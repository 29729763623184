import React, { useState, useEffect } from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import FormularioHistorico from "./FormularioHistorico";
import TablaHistoricos from "./TablaHistoricos";

const Historico = () => {
  const [datosHistoricos, setDatosHistoricos] = useState();

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ padding: "25px 15px" }}>
          <Typography component="h1" variant="h6" color="inherit">
            Búsqueda de Exámenes
          </Typography>
        </Grid>
      </Grid>

      <FormularioHistorico setDatosHistoricos={setDatosHistoricos} />

      <Grid
        item
        md={12}
        xs={12}
        style={{ marginTop: "40px", marginBottom: "20px" }}
      >
        <Divider />
      </Grid>

      <TablaHistoricos datos={datosHistoricos} />
    </>
  );
};

export default Historico;
