import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Box,
    Container,
    Card,
    CardContent,
    CardActionArea,
    CardActions,
    CardMedia,
    Typography,
    Grid
} from '@material-ui/core';
import logo from '../../resources/images/cuiden.svg';
import agendar from '../../resources/images/efectuarexamen.jpeg';
import resultados from '../../resources/images/resultadoexamen.jpeg';

var build_date = process.env.REACT_APP_BUILD_DATE;
var version    = process.env.REACT_APP_BUILD_VERSION;


const Home = () => {
    const history = useHistory();

    const goExamen = () => {
        let path = '/solicitar-examen';
        history.push(path);
    }

    const goResultados = () => {
        let path = '/login';
        history.push(path);
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <Container maxWidth="md">
                    <div className="" style={{ margin: '20px' }}>
                        <Grid
                            container
                            spacing={6}
                            alignItems="center"
                            justify="center"
                        >

                            <Grid
                                item
                                sm={12}
                                md={12}
                                xs={12}
                                style={{ textAlign: 'center' }}
                            >
                                <img src={logo} alt="Cuiden" width="200" />
                                <Typography style={{ marginTop: '10px' }} variant="body2" color="textSecondary" component="p">Plataforma de agendamiento y resultados de exámenes</Typography>

                            </Grid>
                            <Grid
                                item
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <Card>
                                    <CardActionArea onClick={goExamen}>
                                        <CardMedia
                                            component="img"
                                            alt="Contemplative Reptile"
                                            height="240"
                                            image={agendar}
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Solicitar exámen
                                        </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Realizamos exámenes PCR / Antigeno en la seguridad de tu hogar y con alto nivel de calidad. Resultados en 24 horas.
                                    </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions style={{ textAlign: 'center' }}>
                                        <Button onClick={goExamen} variant="contained" color="primary">
                                            Agenda tu hora
                                    </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                sm={6}
                                xs={12}
                            >
                                <Card>
                                    <CardActionArea onClick={goResultados}>
                                        <CardMedia
                                            component="img"
                                            alt="Contemplative Reptile"
                                            height="240"
                                            image={resultados}
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Resultados PCR
                                        </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Ingresa a revisar los resultados de los exámenes PCR que te hayas realizado o solicita uno nuevo.
                                    </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions style={{ textAlign: 'center' }}>
                                        <Button onClick={goResultados} variant="contained" color="primary">
                                            Ver resultados
                                    </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </Box>
            <Box>
                <Container>
                    <Grid
                        container
                        spacing={6}
                        alignItems="center"
                        justify="center"
                    >
                        <div style={{paddingTop:'20px', fontSize:'11.5px',opacity:'0.5'}}>
                            <small>Actualización: {build_date} | Versión: {version}</small>
                        </div>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Home;