import API from './api';
import URLS from './URLS';

const UsuarioService = {

   login: function (user) {
      return API.axios({
         method: 'post',
         url: 'login',
         data: user
      })
   },

   refreshToken: function () {
      return API.axios({
         method: "post",
         url: 'login/refreshToken',
      });
   },

   recuperarClave: function (user) {
      return API.axios({
         method: 'post',
         url: 'login/recuperar',
         data: user
      })
   },


   getUsuarios: function () {
      return API.axios({
         method: 'get',
         url: `${URLS.usuarios}`
      });
   },

   getUsuario: function (idusuario) {
      return API.axios({
         method: 'get',
         url: `${URLS.usuarios}` + '/' + idusuario
      });
   },

   getUsuariosActivos: function () {
      return API.axios({
         method: 'get',
         url: URLS.usuarios + '/activos'
      });
   },

   saveUsuario: function (nuevo) {
      return API.axios({
         method: 'post',
         url: URLS.usuarios + '/nuevo',
         data: nuevo
      })
   },

   deleteUsuario: function (idusuario) {
      return API.axios({
         method: 'delete',
         url: URLS.usuarios + '/delete/' + idusuario
      })
   },

   updateUsuario: function (usuario) {
      return API.axios({
         method: 'put',
         url: URLS.usuarios + '/update/' + usuario.idusuario,
         data: usuario
      })
   },

   checkUsuario: function (username) {
      return API.axios({
         method: 'post',
         url: URLS.usuarios + '/checkUsuario',
         data: username
      })
   },

   updateUsuarioCuenta: function (usuario) {
      return API.axios({
         method: 'put',
         url: URLS.usuarios + '/updateCuenta/' + usuario.idusuario,
         data: usuario
      })
   },

   updatePassword: function (usuario) {
      return API.axios({
         method: 'put',
         url: URLS.usuarios + '/updatePassword/' + usuario.idusuario,
         data: usuario
      })
   },

   getUsuariosPorRol: function (nombrerol){
      return API.axios({
         method: 'get',
         url: URLS.usuarios + '/rol/' + nombrerol
      })
   },

   getRoles: function () {
      return API.axios({
         method: 'get',
         url: `${URLS.roles}`
      });
   },

};
export default UsuarioService;