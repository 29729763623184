import React, { forwardRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { 
    Button, 
    TextField, 
    Snackbar 
} from '@material-ui/core';
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    FirstPage,
    FilterList,
    Edit,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
    AssignmentInd,
    Add
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import UsuarioService from '../../../services/usuario';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Usuarios = () => {
    const history = useHistory();
    const [datos, setDatos] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        fetchAll();
    }, []);

    const fetchAll = async () => {
        try {
            const roles = await UsuarioService.getRoles();
            let rolesAux = {}
            roles.data.forEach(rol => {
                rolesAux[rol.idrol] = rol.nombre;
            });
            setRoles(rolesAux);

            const usuarios = await UsuarioService.getUsuarios();
            if(usuarios && usuarios.data){
                setDatos(usuarios.data);
            }

        } catch (e) {
        }
    }

    const deleteRow = async (usuario) => {
        UsuarioService.deleteUsuario(usuario.idusuario)
            .then(res => {
                if (res.data.success) {
                    const aux = [...datos];
                    const index = usuario.tableData.id;
                    aux.splice(index, 1);
                    setDatos([...aux]);
                }
            })
            .catch(error => {
                setErrorOpen(true);
            })
    }

    const updateRow = async (regNuevo, regAnterior) => {
        UsuarioService.updateUsuario(regNuevo)
            .then(res => {
                if (res.data.success) {
                    const aux = [...datos];
                    const index = regAnterior.tableData.id;
                    aux[index] = regNuevo;
                    setDatos([...aux]);
                }
            })
            .catch(error => {
                setErrorOpen(true);
            })
    }

    /* Alert */
    const [errorOpen, setErrorOpen] = React.useState(false);
    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen(false);
    };

    return (
        <>
            <MaterialTable
                icons={tableIcons}
                title="Usuarios"
                columns={[
                    { title: 'Nombre usuario', field: 'nombreusuario', editable:false },
                    { title: 'Nombre', field: 'primernombre', editable:false, 
                        render: rowData => 
                            <>{rowData.primernombre} {rowData.primerapellido}</>
                        
                    },
                    { title: 'Rol', field: 'idrol', lookup: roles, type:'numeric' },
                    { title: 'Habilitado', field: 'eshabilitado', type: 'boolean' },
                ]}
                localization={{
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No existen datos',
                        addTooltip: 'Nuevo',
                        deleteTooltip: 'Eliminar',
                        editTooltip: 'Editar',
                        editRow: {
                            deleteText: '¿Seguro que deseas eliminarlo?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Eliminar',
                        }
                    },
                    toolbar: {
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar'
                    },
                    pagination: {
                        labelRowsSelect: 'Filas por página',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página'
                    }
                }}
                data={datos}
                options={{
                    filtering: true,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                }}
                
                actions={[
                    {
                        icon: () => ( <Add /> ),
                        tooltip: 'Nuevo usuario',
                        position: "toolbar",
                        onClick: () => history.push('/admin/usuarios/nuevo'),
                    },
                    rowData => (
                        {
                            icon: () => (
                                <AssignmentInd />
                            ),
                            onClick: () => history.push('/admin/usuarios/' + rowData.idusuario + '/editar'),
                            tooltip: 'Editar todos los campos',
                        }
                    ),
                ]}
                
                editable={{
                    onRowUpdate: (regNuevo, regAnterior) => updateRow(regNuevo, regAnterior),
                    onRowDelete: reg => deleteRow(reg),
                }}
            />

            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleError}>
                <MuiAlert elevation={6} variant="filled" onClose={handleError} severity="error">
                    Ha ocurrido un error en la operación, recarga la página y vuelve a intentarlo
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default Usuarios;