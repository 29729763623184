import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  DialogActions,
  TextField
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useState } from "react";
import NumberFormat from 'react-number-format';
import ExamenServices from "../../../services/examen";

const EditarAsignacion = ({examen, open, setOpen, estados, operadores, mediosDePago, reaload}) => {

  const [data, setData] = useState({
    estado: examen.idestado,
    operador: examen.idusuario,
    mediopago: examen.idmediopago,
    montopago: examen.montopago,
    observacion: examen.observacion
  });
  const [dataError, setDataError] = useState({
    estado: false,
    operador: false,
    mediopago: false,
    montopago: false,
    observacion: false
  });

  const handleSelectChange = (target, value) => {
    if(value){
      let dataAux = {
        ...data,
        [target]: value
      }
      setData(dataAux);
      setDataError(dataError => ({
        ...dataError,
        [target]: false
      }))
    }
  };

  const handleChange = (e) => {
    if(e.target.value.length > 255){
      return;
    }

    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submit = async ()  => {    
     
    let dataAux = {
      idexamen: examen.idexamen,
      idusuario: data.operador,
      idestado: data.estado,
      idmediopago: data.mediopago,
      montopago: data.montopago,
      observacion: data.observacion
    }
    const response = await ExamenServices.updateExamen(dataAux);
    setOpen(false);
    reaload();
  }

  const defaultOperador = () => {
    let opcionSeleccionada = operadores.filter(item => item.idusuario === examen.idusuario);
    return opcionSeleccionada[0];
  }

  const defaultEstado = () => {
    let opcionSeleccionada = estados.filter(item => item.idestado === examen.idestado);
    return opcionSeleccionada[0];
  }

  const defaultMedioPago = () => {
    if(examen.idmediopago){
      let opcionSeleccionada = mediosDePago.filter(item => item.idmediopago === examen.idmediopago);
      return opcionSeleccionada[0];
    } else {
      return null;
    }
  }

  return(
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">{"Editar asignación"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={3}>

              <Grid item md={6} sm={6} xs={12}>
                <Autocomplete
                  defaultValue={defaultOperador}
                  options={operadores}
                  getOptionLabel={(option) =>
                      option.primernombre + " " + option.primerapellido
                    }
                  onChange={(event, newValue) => {
                    if(newValue){
                      handleSelectChange("operador", newValue.idusuario)
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      name="operador"
                      label="Operador"
                      error={dataError.operador}
                      variant="outlined"
                      helperText={
                          dataError.operador
                              ? "Debes seleccionar una opción"
                              : null} />
                  } 
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <Autocomplete
                  defaultValue={defaultEstado}
                  options={estados}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(event, newValue) => {
                    if(newValue){
                      handleSelectChange("estado", newValue.idestado)
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      name="estado"
                      label="Estados"
                      error={dataError.estado}
                      variant="outlined"
                      helperText={
                          dataError.estado
                              ? "Debes seleccionar una opción"
                              : null} />
                  } 
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <Autocomplete
                  disabled={data.estado !== 3}
                  defaultValue={defaultMedioPago}
                  options={mediosDePago}
                  getOptionLabel={(option) => option.nombre}
                  onChange={(event, newValue) => {
                    if(newValue){
                      handleSelectChange("mediopago", newValue.idmediopago)
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      name="mediopago"
                      label="Medio de pago"
                      error={dataError.mediopago}
                      variant="outlined"
                      helperText={
                          dataError.mediopago
                              ? "Debes seleccionar una opción"
                              : null} />
                  } 
                />
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                <TextField
                    disabled={data.estado !== 3}
                    label="Monto pago"
                    value={data.montopago}
                    onChange={handleChange}
                    name="montopago"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    error={dataError.montopago}
                    helperText={
                      dataError.montopago
                          ? "Debes ingresar un monto"
                          : null}
                  />
              </Grid>


              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  label="Observación"
                  value={data.observacion}
                  onChange={handleChange}
                  name="observacion"
                  fullWidth
                  variant="outlined"
                  multiline
                  helperText="El mensaje no puede ser más largo de 255 carácteres"
                />
              </Grid>

            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
          <Button onClick={submit} color="primary">Guardar</Button>
        </DialogActions>
      </Dialog>
  )
};

export default EditarAsignacion;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=','
      isNumericString
      prefix="$"
    />
  );
}