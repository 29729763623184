import React, { useEffect, useState } from 'react';
import {
   Button,
   Card,
   Checkbox,
   CardContent,
   Divider,
   FormControl,
   FormGroup,
   FormHelperText,
   FormControlLabel,
   FormLabel,
   Grid,
   TextField,
   RadioGroup,
   Radio
} from '@material-ui/core';
import {
   NavigateNext
} from '@material-ui/icons';
import InputMask from 'react-input-mask';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/es";
import RequestHeader from './RequestHeader';
import {
   DatePicker,
   MuiPickersUtilsProvider,
} from '@material-ui/pickers';
moment.locale("es");

const RequestExamThird = ({
   setStep,
   updateExamenPCR,
   examenPCR,
   motivoExamen,
   sintomas,
   submitExamen
}) => {
   const [fechaSintomas, setFechaSintomas] = useState(null);
   const [dataExam, setDataExam] = useState(examenPCR ||
   {
      motivoExamen: "",
      fechaContactoEstrecho: "",
      auxSintomas: {
         ninguno: false,
      },
      sintomas: [],
      fechaSintomas: "",
   });
   const [dataError, setDataError] = useState({
      motivoExamen: false,
      fechaContactoEstrecho: false,
      auxSintomas: false,
      sintomas: false,
      fechaSintomas: false
   });

   useEffect(() => {
      let fecha = moment(fechaSintomas).format('DD/MM/YYYY');
      setDataExam(data => ({
         ...data,
         fechaSintomas: fecha
      }));
   }, [fechaSintomas]);

   const submit = () => {
      if (dataExam.motivoExamen.trim() === "") {
         errorEnCampo("motivoExamen");
         return;
      }

      let aux = [];
      if (dataExam.auxSintomas.ninguno) {
         aux.push("Ninguno");
      } else {
         sintomas.forEach(item => {
            let result = dataExam.auxSintomas[item.codigonegocio];
            if (result) { aux.push(item.nombre); }
         });
      }

      if (aux.length == 0) {
         errorEnCampo("sintomas");
         return;
      } else {
         if ((!dataExam.auxSintomas.ninguno) && dataExam.fechaSintomas.trim() == "Fecha inválida") {
            errorEnCampo("fechaSintomas");
            return;
         }
      }

      let submitingState = {
         ...dataExam,
         sintomas: aux
      };

      setDataExam(submitingState);
      submitExamen(submitingState);
   }

   const handleChange = (e) => {
      let data = {
         ...dataExam,
         [e.target.name]: e.target.value
      }
      setDataExam(data);
      setDataError(errorState => ({
         ...errorState,
         [e.target.name]: false
      }))
   };

   const errorEnCampo = (campo) => {
      setDataError(errorState => ({
         ...errorState,
         [campo]: true
      }));
   };

   const handleMultiple = (event) => {
      let data = {
         ...dataExam,
         auxSintomas: {
            ...dataExam.auxSintomas,
            [event.target.name]: event.target.checked
         }
      }
      setDataExam(data);
      setDataError(errorState => ({
         ...errorState,
         sintomas: false
      }));
   };

   return (
      <>
         <form autoComplete="off">
            <Card>
               <RequestHeader />
               <Divider />
               <CardContent>
                  <Grid
                     container
                     spacing={3}>
                     <Grid
                        item
                        md={12}
                        xs={12}>
                        <FormControl component="fieldset" error={dataError.motivoExamen}>
                           <FormLabel component="legend">Motivo del exámen:</FormLabel>
                           <RadioGroup
                              row
                              aria-label="position"
                              name="motivoExamen"
                              onChange={handleChange}
                              defaultValue="top"

                           >
                              {motivoExamen.map((option) => (
                                 <FormControlLabel
                                    key={option.idmotivosintoma}
                                    value={option.nombre}
                                    label={option.nombre}
                                    control={
                                       <Radio color="primary" />} />
                              ))}
                           </RadioGroup>
                        </FormControl>
                     </Grid>

                     {dataExam.motivoExamen === "Contacto Estrecho" ?
                        <Grid
                           item
                           md={12}
                           xs={12}
                        >

                           <FormLabel component="legend" style={{ marginBottom: '10px' }}>¿Cuando fue el <em>último contacto</em> con el caso confirmado?</FormLabel>
                           <InputMask
                              mask="99/99/9999"
                              maskChar=" "
                              onChange={handleChange}
                              value={dataExam.fechaContactoEstrecho}
                           >
                              {() => <TextField
                                 required
                                 fullWidth
                                 label="Ingresar fecha"
                                 variant="outlined"
                                 helperText="DD/MM/AAAA"
                                 name="fechaContactoEstrecho" />}
                           </InputMask>

                        </Grid>

                        : null}

                     <Grid
                        item
                        md={12}
                        xs={12}
                     >
                        <FormControl component="fieldset" error={dataError.sintomas}>
                           <FormLabel component="legend">¿Presenta algún tipo de sintoma?</FormLabel>
                           <FormGroup aria-label="position" row>
                              {sintomas.map((option => {
                                 return option.codigonegocio === 'ninguno' ?
                                    <FormControlLabel
                                       label={option.nombre}
                                       labelPlacement="end"
                                       control={
                                          <Checkbox
                                             onChange={handleMultiple}
                                             color="primary"
                                             name={option.codigonegocio}
                                          />
                                       }
                                    />
                                    :
                                    <FormControlLabel
                                       label={option.nombre}
                                       labelPlacement="end"
                                       disabled={dataExam.auxSintomas.ninguno}
                                       control={
                                          <Checkbox
                                             onChange={handleMultiple}
                                             color="primary"
                                             name={option.codigonegocio}
                                          />
                                       }
                                    />
                              }))}
                           </FormGroup>
                           {dataError.sintomas &&
                              <FormHelperText>Debes seleccionar una o varias opciones</FormHelperText>
                           }
                        </FormControl>

                     </Grid>

                     {!dataExam.auxSintomas.ninguno &&
                        <Grid
                           item
                           md={12}
                           xs={12}
                        >
                           <MuiPickersUtilsProvider
                              utils={MomentUtils}
                              libInstance={moment}
                           >
                              <DatePicker
                                 fullWidth
                                 disableToolbar
                                 disableFuture
                                 label="Fecha de inicio de sintomas"
                                 value={fechaSintomas}
                                 name="fechaAgendamiento"
                                 inputVariant="outlined"
                                 format="DD/MM/yyyy"
                                 onChange={setFechaSintomas}
                                 error={dataError.fechaSintomas}
                                 helperText={
                                    dataError.fechaSintomas
                                       ? "Fecha inválida"
                                       : "DD/MM/AAAA"
                                 }
                              />
                           </MuiPickersUtilsProvider>

                        </Grid>
                     }
                  </Grid>
                  <Grid
                     container
                     spacing={3}
                  >
                     <Grid item md={12} xs={12} >
                        <Divider />
                     </Grid>
                     <Grid item md={6} xs={12} >
                        <Button
                           color="primary"
                           variant="contained"
                           onClick={submit}
                        >
                           Continuar <NavigateNext />
                        </Button>
                     </Grid>
                     <Grid item md={6} xs={12} style={{ textAlign: 'right' }}>
                        <Button onClick={() => setStep(2)} >Volver</Button>
                     </Grid>
                  </Grid>
               </CardContent>
               <Divider />

            </Card>
         </form>
      </>
   )
}

export default RequestExamThird;