import React, { forwardRef, useState, useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { IconButton, Snackbar } from '@material-ui/core';
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    FirstPage,
    FilterList,
    Edit,
    Email,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import PersonaService from '../../../services/persona';
import NacionalidadServices from './../../../services/nacionalidad';
import SexoServices from './../../../services/sexo';
import UsuarioServices from './../../../services/usuario';
import './Persona.css';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Examenes = () => {
    const [datos, setDatos] = useState([]);
    const [nacionalidad, setNacionalidad] = useState([]);
    const [sexo, setSexo] = useState([]);
    const [message, setMessage] = useState({
        tipo: '',
        mensaje: ''
    })

    useEffect(() => {
        fetchAll();
        PersonaService.personas()
            .then(res => {
                setDatos(res.data);
            })
            .catch(error => {
                setErrorOpen(true);
            });
    }, []);

    const fetchAll = async () => {
        try {
            const nacionalidad = await NacionalidadServices.getNacionalidades();
            const sexo = await SexoServices.getSexos();

            let nacionalidadResult = {}
            nacionalidad.data.forEach(nacionalidadItem => {
                nacionalidadResult[nacionalidadItem.idnacionalidad] = nacionalidadItem.nombre;
            });
            setNacionalidad(nacionalidadResult);

            let sexoResult = {}
            sexo.data.forEach(sexoItem => {
                sexoResult[sexoItem.idsexo] = sexoItem.nombre;
            });

            setSexo(sexoResult);
            console.log(sexoResult);
        } catch (e) {
        }
    }

    /* Alert */
    const [errorOpen, setErrorOpen] = React.useState(false);
    const handleError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen(false);
    };

    const deleteRow = async (persona) => {
        PersonaService.deletePersona(persona.idpersona)
            .then(res => {
                if (res.data.success) {
                    const aux = [...datos];
                    const index = persona.tableData.id;
                    aux.splice(index, 1);
                    setDatos([...aux]);
                }
            })
            .catch(error => {
                setMessage({
                    tipo: 'error',
                    mensaje: 'No se pudo eliminar al usuario indicado'
                });
                setErrorOpen(true);
            })
    }

    const updateRow = async (nuevaPersona, personaAnterior) => {
        PersonaService.updatePersona(nuevaPersona)
            .then(res => {
                if (res.data.success) {
                    const aux = [...datos];
                    const index = personaAnterior.tableData.id;
                    aux[index] = nuevaPersona;
                    setDatos([...aux]);
                }
            })
            .catch(error => {
                setMessage({
                    tipo: 'error',
                    mensaje: 'No se ha logrado actualizar los datos, verifica que hayas completado bien los campos'
                });
                setErrorOpen(true);
            })
    }

    const enviarCredenciales = async (rowData) => {
        let hasRut = rowData.rut && rowData.rut.trim().length > 0;
        let hasPasaporte = rowData.pasaporte && rowData.pasaporte.trim().length > 0;

        if (!hasRut && !hasPasaporte) {
            setMessage({
                tipo: 'error',
                mensaje: 'El rut o pasaporte no ha sido ingresado'
            });
            setErrorOpen(true);
            return;
        }

        let nombreUsuario;
        if (hasRut && !hasPasaporte) {
            nombreUsuario = rowData.rut;
        } else {
            nombreUsuario = rowData.pasaporte;
        }

        try {
            const response = await UsuarioServices.recuperarClave({ nombreusuario: nombreUsuario });
            if (response.data.success) {
                setMessage({
                    tipo: 'success',
                    mensaje: response.data.data
                });
                setErrorOpen(true);
            } else {
                setMessage({
                    tipo: 'error',
                    mensaje: response.data.data
                });
                setErrorOpen(true);
            };
        } catch (error) {
            setMessage({
                tipo: 'error',
                mensaje: 'El correo no pudo ser enviado al correo indicado. Posibilemente por que no exista o este marcado como un correo spam.'
            });
            setErrorOpen(true);
        }
    }

    return (
        <>
            <MaterialTable
                icons={tableIcons}
                title="Personas"
                columns={[
                    { title: 'ID', field: 'idpersona', editable: false },
                    { title: 'RUT', field: 'rut' },
                    { title: 'Pasaporte', field: 'pasaporte' },
                    { title: 'Nombre', field: 'primernombre' },
                    { title: 'Segundo Nombre', field: 'segundonombre' },
                    { title: 'Apellido', field: 'primerapellido' },
                    { title: 'Segundo Apellido', field: 'segundoapellido' },
                    { title: 'Email', field: 'correo' },
                    { title: 'Nacionalidad', field: 'idnacionalidad', lookup: nacionalidad, type: 'numeric' },
                    { title: 'Región', field: 'region' },
                    { title: 'Comuna', field: 'comuna' },
                    { title: 'Ciudad', field: 'ciudad' },
                    { title: 'Calle', field: 'calle' },
                    { title: 'Sexo', field: 'idsexo', lookup: sexo, type: 'numeric' },
                    { title: 'Fecha Nacimiento', field: 'fechanacimiento' },
                    { title: 'Fecha Creación', field: 'fechacreacion', type: 'date', editable: false }
                ]}
                localization={{
                    header: {
                        actions: 'Opciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'No existen datos',
                        deleteTooltip: 'Eliminar',
                        editTooltip: 'Editar',
                        editRow: {
                            deleteText: '¿Seguro que deseas eliminarlo?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Eliminar',
                        }
                    },
                    pagination: {
                        labelRowsSelect: 'Filas por página',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página'
                    }
                }}
                actions={[
                    rowData => (
                        {
                            icon: () => (
                                <Email />
                            ),
                            onClick: () => enviarCredenciales(rowData),
                            tooltip: 'Reenviar credenciales',
                            hidden: rowData.correo.length === 0
                        }
                    )

                ]}
                data={datos}
                options={{
                    filtering: true,
                    pageSize: 10,
                }}
                editable={{
                    onRowUpdate: (nuevaPersona, personaAnterior) => updateRow(nuevaPersona, personaAnterior),
                    onRowDelete: persona => deleteRow(persona),
                }}
            />

            <Snackbar open={errorOpen} autoHideDuration={20000} onClose={handleError}>
                <MuiAlert elevation={6} variant="filled" onClose={handleError} severity={message.tipo}>
                    {message.mensaje}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default Examenes;