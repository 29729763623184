import API from './api';
import URLS from './URLS';

const EstadoService = {

    getEstados: function () {
        return API.axios({
            method: 'get',
            url: `${URLS.estado}`
        });
    },

};

export default EstadoService;