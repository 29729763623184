import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import RecuperarClave from './pages/Login/RecuperarClave';
import RequestExam from './pages/RequestExam/RequestExam';
import AdminRouting from './pages/Admin/AdminRouting';
import CuentaRouting from './pages/Cuenta/CuentaRouting';
import API, {
  setNewToken,
  isApiLoged,
  getLogedPatient,
  setLogedPatient,
} from "./services/api";
import UsuarioService from './services/usuario';
import PrintableConsentimiento from './pages/Printable/Consentimiento';
import PrintableEtiqueta from './pages/Printable/Etiqueta';
import PrintableResultadoOperacionPDF from './pages/Printable/ResultadoOperacion';
import ResultadoTemplatePDF from './pages/Printable/ResultadoTemplate';

function App() {
  const [patient, setPatient] = useState(JSON.parse(localStorage.getItem('saem')) || {
    nombreusuario: ''
  });
  const [isLoged, setIsLoged] = useState(false);
  const [_isApiLoged, setIsApiLoged] = useState(isApiLoged);
  const [tryAutologin, setTryAutologin] = useState(false);


  const hashing = async () => {
    let msgBuffer = new TextEncoder().encode(patient.nombreusuario);
    let hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    let hashArray = Array.from(new Uint8Array(hashBuffer));
    let hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }

  useEffect(() => {
    hashing();
  }, []);

  useEffect(() => {
  }, [patient]);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem('saem'));
    if(data){
      setNewToken(data.token);
    }
  }, [_isApiLoged]);

  if (_isApiLoged !== isApiLoged) {
    setIsApiLoged(isApiLoged)
  }

  if (!patient && getLogedPatient()) {
    setPatient(getLogedPatient())
  }

  useEffect(() => {
    if (!_isApiLoged) {
      UsuarioService
        .refreshToken()
        .then((response) => {
          if (response.data.accessToken) {
            
           /*  localStorage.setItem('saem', JSON.stringify({ nombreusuario: login.nombreusuario })); */
            const p = setLogedPatient(response.data.patient);
            setPatient(p)
            
          }
          setTryAutologin(true);
        })
        .catch((error) => {
          console.log("autologin error :", error);
          setTryAutologin(true);
          
        });
    } else {
     
    }

  }, [_isApiLoged]);

  useEffect(() => {

    if (tryAutologin && !isLoged) {
      // alert('redirecciona al login')
      // history.push('/movil/login')

      // movil/login
    }

  }, [tryAutologin, isLoged]);



  return (

    <div className="cuiden">
      <Router>
        <Switch>
          <Route path="/admin">
            <AdminRouting account={patient} />
          </Route>
          <Route path="/cuenta">
            <CuentaRouting account={patient} />
          </Route>
          <Route exact path="/login">
            <Login setAccount={setPatient} />
          </Route>
          <Route exact path="/recuperar-clave">
            <RecuperarClave />
          </Route>
          <Route exact path="/solicitar-examen">
            <RequestExam backButton="true" tipoOperativo="Agendamiento" />
          </Route>
          <Route exact path="/printable/consentimiento/:idexamen">
            <PrintableConsentimiento />
          </Route>
          <Route exact path="/printable/etiqueta/:idexamen">
            <PrintableEtiqueta />
          </Route>
          <Route exact path="/resultado/:data">
            <PrintableResultadoOperacionPDF />
          </Route>
          <Route exact path="/resultado/base/:data">
            <ResultadoTemplatePDF />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
