import API from './api';

const ConfiguracionServices = {

   configuracion: function (key) {
      return API.axios({
         method: 'get',
         url: '/configuracion/' + key
      });
   },

   update: function (configuracion) {
      return API.axios({
         method: 'put',
         url: '/configuracion/update/' + configuracion.key,
         data: configuracion
      })
   },

   activo: function (key) {
      return API.axios({
         method: 'get',
         url: '/configuracion/activo/' + key
      });
   },

};
export default ConfiguracionServices;