import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  FormLabel,
  Divider,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loading from "./../../../components/Loading/Loading";
import RegionesComunas from "../../../resources/RegionesComunas";
import TipoExamenServices from "../../../services/tipoExamen";
import TipoOperativoServices from "../../../services/tipoOperativo";
import ExamenServices from "../../../services/examen";

const FormularioHistorico = ({ setDatosHistoricos }) => {
  const [operativos, setOperativos] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [tipoExamenes, setTipoExamenes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [submited, setSubmited] = useState(false);
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaTermino, setFechaTermino] = useState();

  const [query, setQuery] = useState({
    rut: "",
    fechaRegistroInicial: "",
    fechaRegistroTermino: "",
    comuna: "",
    tipoExamen: "",
    tipoOperativo: "",
  });

  const init_data = async () => {
    setIsLoading(true);

    let comunasAcum = [];
    RegionesComunas.forEach((comunaArr) => {
      comunasAcum.push(...comunaArr.communes);
    });

    comunasAcum.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    setComunas(comunasAcum);

    const operativosAcum = await TipoOperativoServices.getTipoOperativos();
    if (operativosAcum.data) {
      setOperativos(operativosAcum.data);
    }

    const tipoExamenesAcum = await TipoExamenServices.getTipoExamenes();
    if (tipoExamenesAcum.data) {
      setTipoExamenes(tipoExamenesAcum.data);
    }

    setIsLoading(false);
  };

  const handleChange = (e) => {
    let data = {
      ...query,
      [e.target.name]: e.target.value,
    };
    setQuery(data);
  };

  const submit = async (e) => {
    setSubmited(true);

    let queryAux = {
      ...query,
    };

    if (query.tipoExamen) {
      let objTipoExamenes = tipoExamenes.find(
        (item) => item.nombre == query.tipoExamen
      );
      queryAux = {
        ...query,
        tipoExamen: objTipoExamenes.idtipoexamen,
      };
    }

    if (query.tipoOperativo) {
      let objOperativos = operativos.find(
        (item) => item.nombre == query.tipoOperativo
      );
      queryAux = {
        ...query,
        tipoOperativo: objOperativos.idtipooperativo,
      };
    }

    const response = await ExamenServices.getExamenesHistoricos(queryAux);
    if (response.data) {
      setDatosHistoricos(response.data);
    }

    setSubmited(false);
  };

  useEffect(() => {
    init_data();
  }, []);

  return (
    <Paper>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} style={{ padding: "20px 40px" }}>
            <Typography component="h1" variant="h6" color="inherit">
              Obtener histórico
            </Typography>

            <Typography variant="body2" color="inherit">
              Completa los campos para buscar los exámenes según necesidad.
              Puedes dejar los campos en blanco para traer todos los resultados.
            </Typography>

            <small style={{ marginTop: "10px", display: "block" }}>
              La búsqueda puede tomar un tiempo según la cantidad de registros.
            </small>
          </Grid>
          <Grid item xs={12} md={8} style={{ padding: "20px 40px" }}>
            <form autoComplete="off">
              <Grid container spacing={3}>
                <Grid item md={6} sm={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Rut"
                    name="rut"
                    onChange={handleChange}
                    value={query.rut}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Autocomplete
                    options={comunas}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="comuna"
                        onBlur={handleChange}
                        label="Comuna"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Autocomplete
                    options={tipoExamenes}
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="tipoExamen"
                        onBlur={handleChange}
                        label="Tipo examen"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Autocomplete
                    options={operativos}
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="tipoOperativo"
                        onBlur={handleChange}
                        label="Tipo operativo"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ marginTop: "15px" }}>
                <Grid item md={12} xs={12}>
                  <Divider />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submited}
                  >
                    Buscar{" "}
                    {submited && (
                      <CircularProgress
                        style={{
                          width: "26px",
                          height: "26px",
                          marginLeft: "15px",
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Divider />
        </Grid>
      )}
    </Paper>
  );
};

export default FormularioHistorico;
