import React, { forwardRef, useState, useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Button,
  Divider,
  Grid,
  TextField,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  FirstPage,
  FilterList,
  Edit,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from '@material-ui/icons';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MaterialTable from 'material-table';
import ExamenServices from '../../../services/examen';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

//Servicios
import UsuarioService from '../../../services/usuario';
import AsignacionServices from '../../../services/asignacion';
import Loading from '../../../components/Loading/Loading';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useHistory } from 'react-router-dom';

moment.locale("es");

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const NuevaAsignacion = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosInput, setUsuariosInput] = useState();
  const [fechaAsignacion, setFechaAsignacion] = useState();
  
  const [datos, setDatos] = useState([]);
  const [message, setMessage] = useState({
    tipo: '',
    mensaje: ''
  })
  const [asignacion, setAsignacion] = useState(
  {
    personaAsignada: "",
    fechaAsignacion: moment(new Date()),
    examenesSeleccionados: [],
  });

  const [dataError, setDataError] = useState({
    personaAsignada: false,
    fechaAsignacion: false,
    examenesSeleccionados: false,
  });

  const [examenesAsignados, setExamenesAsignados] = useState([]);
  const [isLoadingAsignados, setIsLoadingAsignados] = useState(false);
  const [isLoadingDisponibles, setIsLoadingDisponibles] = useState(false);

  const fetchInit = async () => {
    try {
      setIsLoading(true);
      const usuariosPorRol = await UsuarioService.getUsuariosPorRol('operador');
      setUsuarios(usuariosPorRol.data.data);
    } catch (e) {
      setMessage({ tipo: 'error', mensaje: 'Ha ocurrido un problema, intenta cargando la página nuevamente' });
      setErrorOpen(true);
    }
    setIsLoading(false);
  };


  const fetchExamenesDisponibles = async () => {
    setIsLoadingDisponibles(true);
    try {
      const examenesResponse = await ExamenServices.buscarExamenesNoAsignados(); 
      setDatos(examenesResponse.data);
    } catch (e) {
      setMessage({ tipo: 'error', mensaje: 'Ha ocurrido un problema, intenta cargando la página nuevamente' });
      setErrorOpen(true);
    }
    setIsLoadingDisponibles(false);
  }

  useEffect(() => {
    fetchInit();
    fetchExamenesDisponibles();
  }, []);

  const submit = async ()  => {
    let validate = {
      personaAsignada: false,
      fechaAsignacion: false,
      examenesSeleccionados: false,
    }
    validacionCampos(validate);

    if (!hasError(validate)) {
      let data = {
        ...asignacion,
        fechaAsignacion:moment(fechaAsignacion).format('DD/MM/YYYY')
      }
      const response = await AsignacionServices.guardarAsignacion(data);
      if(response.data.success){
        setMessage({ tipo: 'success', mensaje: 'Se ha realizado la asignación correctamente' });
        setErrorOpen(true);
        fetchExamenesDisponibles();
        loadExamenes();
      }else{
        setMessage({ tipo: 'error', mensaje: 'Ha ocurrido un error en la asignación, verifica que los datos hayan sido completados correctamente.' });
        setErrorOpen(true);
      }
    } else {
      setDataError(validate);
    }
  }

  const validacionCampos = (validate) => {
    validate.personaAsignada = (!asignacion.personaAsignada > 0 );
    validate.fechaAsignacion =  (!asignacion.fechaAsignacion);
    validate.examenesSeleccionados = (!asignacion.examenesSeleccionados.length > 0 );
  }

  const hasError = (validate) => {
    let errorFound = true;
     if (!validate.personaAsignada
      && !validate.fechaAsignacion
      && !validate.examenesSeleccionados
      ) {
      errorFound = false;
    } 
    return errorFound;
  };

  const handlerSelection = (rows) => {
    if(rows){
      let data = {
        ...asignacion,
        examenesSeleccionados: rows
      };
      setAsignacion(data);
      setDataError(dataError => ({
        ...dataError,
        examenesSeleccionados: false
      }))
    }
  };

  const handleChange = (idusuario) => {
    if(idusuario){
      let data = {
        ...asignacion,
        personaAsignada: idusuario
      }
      setAsignacion(data);
      setDataError(dataError => ({
        ...dataError,
        personaAsignada: false
      }))
    }
  };

  const loadExamenes = async () => {
    setIsLoadingAsignados(true);

    if(asignacion.personaAsignada && asignacion.fechaAsignacion){
      let data = {
        idusuario: asignacion.personaAsignada,
        fechaAsignacion: moment(fechaAsignacion).format('DD/MM/YYYY')
      }
      const response = await AsignacionServices.examenesAsignados(data);
      if(response && response.data){
        setExamenesAsignados(response.data);
      }else{
        setMessage({ tipo: 'error', mensaje: 'Ha ocurrido un problema en la petición al servidor' });
        setErrorOpen(true);
        console.log(response);
      }
    }else{
      setDataError(dataError => ({
        ...dataError,
        personaAsignada: true,
        fechaAsignacion: true
      }))
    }

    setIsLoadingAsignados(false);
  }

  const deleteRow = async (asignacion) => {
    AsignacionServices.borrarAsignacion(asignacion.idasignacion)
      .then(res => {
        if (res.data.success) {
            const aux = [...examenesAsignados];
            const index = asignacion.tableData.id;
            aux.splice(index, 1);
            setExamenesAsignados([...aux]);
            fetchExamenesDisponibles();
            loadExamenes();
        }
    })
    .catch(error => {
        console.log(error);
        setErrorOpen(true);
    })
  }

  /* Alert */
  const [errorOpen, setErrorOpen] = React.useState(false);
  const handleError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
  };

  return (
    <>
      
        {isLoading ?
          <Loading />
          :
          
          <Grid container spacing={2} >
            <Grid item md={12} xs={12} >
              <Paper style={{ marginTop: '10px', padding: '20px' }}>
                <Grid item xs={12} md={12}>
                  <Typography component="h1" variant="h6" color="inherit">Asignación de exámenes</Typography>
                  <Divider style={{marginTop:'10px', marginBottom:'20px'}} />
                </Grid>
                
                <Grid container spacing={4}>
                  <Grid item md={4} sm={4} xs={12}>
                    <Autocomplete
                      options={usuarios}
                      getOptionLabel={(option) => (option.primernombre + ' ' + option.primerapellido)}
                      onChange={(event, newValue,reason) => {
                        if(reason === "clear"){
                          handleChange(null);
                          setAsignacion({
                            ...asignacion,
                            personaAsignada:null
                          })
                        }
                        if(newValue){
                          handleChange(newValue.idusuario)
                        }
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          error={dataError.personaAsignada}
                          name="personaAsignada"
                          label="Seleccionar operador"
                          variant="outlined" 
                          helperText={
                            dataError.personaAsignada
                              ? "Debes seleccionar una opción"
                              : null}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={4}>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <DatePicker
                        required
                        fullWidth
                        disableToolbar
                        variant="inline"
                        label="Fecha de Asignación"
                        value={fechaAsignacion}
                        name="fechaAsignacion"
                        inputVariant="outlined"
                        format="DD/MM/yyyy"
                        openTo="date"
                        views={["year", "month", "date"]}
                        onChange={setFechaAsignacion}
                        error={dataError.fechaNacimiento}
                        helperText={
                            dataError.fechaNacimiento
                                ? "Fecha inválida"
                                : "DD/MM/AAAA"
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={4} xs={4}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => loadExamenes()}
                      >
                        Cargar exámenes
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item md={12} xs={12}>
              <MaterialTable
                isLoading={isLoadingAsignados}
                style={{
                  width: '100%',
                  border: (dataError.examenesSeleccionados ? '1px solid #f44336' : '')
                }}
                icons={tableIcons}
                title={'Exámenes asignados ' +  (fechaAsignacion ? moment(fechaAsignacion).format('DD/MM/YYYY') : ' - ')}
                columns={[
                    { title: 'ID asignacion', field: 'idasignacion', hidden:true },
                    { title: 'ID', field: 'idexamen' },
                    { title: 'Examen', field: 'tipoexamen' },
                    { title: 'Agendamiento', field: 'fechaagendamiento', type: 'date', dateSetting: { locale: 'es-ES' } },
                    { title: 'Comuna', field: 'persona.comuna' },
                    { title: 'Dirección', field: 'persona.direccion', filtering: false  },
                    { title: 'Nombre', field: 'persona.nombres', filtering: false  },
                    { title: 'Telefono', field: 'persona.telefono', filtering: false  },
                    { title: 'Email', field: 'persona.correo', filtering: false  },
                ]}
                localization={{
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: 'No existen datos',
                        deleteTooltip: 'Eliminar',
                        editRow: {
                            deleteText: '¿Seguro que deseas eliminar la asignación?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Eliminar',
                        },
                        filterRow: {
                          filterTooltip: 'Filtrar'
                      }
                    },
                    pagination: {
                        labelRowsSelect: 'Filas por página',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página'
                    },
                    toolbar: {
                      nRowsSelected: '{0} exámenes seleccionados',
                      searchPlaceholder: 'Buscar'
                  },
                }}
                data={examenesAsignados}
                options={{
                    filtering: true,
                    pageSize: 5,
                    search:false,
                    rowStyle: {
                      padding: '0px 6px',
                    },
                    cellStyle:{
                      padding:'0px 6px'
                    },
                }}
                editable={{
                  onRowDelete: asignacion => deleteRow(asignacion),
              }}
                onSelectionChange={(rows) => handlerSelection(rows)}
              />
              
              {dataError.examenesSeleccionados ? <p style={{color:'#f44336'}}>Debes seleccionar almenos un exámen</p> : null }
            </Grid>

            <Grid item md={12} xs={12}>
              <MaterialTable
                style={{
                  width: '100%',
                  border: (dataError.examenesSeleccionados ? '1px solid #f44336' : '')
                }}
                isLoading={isLoadingDisponibles}
                icons={tableIcons}
                title="Exámenes disponibles"
                columns={[
                    { title: 'ID', field: 'idexamen'},
                    { title: 'Examen', field: 'tipoexamen' },
                    { title: 'Agendamiento', field: 'fechaagendamiento', type: 'date', dateSetting: { locale: 'es-ES' } },
                    { title: 'Comuna', field: 'persona.comuna' },
                    { title: 'Dirección', field: 'persona.direccion', filtering: false  },
                    { title: 'Nombre', field: 'persona.nombres', filtering: false  },
                    { title: 'Telefono', field: 'persona.telefono', filtering: false  },
                    { title: 'Email', field: 'persona.correo', filtering: false  },
                ]}
                localization={{
                    header: {
                        actions: 'Documentos'
                    },
                    body: {
                        emptyDataSourceMessage: 'No existen datos',
                        deleteTooltip: 'Eliminar',
                        editRow: {
                            deleteText: '¿Seguro que deseas eliminarlo?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Eliminar',
                        },
                        filterRow: {
                          filterTooltip: 'Filtrar'
                      }
                    },
                    pagination: {
                        labelRowsSelect: 'Filas por página',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: 'Primera página',
                        previousTooltip: 'Página anterior',
                        nextTooltip: 'Próxima página',
                        lastTooltip: 'Última página'
                    },
                    toolbar: {
                      nRowsSelected: '{0} exámenes seleccionados',
                      searchPlaceholder: 'Buscar'
                  },
                }}
                data={datos}
                options={{
                    filtering: true,
                    pageSize: 10,
                    selection: true,
                    search:false,
                    rowStyle: {
                      padding: '0px 6px',
                    },
                    cellStyle:{
                      padding:'0px 6px'
                    },
                }}
                onSelectionChange={(rows) => handlerSelection(rows)}
              />
              
              {dataError.examenesSeleccionados ? <p style={{color:'#f44336'}}>Debes seleccionar almenos un exámen</p> : null }
            </Grid>


            <Grid item md={6} xs={12} >
              <Button style={{marginRight:'20px'}} onClick={() => history.push('/admin/usuarios')} >
                Volver
              </Button>              
            </Grid>
            <Grid item md={6} xs={12} style={{textAlign:'right'}}>
              <Button
                  color="primary"
                  variant="contained"
                  onClick={submit}
                >
                  Asignar seleccionados
                </Button>
            </Grid>

          </Grid>
        }

      
      <Snackbar open={errorOpen} autoHideDuration={20000} onClose={handleError}>
        <MuiAlert elevation={6} variant="filled" onClose={handleError} severity={message.tipo}>
            {message.mensaje}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default NuevaAsignacion;