import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import BuscarPaciente from './../NuevoExamen/BuscarPaciente';
import RequestExam from './../../RequestExam/RequestExam';
import OperativoServices from '../../../services/operativo';
import Loading from './../../../components/Loading/Loading';


const FormularioPaciente = () => {
   const { idoperacion } = useParams();
   const [user, setUser] = useState(null);
   const [ready, setReady] = useState(false);
   const [tipoOperativo, setTipoOperativo] = useState();
   const [isLoading, setIsLoading] = useState(true);

   const handleUser = (user) => {
      setReady(false);
      setUser(user);
   }

   useEffect(() => {
      OperativoServices.getOperacion(idoperacion)
         .then((response) => {
            setTipoOperativo(response.data.data.nombre);
            setIsLoading(false);
         })
         .catch((error) => {
            console.log(error);
            setIsLoading(false);
         })
   }, []);

   useEffect(() => {
      if (user) {
         setReady(true);
      }
   }, [user]);

   if (isLoading) {
      return (
         <Loading />
      )
   } else {
      return (
         <>
            <BuscarPaciente
               handleUser={handleUser}
               setReady={setReady}
               setTipoOperativo={setTipoOperativo}
               tipoOperativo={tipoOperativo}
            />

            <Button
               style={{ marginTop: '10px' }}
               onClick={() => window.history.back()}
            >
               <ArrowBackIos style={{ marginRight: '10px' }} /> Volver
            </Button>

            {ready &&
               <RequestExam
                  user={user}
                  backButton="false"
                  tipoOperativo={tipoOperativo}
                  printable="false"
                  operacion={idoperacion}
               />
            }
         </>
      )
   }
};

export default FormularioPaciente;