import React, { forwardRef, useState, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Button,
  Grid,
  TextField,
  Paper,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import {
  AddBox,
  AttachMoney,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  CancelPresentation,
  Clear,
  DeleteOutline,
  FirstPage,
  FilterList,
  Edit,
  HourglassEmpty,
  LocalShipping,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MaterialTable from "material-table";
import NumberFormat from 'react-number-format';
import Autocomplete from "@material-ui/lab/Autocomplete";
import './asignacion.css';

//Servicios
import UsuarioService from "../../../services/usuario";
import AsignacionServices from "../../../services/asignacion";
import Loading from "../../../components/Loading/Loading";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import EstadoService from "../../../services/estado";
import MedioPagoService from "../../../services/medioPago";
import EditarAsignacion from "./EditarAsignacion";

moment.locale("es");

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Asignacion = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [ estados, setEstados ] = useState([]);
  const [ medioPago, setMedioPago ] = useState([]);
  const [open, setOpen] = useState(false);
  const [fechaAsignacion, setFechaAsignacion] = useState();

  const [message, setMessage] = useState({
    tipo: "",
    mensaje: "",
  });
  const [asignacion, setAsignacion] = useState({
    personaAsignada: "",
    fechaAsignacion: moment(new Date()),
    examenesSeleccionados: [],
  });

  const [dataError, setDataError] = useState({
    personaAsignada: false,
    fechaAsignacion: false,
    examenesSeleccionados: false,
  });

  const [examenSeleccionado, setExamenSeleccionado ] = useState({});

  const [examenesAsignados, setExamenesAsignados] = useState([]);
  const [isLoadingAsignados, setIsLoadingAsignados] = useState(false);
  const [resumenCifras, setResumenCifras ] = useState({
    pendientes: 0,
    encurso: 0,
    cancelados: 0,
    pagados: 0
  })
  

  const fetchInit = async () => {
    try {
      setIsLoading(true);
      
      const usuariosPorRol = await UsuarioService.getUsuariosPorRol("operador");
      if(usuariosPorRol && usuariosPorRol.data){
        setUsuarios(usuariosPorRol.data.data);
      }

      const responseEstados = await EstadoService.getEstados();
      if(responseEstados && responseEstados.data){
        setEstados(responseEstados.data);
      }

      const responsePagos = await MedioPagoService.getMedioPagos();
      if(responsePagos && responsePagos.data){
        setMedioPago(responsePagos.data);
      }      
      
    } catch (e) {
      setMessage({
        tipo: "error",
        mensaje:
          "Ha ocurrido un problema, intenta cargando la página nuevamente",
      });
      setErrorOpen(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchInit();
    fetchExamenes();
  }, []);

  useEffect(() => {
    let examCancelados = 0;
    let examPendientes = 0;
    let examEnCurso = 0;
    let examPagados = 0;

    if(examenesAsignados){
      examenesAsignados.forEach(examen => {
        if(examen.estado === "Pendiente"){ examPendientes++ }
        if(examen.estado === "En ruta"){ examEnCurso++ }
        if(examen.estado === "Pagado"){ examPagados++ }
        if(examen.estado === "Cancelado"){ examCancelados++ }
      });
  
      setResumenCifras({
        pendientes: examPendientes,
        encurso: examEnCurso,
        cancelados: examCancelados,
        pagados: examPagados
      })
    }
  }, [examenesAsignados]);

  const handlerSelection = (rows) => {
    if (rows) {
      let data = {
        ...asignacion,
        examenesSeleccionados: rows,
      };
      setAsignacion(data);
      setDataError((dataError) => ({
        ...dataError,
        examenesSeleccionados: false,
      }));
    }
  };

  const handleChange = (idusuario) => {
    if (idusuario) {
      let data = {
        ...asignacion,
        personaAsignada: idusuario,
      };
      setAsignacion(data);
      setDataError((dataError) => ({
        ...dataError,
        personaAsignada: false,
      }));
    }
  };

  const fetchExamenes = async () => {
    setIsLoadingAsignados(true);

    let data = {
      idusuario: asignacion.personaAsignada,
      fechaAsignacion: moment(fechaAsignacion).format("DD/MM/YYYY"),
    };

    const response = await AsignacionServices.examenesAsignados(data);
    if (response && response.data) {
      setExamenesAsignados(response.data);
    }

    setIsLoadingAsignados(false);
  };

  const handleEditar = (rowData) => {
    setExamenSeleccionado(rowData);
    setOpen(true);
  }

  /* Alert */
  const [errorOpen, setErrorOpen] = React.useState(false);
  const handleError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Paper style={{ marginTop: "10px", padding: "20px" }}>
              <Grid container spacing={4}>
                <Grid item md={4} sm={4} xs={12}>
                  <Autocomplete
                    options={usuarios}
                    getOptionLabel={(option) =>
                      option.primernombre + " " + option.primerapellido
                    }
                    onChange={(event, newValue, reason) => {
                      console.log(reason);
                      if(reason === "clear"){
                        handleChange(null);
                        setAsignacion({
                          ...asignacion,
                          personaAsignada:null
                        })
                      }
                      if (newValue) {
                        handleChange(newValue.idusuario);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={dataError.personaAsignada}
                        name="personaAsignada"
                        label="Seleccionar operador"
                        variant="outlined"
                        helperText={
                          dataError.personaAsignada
                            ? "Debes seleccionar una opción"
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={4}>
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    libInstance={moment}
                  >
                    <DatePicker
                      required
                      fullWidth
                      disableToolbar
                      variant="inline"
                      label="Fecha de Asignación"
                      value={fechaAsignacion}
                      name="fechaAsignacion"
                      inputVariant="outlined"
                      format="DD/MM/yyyy"
                      openTo="date"
                      views={["year", "month", "date"]}
                      onChange={setFechaAsignacion}
                      error={dataError.fechaNacimiento}
                      helperText={
                        dataError.fechaNacimiento ? "Fecha inválida" : ""
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={4} xs={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    style={{ marginTop: "8px" }}
                    onClick={() => fetchExamenes()}
                  >
                    Actualizar datos
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item md={3} xs={6}>
            <Paper style={{ marginTop: "10px", padding: "10px" }}>
              
              <Grid className="card-asignacion" container spacing={2} alignContent="center" alignItems="center">
                <Grid item>
                  <div className="circle-icon color-pendiente">
                    <HourglassEmpty />
                  </div>
                </Grid>
                <Grid item>
                  <small>Pendientes</small>
                  <h2>{resumenCifras.pendientes}</h2>
                </Grid>
              </Grid>
              
            </Paper>
          </Grid>
          <Grid item md={3} xs={6}>
            <Paper style={{ marginTop: "10px", padding: "10px" }}>

              <Grid className="card-asignacion" container spacing={2} alignContent="center" alignItems="center">
                <Grid item>
                  <div className="circle-icon color-encurso">
                    <LocalShipping />
                  </div>
                </Grid>
                <Grid item>
                  <small>En curso</small>
                  <h2>{resumenCifras.encurso}</h2>
                </Grid>
              </Grid>

            </Paper>
          </Grid>
          <Grid item md={3} xs={6}>
            <Paper style={{ marginTop: "10px", padding: "10px" }}>

              <Grid className="card-asignacion" container spacing={2} alignContent="center" alignItems="center">
                <Grid item>
                  <div className="circle-icon color-pagados">
                    <AttachMoney />
                  </div>
                </Grid>
                <Grid item>
                  <small>Pagados</small>
                  <h2>{resumenCifras.pagados}</h2>
                </Grid>
              </Grid>

            </Paper>
          </Grid>
          <Grid item md={3} xs={6}>
            <Paper style={{ marginTop: "10px", padding: "10px" }}>

              <Grid className="card-asignacion" container spacing={2} alignContent="center" alignItems="center">
                <Grid item>
                  <div className="circle-icon color-cancelados">
                    <CancelPresentation />
                  </div>
                </Grid>
                <Grid item>
                  <small>Cancelados</small>
                  <h2>{resumenCifras.cancelados}</h2>
                </Grid>
              </Grid>

            </Paper>
          </Grid>

          <Grid item md={12} xs={12}>
            <MaterialTable
              isLoading={isLoadingAsignados}
              style={{
                width: "100%",
                border: dataError.examenesSeleccionados
                  ? "1px solid #f44336"
                  : "",
              }}
              icons={tableIcons}
              title={false}
              columns={[
                { title: "ID asignacion", field: "idasignacion", hidden: true },
                { title: "ID Estado", field: "idestado", hidden: true },
                { title: "ID Usuario", field: "idusuario", hidden: true },
                { title: "ID", field: "idexamen",
                  render: rowData => 
                  <>
                    {rowData.idexamen}
                    <IconButton 
                      aria-label="Dirección" 
                      color='primary' 
                      size='small'
                      onClick={() => handleEditar(rowData)}
                    >
                    <Edit style={{fontSize:'18px'}} />
                    </IconButton>
                  </>
                },
                { title: "Estado", field: "estado",
                  render: rowData => 
                  <><BadgeEstado estado={rowData.estado} /></>
                },
                { title: "Operador", field: "operador", 
                  render: rowData => 
                    <>{rowData.operador}</>
                },
                { title: "Examen", field: "tipoexamen" },
                {
                  title: "Agendamiento",
                  field: "fechaagendamiento",
                  type: "date",
                  dateSetting: { locale: "es-ES" },
                },
                { title: "Comuna", field: "persona.comuna" },
                {
                  title: "Dirección",
                  field: "persona.direccion",
                  filtering: false,
                },
                { title: "Nombre", field: "persona.nombres", filtering: false },
                { title: "RUT/DNI", field: "persona.identificador" },
                { title: "Teléfono", field: "persona.telefono" },
                { title: "Medio de pago", field: "mediopago"},
                { title: "Valor pagado", field: "montopago"},
                { title: "Observaciones", field: "observacion", filtering:false}
              ]}
              localization={{
                header: {
                  actions: "",
                },
                body: {
                  emptyDataSourceMessage: "No existen datos",
                  deleteTooltip: "Eliminar",
                  editRow: {
                    deleteText: "¿Seguro que deseas eliminar la asignación?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Eliminar",
                  },
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                },
                pagination: {
                  labelRowsSelect: "Filas por página",
                  labelDisplayedRows: "{from}-{to} de {count}",
                  firstTooltip: "Primera página",
                  previousTooltip: "Página anterior",
                  nextTooltip: "Próxima página",
                  lastTooltip: "Última página",
                },
                toolbar: {
                  nRowsSelected: "{0} exámenes seleccionados",
                  searchPlaceholder: "Buscar",
                },
              }}
              data={examenesAsignados}
              options={{
                filtering: true,
                pageSize: 10,
                search: false,
                rowStyle: {
                  padding: "6px 6px",
                  fontSize:'12px'
                },
                cellStyle: {
                  padding: "6px 6px",
                  fontSize:'12px'
                },
                toolbar: false,
              }}
              onSelectionChange={(rows) => handlerSelection(rows)}
            />

            {dataError.examenesSeleccionados ? (
              <p style={{ color: "#f44336" }}>
                Debes seleccionar almenos un exámen
              </p>
            ) : null}
          </Grid>
        </Grid>
      )}

      <Snackbar open={errorOpen} autoHideDuration={20000} onClose={handleError}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleError}
          severity={message.tipo}
        >
          {message.mensaje}
        </MuiAlert>
      </Snackbar>

      {open &&
        <EditarAsignacion 
          examen={examenSeleccionado}
          open={open}
          setOpen={setOpen}
          estados={estados}
          operadores={usuarios}
          mediosDePago={medioPago}
          reaload={fetchExamenes}
        />
      }

    </>
  );
};


const BadgeEstado = ({estado}) => {
  const classStyle = () => {
    switch (estado) {
      case "Pendiente":
        return "badge-pendiente";
      case "En ruta":
        return "badge-encurso";
      case "Pagado":
        return "badge-pagado";
      case "Cancelado":
        return "badge-cancelado";
      default:
        return "badge-default";
    }
  };

  return(
    <span className={classStyle()}>
      {estado}
    </span>
  )
};

export default Asignacion;
