import API from './api';
import URLS from './URLS';

const NacionalidadServices = {

    getNacionalidades: function () {
        return API.axios({
            method: 'get',
            url: `${URLS.nacionalidad}`
        });
    },

};

export default NacionalidadServices;