const URLS = {
    baseURL: process.env.REACT_APP_PATH + 'api',
    examen: '/examen',
    sexo: '/sexo',
    nacionalidad: '/nacionalidad',
    motivoexamen: '/motivoexamen',
    sintomas: '/sintoma',
    archivo: '/archivo',
    tipoOperativo: '/tipooperativo',
    tipoExamen: '/tipoexamen',
    operativo: '/operacion',
    detalleOperativo: '/detalleoperacion',
    usuarios: '/usuarios',
    roles: '/roles',
    asignacion: '/asignacion',
    estado: '/estado',
    mediopago: '/mediopago'
};

export default URLS;