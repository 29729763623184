import API from './api';
import URLS from './URLS';

const SexoServices = {

    getSexos: function () {
        return API.axios({
            method: 'get',
            url: `${URLS.sexo}`
        });
    },

};

export default SexoServices;