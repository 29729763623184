import {
   LinearProgress
} from '@material-ui/core';

const TopLoading = () => {
   return (

      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
         <LinearProgress />
      </div>

   );
}

export default TopLoading;