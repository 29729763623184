import API from './api';
import URLS from './URLS';

const OperativoServices = {

   getOperativos: function () {
      return API.axios({
         method: 'get',
         url: `${URLS.operativo}`
      });
   },

   saveOperacion: function (operativoNuevo) {
      return API.axios({
         method: 'post',
         url: URLS.operativo + '/nuevo',
         data: operativoNuevo
      })
   },

   getOperacion: function (idoperacion) {
      return API.axios({
         method: 'get',
         url: `${URLS.operativo}` + '/' + idoperacion
      })
   },

   getOperacionFull: function (idoperacion) {
      return API.axios({
         method: 'get',
         url: URLS.operativo + '/detalle/' + idoperacion
      })
   },

   deleteOperacion: function (idoperacion) {
      return API.axios({
         method: 'delete',
         url: URLS.operativo + '/delete/' + idoperacion
      })
   },

   getResultadoOperativo: function (dataHashed) {
      return API.axios({
         method: 'get',
         url: URLS.operativo + '/resultadoExamen/' + dataHashed,
      })
   },
   //OperacionDetalle

   saveOperacionDetalle: function (operacionDetalle) {
      return API.axios({
         method: 'post',
         url: URLS.detalleOperativo + '/nuevo',
         data: operacionDetalle
      })
   },

   deleteOperacionDetalle: function (operacionDetalle) {
      return API.axios({
         method: 'delete',
         url: URLS.detalleOperativo + '/delete',
         data: operacionDetalle
      })
   },

   cambiarEstadoOperacionDetalle: function (operacionDetalle) {
      return API.axios({
         method: 'put',
         url: URLS.detalleOperativo + '/estado',
         data: operacionDetalle
      })
   },

   procesarResultado: function (operacionDetalle) {
      return API.axios({
         method: 'post',
         url: URLS.detalleOperativo + '/procesar',
         data: operacionDetalle
      })
   }

}

export default OperativoServices;